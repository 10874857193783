<div
  class="taskDropZone flex flex-col draggingActive">

  <!-- drop zone overlay START -->
  <div class="taskDropZoneOverlay"
    nxDropList
    (nxDropped)="dragDropped($event)"
    *ngIf="(tourPlannerService?.isDragging$ | async) && (tourPlannerService?.currentDragSource$ | async ) === 'TOURS'">
    <div class="inside">
      <div>Aufgabe von Tour entfernen.</div>
    </div>
  </div>
  <!-- drop zone overlay END -->

  <!-- header start -->
  <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
    <div class="basis-1/2 flex flex-row items-center ml-3 font-medium noWrap h-12">
      Offene Aufgaben
    </div>
    <div class="basis-1/2 flex flex-row justify-end items-center">
      <input
        #globalSearch
        pInputText
        type="text"
        class="p-inputtext p-component p-element mr-3"
        (input)="onSearchInput($event.target?.['value'])"
        placeholder="Aufgabe suchen" />
        <nx-command-button
          *ngIf="this.growthBookService.isFeatureEnabled('defaultTasks') && rolesService.hasRole('nexcore_task_create')"
          #taskListComp
          [reloadable]="reloadable"
          class="mr-3"
          [style]="'light'"
          [commandName]="'createDefaultTaskCommand'"
          [icon]="'icon-plus'">
        </nx-command-button>
    </div>
  </div>
  <!-- header end -->

  <!-- filter start -->
  <div class="flex flex-row pt-[0.4rem] pb-1 pl-3 pr-3 bg-white border-b">
    <div class="basis-2/3 flex flex-row gap-2">
      <nx-filter-multiSelect
        [className]="'table-filter'"
        [filter]="false"
        [showToggleAll]="false"
        [showHeader]="false"
        [appendTo]="'body'"
        [(ngModel)] = "typesModel"
        [options]="typeOptions"
        [showClear]="true"
        placeholder="Typ"
        (onClear)="filter()"
        (onChange)="filter()"
        optionLabel="label">
          <ng-template let-option pTemplate="item">
            <div class="inline-block vertical-align-middle">
              <span class="ml-1 mt-1">{{ option.name }}</span>
            </div>
          </ng-template>
      </nx-filter-multiSelect>
      <nx-filter-multiSelect
        [className]="'table-filter ml-2'"
        [filter]="false"
        [showToggleAll]="false"
        [showHeader]="false"
        [appendTo]="'body'"
        [(ngModel)] = "locationsModel"
        [options]="locationsOptions"
        [showClear]="true"
        placeholder="Niederlassungen"
        (onClear)="filter()"
        (onChange)="filter()"
        optionLabel="label">
        <ng-template let-option pTemplate="item">
          <div class="inline-block vertical-align-middle">
            <span class="ml-1 mt-1">{{ option.name }}</span>
          </div>
        </ng-template>
      </nx-filter-multiSelect>
      <nx-filter-numberselect
        [className]="'table-filter ml-2'"
        [(ngModel)] = "previewModel"
        placeholder="Vorausschau"
        suffix="Tag(e)"
        (onClear)="filter()"
        (onChange)="filter()"
        optionLabel="label">
      </nx-filter-numberselect>   
    </div>
    <div class="basis-1/3 flex flex-row justify-end">
      <p-dropdown
        class="mr-2"
        placeholder="Sortierung"
        [(ngModel)] = "sortModel"
        (onChange)="sort()"
        [options]="sortOptions"
        [styleClass]="'sort'"
        appendTo="body"
        optionLabel="label"
        [showClear]="false">
      </p-dropdown>
      <p-overlayPanel #op>
        <ng-template pTemplate="content">
          <div class="max-h-[17rem] w-[200px] overflow-y-auto">
            <div class="flex flex-col"> 

              <div class="border-b mb-2">
                <div class="text-base font-medium leading-3r">Aktive Labels</div>
                <ul cdkDropList
                  [cdkDropListData]="displayedColumns"
                  (cdkDropListDropped)="dropColumn($event)">
                  <li
                    class="displayedColumn flex flex-row text-base text-[#848488] font-light leading-3"
                    cdkDrag 
                    cdkDragLockAxis="y"
                    *ngFor="let column of displayedColumns">
                    <div class="pr-2 py-1">
                      <p-checkbox
                      [disabled]="displayedColumns?.length === 1"
                      [binary]="true"
                      (click)="displayedColumns?.length > 1 ? onRemoveFromDisplayedColumns($event, column) : null"
                      [ngModel]="true">
                    </p-checkbox>
                    </div>
                    <div class="pt-[3px] flex-grow">
                      {{getHeaderForColumn(column)}}
                    </div>
                    <div class="pr-2 py-1 cursor-grabbing" cdkDragHandle>
                      <i [ngClass]="'icon-expand'"></i></div>
                  </li>
                </ul>
              </div>
              <div class="border-b mb-2">
                <div class="text-base justify-center items-center font-medium leading-3r">Verfügbare Labels</div>
                <ul
                  class="text-base text-[#848488] font-light leading-3">
                  <li *ngFor="let column of notDisplayedColumns" class="flex flex-row">
                    <div class="pr-2 flex">
                      <input type="checkbox" [checked]="false" (change)="onAddToDisplayedColumns(column)">
                    </div>
                    <div class="py-1 flex-grow">
                      {{getHeaderForColumn(column)}}
                    </div>
                    <div></div>
                  </li>
                </ul>
                <ng-container *ngIf="!notDisplayedColumns || notDisplayedColumns?.length === 0">
                  <div class="text-base text-[#848488] font-light">-</div>
                </ng-container>
              </div>
              <!--  -->
              <nx-default-button
                (click)="resetLabels()"
                [width]="'full'"
                class="w-full"
                [style]="'dark'"
                [label]="'Labels zurücksetzen'">
              </nx-default-button>
            </div>
          </div>
        </ng-template>
      </p-overlayPanel>
      <nx-default-button
        (click)="op.toggle($event)"
        [style]="'dark'"
        [icon]="'icon-settings'">
      </nx-default-button>
    </div>
  </div>
  <!-- filter end -->

  <!-- tasks start -->
  <div class="flex flex-col overflow-scroll scrollbar-hide">
    <div 
      *ngFor="let task of dataSource?.data; let isLast = last"
      task-row-wide
      [showOrderNote]="true"
      [columns]="displayedColumns"
      [showCommandMenu]="(task?.type === 'nexcore_default_task' && task?.commands?.length > 0) || (task?.type !== 'nexcore_default_task')"
      (refetchTasks)="refetchTasks(true)"
      nxDragItem
      [nxDragItemData]="task"
      (nxDragStarted)="dragStarted($event)"
      (nxDragEnded)="dragReleased($event)"
      [nxIsDragDisabled]="isDragDisabled(task)"
      [class.notDraggable]="isDragDisabled(task)"
      [class.draggable]="!isDragDisabled(task)"
      [ngClass]="{ 'notAssignable' : !task.isAssignable, 'assignable' : task.isAssignable}"
      class="bg-white border-gray border-b last:border-none"
      [task]=task>
    </div>
  </div>
  <nx-empty-box
    *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
    [label]="'Keine Aufgaben gefunden.'">
  </nx-empty-box>
  <!-- tasks end -->

  <!-- Pagination start -->
  <p-paginator 
    [dropdownAppendTo]="'body'"
    (onPageChange)="onPageChange($event)" 
    [first]="pageModel?.pageNumber * pageModel?.pageSize"
    [rows]="pageModel?.pageSize"
    [totalRecords]="dataSource?.totalElements"
    [showCurrentPageReport]="true" 
    currentPageReportTemplate="{first} - {last} von {totalRecords}"
    [rowsPerPageOptions]="[15, 20, 50]">
  </p-paginator>
  <!-- Pagination end -->
</div>
