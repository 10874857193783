<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
  <div class="basis-1/3 flex flex-row items-center ml-3 font-medium">
    Zugewiesene Aufgaben
  </div>
  <div class="basis-1/3 flex flex-row justify-center items-center">
    <nx-default-button
      (click)="selectAllTasks()"
      [disabled]="selectedTasks?.length === dataSource?.data?.length"
      [style]="'light'"
      [label]="'Alle auswählen'">
    </nx-default-button>
    <div *ngIf="!selectedTasks || selectedTasks?.length === 0"
      class="bg-white text-base pt-[0.25rem] pb-[0.188rem] px-[0.313rem] opacity-50 rounded-md ml-2">
      Keine ausgewählt
    </div>
    <div *ngIf="selectedTasks?.length > 0"
      class="bg-white text-base pt-[0.25rem] pb-[0.188rem] px-[0.313rem] rounded-md ml-2 flex flex-row">
      <div>
        {{selectedTasks?.length}} ausgewählt
      </div>
      <div
        *ngIf="selectedTasks?.length > 0"
        (click)="clearSelection()"
        class="clear ml-1 cursor-pointer">
      +
      </div>
    </div>
    <nx-default-button
      [tooltip]="'Zuweisung aufheben'"
      [disabled]="!selectedTasks || selectedTasks?.length === 0"
      (click)="unassignTasks()"
      [style]="'light'"
      [label]="'aufheben'"
      class="ml-2">
    </nx-default-button>
  </div>
  <div class="basis-1/3 flex flex-row justify-end items-center">
    <input
      pInputText
      type="text"
      class="p-inputtext p-component p-element mr-3"
      (input)="onSearchInput($event.target?.['value'])"
      placeholder="Aufgabe suchen" />
  </div>
</div>
<div class="flex flex-row px-[0.75rem] py-[0.4rem] leading-[1rem] border-b bg-white">
  <div class="basis-2/3 flex flex-row gap-2">
    <nx-filter-multiSelect
      [className]="'table-filter'"
      [filter]="false"
      [showToggleAll]="false"
      [showHeader]="false"
      [appendTo]="'body'"
      [(ngModel)] = "typesModel"
      [options]="typeOptions"
      [showClear]="true"
      placeholder="Typ"
      (onClear)="filter()"
      (onChange)="filter()"
      optionLabel="label">
        <ng-template let-option pTemplate="item">
          <div class="inline-block vertical-align-middle">
            <span class="ml-1 mt-1">{{ option.name }}</span>
          </div>
        </ng-template>
    </nx-filter-multiSelect>
  </div>
  <div class="basis-1/3 flex flex-row justify-end">
    <p-dropdown
      placeholder="Sortierung"
      [styleClass]="'sort'"
      appendTo="body"
      [(ngModel)] = "sortModel"
      [options]="sortOptions"
      optionLabel="label"
      [showClear]="false"
      (onChange)="refetchloadTaskList()">
    </p-dropdown>
  </div>
</div>
<div class="grid grid-cols-[1fr,auto,auto]">
  <div
    (click)="selectTask(task)"
    *ngFor="let task of dataSource?.data; let isLast = last"
    class="contents">
      <div 
      task-row-wide
      [showOrderNote]="true"
      [columns]="displayedColumns"
      class="child flex flex-row task items-center basis-full relative border-b border-r border-gray cursor-pointer"
      [ngClass]="{'border-b' : !isLast, 'bg-[#f3f4f6]' : isSelected(task), 'bg-white' : !isSelected(task)}"
      [task]=task>
    </div>
    <div
      class="child border-b border-gray bg-white px-2 py-1 text-base flex justify-end items-center max-w-[15.625rem] truncate cursor-pointer"
      [ngClass]="{'border-b' : !isLast, 'bg-[#f3f4f6]' : isSelected(task), 'bg-white' : !isSelected(task)}"><span><app-task-assignment-strategy-view [assignment]="task?.assignment"/></span>
    </div>
    <div
      class="child border-b border-gray bg-white px-2 py-1 flex justify-center items-center cursor-pointer"
      [ngClass]="{'border-b' : !isLast, 'bg-[#f3f4f6]' : isSelected(task), 'bg-white' : !isSelected(task)}">
        <nx-default-button
          (click)="unassignTask(task); $event.stopPropagation()"
          [tooltip]="'Zuweisung aufheben'"
          [style]="'dark'"
          [label]="'Zuweisung aufheben'">
        </nx-default-button>
    </div>
  </div>
</div>
<nx-empty-box
  *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
  [label]="'Keine Aufgaben gefunden.'">
</nx-empty-box>
<p-paginator 
  (onPageChange)="onPageChange($event)" 
  [first]="pageModel?.pageNumber * pageModel?.pageSize"
  [rows]="pageModel?.pageSize"
  [totalRecords]="dataSource?.totalElements"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[15, 20, 50]">
</p-paginator>



