import { Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { EditDialogService } from '@nexato/nx-core-module';
import { Apollo, QueryRef } from 'apollo-angular';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import * as fromGraphQl from './edit-linked-contact.graphql';

type OptionLabelType = string | ((option: any) => string);

@Component({
    selector: 'nx-edit-linked-contact-input',
    template: `
    <div class="nx-edit-linked-contact-input">
        <label for="text">{{label}}<ng-container *ngIf=isRequired()>*</ng-container></label>
        <p-autoComplete 
            [(ngModel)]="selectedItem" 
            [emptyMessage]="emptyMessage"
            [showEmptyMessage]="emptyMessage != undefined"
            [placeholder]="placeholder"
            [suggestions]="suggestions"
            (onSelect)=onSelect();
            (onClear)=onUnselect();
            (completeMethod)="search($event)" 
            [optionLabel]="optionLabel"
            [showClear]="true"
            [multiple]="false" />
    </div>
    `,
    styles: [`
        :host ::ng-deep .nx-edit-linked-contact-input .p-icon-wrapper {
            position: absolute;
            right: 24px;
            top: 18px;
        }
        .placeholder {
            color: #888484;
        }
        :host ::ng-deep .p-autocomplete .p-inputtext {
            padding-left: 10px !important;
        }
  `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditLinkedContactInputComponent),
            multi: true
        }
    ]
})
export class EditLinkedContactInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

    ngControl: NgControl;
    isDisabled: boolean;
    dialogRef: DynamicDialogRef | undefined;

    optionLabel = this.getOptionLabel.bind(this) as unknown as string;

    suggestions: Contact[] = [];
    selectedItem: any = undefined;

    @Input() label: string;
    @Input() placeholder: string;
    @Input() emptyMessage: string;

    private onChange: (value: any) => void;
    private onTouched: () => void;

    private pageNumber: number = 0;
    private pageSize: number = 10;
    private sortProperty: string = 'number';
    private sortDirection: string = 'asc';
    private name: string;

    autocompleteQuery: QueryRef<fromGraphQl.ContactsQueryResponse>;
    public autocompleteQuerySubscription: Subscription;

    constructor(
        public dialogService: EditDialogService,
        private apollo: Apollo,
        private _injector: Injector) {
    }

    ngOnInit() {
        this.ngControl = this._injector.get(NgControl);
        this.ngControl.valueAccessor = this;
    }

    ngOnDestroy(): void {
    }

    writeValue(contact: Contact): void {
        this.selectedItem = contact;
        // TODO -> hier bekommen wir halt nur die "links" und müssten die eigentlich auf ressourcen mappen
        // diese müssten wir über die ids holen..
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    isRequired(): boolean {
        return this.ngControl?.control?.hasValidator(Validators.required);
    }

    search(event: any) {
        this.name = event.query;
        if(!this.autocompleteQuery){
            this.autocompleteQuery = this.apollo.watchQuery<fromGraphQl.ContactsQueryResponse>({
                fetchPolicy: 'network-only',
                query: fromGraphQl.CONTACTS_QUERY,
                variables: this.buildVariables()
                });
            this.autocompleteQuerySubscription = this.autocompleteQuery.valueChanges.subscribe(
                {
                    next: (data) => {
                    if (data.networkStatus === 7) {
                        let contacts = Contact.createContacts(data.data.contacts.content);
                        // filter out already selected items
                        this.suggestions = contacts;
                    }
                    },
                    error: (error) => {
                        this.autocompleteQuery = undefined;
                        this.autocompleteQuerySubscription?.unsubscribe();
                    }
                }
            );
        } else {
            this.autocompleteQuery.refetch(this.buildVariables());
            this.autocompleteQuery.refetch();
        }
    }

    onSelect() {
        if (this.onChange) {
            this.onChange(this.selectedItem);
        }
    }

    onUnselect() {
        if (this.onChange) {
            this.onChange(this.selectedItem);
        }
    }

    buildVariables(): any {
        return {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortProperty: this.sortProperty,
            sortDirection: this.sortDirection,
            // fix for the case when the user tries to search for the same term after he selected an element
            // reason: search gehts called with the same variable and apollo does not resend the query in this case
            timeStamp: new Date().toISOString(),
            name: this.name
          }
    }

    getOptionLabel(option: any): string {
        let fullname = option.getFullName();
        return fullname;
      }

}