
export class AssignmentStrategyCandidate {
  type: string;
  label: string;
  dueDateTimePeriod: any;
  settings: any;


  constructor(payload?: any) {
    if (payload) {
      this.type = payload.type;
      this.label = payload.label;
      this.dueDateTimePeriod = payload.dueDateTimePeriod;
      this.settings = payload.settings;
    }
  }

  static createAssignmentStrategyCandidates(result: Array<any>): AssignmentStrategyCandidate[] {
    const assignmentStrategyCandidates = new Array<AssignmentStrategyCandidate>();
    if (result) {
      result.map((assignmentStrategyCandidate) => {
        assignmentStrategyCandidates.push(new AssignmentStrategyCandidate(assignmentStrategyCandidate));
      });
    }
    return assignmentStrategyCandidates;
  }

}

