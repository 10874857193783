import { Component, Input } from '@angular/core';
import { Task } from '../../shared/entities/task/task';

@Component({
    selector: 'app-assignment-task-view',
    template: `
    <nx-default-box
        class="bg-white border-b border-gray"
        [label]="'Zuweisung'">
        <div class="flex items-center">
            <app-task-assignment-strategy-view 
                *ngIf="task?.assignment; else noAssignment"
                class="assignmnet" [assignment]="task?.assignment"/>
            <ng-template #noAssignment>
            -
            </ng-template>
        </div>
    </nx-default-box>
    `,
    styles: [`

  `]
})
export class AssignmentTaskViewComponent{

    @Input() task: Task;

    constructor() {
    }

}