<div [formGroup]="assignmentFormGroup" class="form flex flex-col">
  <nx-edit-select-input
    formControlName="assignmentType"
      [placeholder]="'Zuweisungstyp auswählen'"
      [options]="assignemtTypeOptions"
      label="Zuweisungstyp">
  </nx-edit-select-input>
  <nx-edit-select-input
    formControlName="assigneeId"
    [placeholder]="'Auswählen'"
    [options]="assigneeOptions"
    label="Zuweisen an">
  </nx-edit-select-input>
  <!-- <nx-edit-dateTime-input
    formControlName="dueDateTimePeriod"
    label="Fälligkeit">
  </nx-edit-dateTime-input> --> 
  <nx-edit-dateTime-input-v2
    formControlName="dueDateTimePeriod"
    [placeholder]="'Fälligkeit hinzufügen'"
    label="Fälligkeit">
  </nx-edit-dateTime-input-v2>
</div>
