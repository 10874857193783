import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommandService, CommandTemplate, Reloadable } from '@nexato/nx-core-module';
import { MenuItem } from 'primeng/api';
import { ContactPerson } from 'src/app/rent-module/shared/entities/contactPerson/contactPerson';
import { LineItem } from 'src/app/rent-module/shared/entities/lineItem/lineItem';
import { ResourceAssignment } from 'src/app/rent-module/shared/entities/resourceAssignment/resourceAssignment';

@Component({
  selector: 'app-order-rent-line-items',
  templateUrl: './order-rent-line-items.component.html',
  styleUrls: ['./order-rent-line-items.component.scss']
})
export class OrderRentLineItemsComponent implements Reloadable {

  @Output() refetch = new EventEmitter();

  public orderLineItems: LineItem[];
  public panelOpenState: boolean[] = [];


    defaultColumns = [
      'dueDateTimePeriod',
      'extendedState'
    ];

  @Input() set lineItems(lineItems: LineItem[]) {
    this.orderLineItems = lineItems;
    for (let i in this.orderLineItems){
      this.panelOpenState.push(true);
    }
  };
  public state = 'opened';

  constructor(
    public datePipe: DatePipe,
    public commandService: CommandService) {
  }

  reload(): void {
    this.refetch.emit();
  }

  getHandlingType(type: string, way: string): string{
    switch(type) {
      case 'DELIVERY':
          return 'Lieferung';
      case 'PICKUP':
          return 'Selbstabholung';
      default: return '-';
    }
  }

  findArticleNumber(lineItem: LineItem) {
    // we have an article available
    if(lineItem?.article?.number) {
      return lineItem?.article?.number;
    }
    // we do not have an article available, so we display
    // resource.number. If no article is available, the constraint is,
    // that we have only one resourceAssignment per lineItem, so we can
    // safely acces the first resourceAssignment harcoded
    if(lineItem?.resourceAssignments?.length > 0){
      return lineItem?.resourceAssignments[0].resource?.name;
    }
    return undefined;
  }

  getName(contacts: ContactPerson[]): string {
    if(!contacts) {
      return undefined;
    }
    let name = undefined;
    for (let contact of contacts) {
      const fullName = contact?.getFullName();
      if (fullName) {
        name = name? name + ', ' + fullName: fullName;
      }
    }
   return name? name: '-';
  }

  test(state: any) {
    if (state === 'check') {
      let opened = true;
      for(let state of this.panelOpenState) {
        if (!state) {
          opened = false;
        }
      }
      this.state = opened? 'opened': 'closed';
    } else {
      this.state = state;
    }
  }
  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  getAvailableMenuItems(resourceAssignment: ResourceAssignment): MenuItem[] {
    let menuItems: MenuItem[] = [];

    if(resourceAssignment?.commands?.length > 0){
      resourceAssignment?.commands.forEach( (command: CommandTemplate) => {
        menuItems.push({
          label: command.settings?.label,
          disabled: command.settings?.disabled,
          command: () => {
            this.commandService.execute(resourceAssignment, command, this);
          }
        });
      });
    }

    return menuItems;
  }
}


