import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs';
import { ResourceAssignment } from 'src/app/rent-module/shared/entities/resourceAssignment/resourceAssignment';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-rental-resource-assignment-review-form',
  templateUrl: './rental-resource-assignment-review-form.component.html',
  styleUrls: ['./rental-resource-assignment-review-form.component.scss']
})
export class RentalResourceAssignmentReviewFormComponent {

  resourceAssignents: ResourceAssignment[];

  _task: Task;
  @Input() set task(task: Task) {
    if(task) {
      this._task = task;
      this.loadResourceAssignments(task);
    }
  }
  
  @Output() complete = new EventEmitter<{ task: Task, formModelInput?: any }>();

  constructor(
    private apollo: Apollo
  ) {
  }

  loadResourceAssignments(task: Task) {
    if(task?.context) {
      this.apollo.query<fromGraphQl.RentalResourceAssignmentReviewQuery>({
        query: fromGraphQl.RENTAL_RESOURCE_ASSIGNMENT_REVIEW_QUERY,
        variables: {
          ids: task?.context?.map(element => element.id)
        },
        fetchPolicy: 'network-only',
      })
      .pipe(take(1))
      .subscribe( result => {
        this.resourceAssignents = ResourceAssignment.createResourceAssignments(result?.data?.resourceAssignments);
      })
    }
  }

  completeTask() {
    this.complete.emit({
      task: this._task
    });
  }
  
  
}
