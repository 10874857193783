import { DatePipe } from "@angular/common";

export class DateTimePeriod {
  id: string | undefined;
  modifier: any | undefined;
  dateTime: string| undefined;
	fromDateTime: string| undefined;
	toDateTime: string| undefined;
  precision: any | undefined;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.modifier = payload.modifier;
      this.dateTime = payload.dateTime;
      this.fromDateTime = payload.fromDateTime;
      this.toDateTime = payload.toDateTime;
      this.precision = payload.precision;
    }
  }
  getDatePeriod(datePipe: DatePipe): string {
    // console.log(this);
    if(!datePipe) {
      return null;
    }
    let pattern = 'dd.MM.yyyy HH:mm';
    switch(this?.modifier) {
      case 'BETWEEN':
        return datePipe.transform(this?.fromDateTime, pattern) + '\u00a0 -\u00a0 ' + datePipe.transform(this?.toDateTime, pattern);
      case 'BEFORE':
        let date = 'vor ' ;
        date +=  this?.dateTime? datePipe.transform(this?.dateTime, pattern): '\u00a0 -\u00a0 ';
        return date;
      case 'AFTER':
        let dateAfter = 'nach ';
        dateAfter +=  this?.dateTime? datePipe.transform(this?.dateTime, pattern): '\u00a0 -\u00a0 ';
        return dateAfter;
      case 'EXACT':
        let dateExact =  this?.dateTime? datePipe.transform(this?.dateTime, pattern): '\u00a0 -\u00a0 ';
        return dateExact;
      default: return '-';
    }
  }
}

export class DateTimePeriodInput {
  id: string | undefined;
  modifier: any | undefined;
  dateTime: string| undefined;
	fromDateTime: string| undefined;
	toDateTime: string| undefined;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.modifier = payload.modifier;
      this.dateTime = payload.dateTime;
      this.fromDateTime = payload.fromDateTime;
      this.toDateTime = payload.toDateTime;
    }
  }
}
