import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommandTemplate, EditDialogComponent, EditDialogInstance } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import * as fromGraphQl from './graphql';

/**
 * Dialog is for splitting composite tasks into atomic tasks. It displays a
 * a list of atomic tasks that are currently part of this composite the
 * command belongs to. The user then decides which atomic tasks he wants
 * to split off.
 * 
 * To display the list, the command templates contains a list of atomic task
 * ids. It loads those tasks and display them in a list, where the user then 
 * picks the tasks he wants to split off.
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-split-task-dialog",
  templateUrl: "split-task-dialog.html",
  styleUrls: ["./split-task-dialog.scss"]
})
export class SplitTaskDialogComponent implements EditDialogInstance {

  // address form
  splitTaskForm: FormGroup;
  commandTemplate: CommandTemplate

  tasksToDisplay: Task[] = [];
  selectedTasks: Task[] = [];

  defaultColumns = [
    'dueDateTimePeriod',
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private apollo: Apollo
  ) {
    this.commandTemplate = this.config?.data.commandTemplate;

    // collect the ids of the tasks that we must load
    const taskIds = this.commandTemplate?.flow?.settings?.customFlowSettings?.atomicTaskIds;

    // load the tasks
    if(taskIds && taskIds.length > 0) {
      this.apollo.query<fromGraphQl.TasksQueryResponse>({
        query: fromGraphQl.TASKS_QUERY,
        variables: {
          ids: taskIds
        },
        fetchPolicy: 'network-only',
      })
      .pipe(take(1))
      .subscribe( result => {
        this.tasksToDisplay = Task.createTasks(result?.data?.tasks);
      })
    }

    this.splitTaskForm = this.fb.group({
      selectedAtomicTasks: [ this.selectedTasks?.map( task => task.id), [
        Validators.required,
        Validators.minLength(1)
      ]]
    });
  }

  onTaskChange() {
    // Bei Änderungen der Checkboxen das Formular aktualisieren
    this.splitTaskForm.patchValue({
      selectedAtomicTasks: [this.selectedTasks?.map( task => task.id)]
    });
    
  }

  getEditForm(): FormGroup {
    return this.splitTaskForm;
  }

  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    console.log(this.selectedTasks);
    this.ref.close({
      "taskIds" :  this.selectedTasks?.map( task => task.id)
    });
  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

  isSelectable(task: Task): boolean {
    return true;
  }

  isSelected(task: Task): boolean {
    if(this.selectedTasks?.includes(task)){
      return true;
    }
    return false;
  }

  selectTask(task: Task) {
    if(!this.selectedTasks){
      this.selectedTasks = [];
    }
    if(this.selectedTasks.includes(task)){
      this.selectedTasks = this.selectedTasks.filter(selectedTask => selectedTask.id !== task.id);
    } else {
      this.selectedTasks.push(task);
    }
    this.onTaskChange();
  }

}
