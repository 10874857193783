
<ng-container *ngFor="let resourceAssignment of resourceAssignents">
    <app-order-rent-resourceAssignment-protocols-view
        [showReviewProtocol]="false"
        [resourceAssignment]="resourceAssignment">
    </app-order-rent-resourceAssignment-protocols-view>
</ng-container>

<app-complete-button-task-view
    [canComplete]="true"
    (completeTask)="completeTask()"
    [task]="_task">
</app-complete-button-task-view>
