

<ng-container>
    <div [ngSwitch]="_task?.type">
    <div *ngSwitchCase="'nexcore_default_task'">
        <app-nexcore-default-task-view
            (complete)="completeTask($event)"
            [task]="_task">
        </app-nexcore-default-task-view>
    </div>
    <div *ngSwitchCase="'nexcore_rental_resourceAssignmenmt_out'">
        <app-nexcore-rental-resourceAssignment-out-task-view
            [task]="_task">
        </app-nexcore-rental-resourceAssignment-out-task-view>
    </div>
    <div *ngSwitchCase="'nexcore_rental_resourceAssignmenmt_in'">
        <app-nexcore-rental-resourceAssignment-in-task-view
            [task]="_task">
        </app-nexcore-rental-resourceAssignment-in-task-view>
    </div>
    <div *ngSwitchCase="'nexcore_rental_resourceAssignmenmt_refuel'">
        <app-nexcore-rental-refuel-resource-assignment-task-view
            (complete)="completeTask($event)"
            [task]="_task">
        </app-nexcore-rental-refuel-resource-assignment-task-view>
    </div>
    <div *ngSwitchCase="'nexcore_rental_resourceAssignmenmt_review'">
        <app-nexcore-rental-resourceAssignment-review-task-view
            (complete)="completeTask($event)"
            [task]="_task">
        </app-nexcore-rental-resourceAssignment-review-task-view>
    </div>
    <div *ngSwitchDefault>
        <app-header-task-view
            [label]="'-'">
        </app-header-task-view>
        <ng-container>
            <nx-empty-box
                [label]="'Keine Aufgabe ausgewählt.'">
            </nx-empty-box>
        </ng-container>
    </div>
    </div>
</ng-container>

