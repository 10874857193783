import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { User } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { Contact } from '../../shared/entities/contact/contact';
import { Task } from '../../shared/entities/task/task';
import { TasksService } from '../../shared/services/tasks/tasks.service';
import { TaskAssignmentDataSource } from '../task-assignment-component/task-assignment.datasource';
import { AbstractAssignmentList, PageModel, SortModel } from '../task-assignment-list-unassigned/abstract-task-assignment-list';

@Component({
  selector: 'app-task-assignment-list-completed',
  templateUrl: './task-assignment-list-completed.component.html',
  styleUrls: ['./task-assignment-list-completed.component.scss']
})
export class TaskAssignmentListCompletedComponent extends AbstractAssignmentList implements OnInit, OnChanges, OnDestroy {

  // inputs
  @Input() date: Date;
  @Input() locations: LocationEntity[];
  
  dataSource: any;
  loading = false;
  textSubscription: Subscription;

  displayedColumns = [
    'dueDateTimePeriod',
    'orderNumber',
    'orderCustomerName',
    'state'
  ];

  // types
  public typesModel: any[] = [];
  public typeOptions = [
    { id: 'nexcore_rental_resourceAssignmenmt_out', name: 'Übergabe', label: 'Übergabe' },
    { id: 'nexcore_rental_resourceAssignmenmt_in', name: 'Rücknahme', label: 'Rücknahme' },
    { id: 'nexcore_default_task', name: 'Aufgabe', label: 'Aufgabe' }
  ];
  // defaultTypesModel: what the user sees (nothing selected)
  public defaultTypesModel = [];
  // emptyTypesModel: what is actually send to the server: only review tasks
  public emptyTypesModel = [
    { id: 'nexcore_rental_resourceAssignmenmt_out' },
    { id: 'nexcore_rental_resourceAssignmenmt_in' },
    { id: 'nexcore_default_task' }
  ]

  // text
  @ViewChild('globalSearch') globalSearchInput!: ElementRef;
  private textSubject = new Subject<string>();
  public textModel: string;
  public defaultTextModel: string = undefined;

  // page model
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };
  

  // sortModel
  public sortModel: SortModel;
  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Erledigt aufsteigend', fieldName: 'completedDateTime', direction: "asc" },
    { label: 'Erledigt absteigend', fieldName: 'completedDateTime', direction: "desc"},
    { label: 'Auftragsnummer aufsteigend', fieldName: 'order.number', direction: "asc" },
    { label: 'Auftragsnummer absteigend', fieldName: 'order.number', direction: "desc"},
    { label: 'Kunde aufsteigend', fieldName: 'customer', direction: "asc" },
    { label: 'Kunde absteigend', fieldName: 'customer', direction: "desc"}
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  // fixed
  public statesModel = ['COMPLETED'];
  public assignmentTypes = ['UserTaskAssignmentStrategy', 'LocationTaskAssignmentStrategy'];

  constructor(
    private apollo: Apollo,
    public taskService: TasksService,
  ) {
    super('TaskAssignmentListCompletedComponent.unassignedTasksTable', 'session' )
    let state = this.loadState();
    // page model
    let statePageModel = state?.pageModel;
    this.pageModel = statePageModel ? statePageModel : this.defaultPageModel;
    // sort model
    let stateSortModel = state?.sortModel;
    let matchedSortModel = this.sortOptions.find(option => option.fieldName === stateSortModel?.fieldName && option.direction === stateSortModel?.direction);
    this.sortModel = matchedSortModel ? matchedSortModel : this.defaultSortModel;
    // types
    let stateTypesModel = state?.filter?.typesModel;
    const typeModelIds = new Set(stateTypesModel?.map(option => option.id));
    const matchedTypesModel = this.typeOptions?.filter(option => typeModelIds?.has(option?.id));
    this.typesModel = this.defaultTypesModel;
    this.typesModel = matchedTypesModel ? matchedTypesModel : this.defaultTypesModel;
    // text
    this.textSubscription = this.textSubject.pipe(
      debounceTime(500)
    ).subscribe(text => {
      this.textModel = text;
      this.filter();
    });
  }

  ngOnDestroy(): void {
    this.textSubscription?.unsubscribe();
  }

  buildVariables(): any {
    return {
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      fromCompletedDateTime: new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString(),
      toCompletedDateTime: new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      locationIds: this.locations?.map(location => location.id),
      types: this.typesModel?.length == 0 || this.typesModel === undefined ?  this.emptyTypesModel?.map(type => type.id) : this.typesModel?.map(type => type.id),
      states: this.statesModel,
      text: this.textModel,
      assignmentTypes: this.assignmentTypes
    };
  }


  ngOnChanges(changes: SimpleChanges): void {
    // we check here for date and location tho initiate the first load
    // both values must be set, even locations gets an empty array after
    // initilaztion of the input
    if (this.date) {
      this.dataSource?.loadTasks(this.buildVariables());
    }
  }

  ngOnInit(): void {
    this.dataSource = new TaskAssignmentDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  getTypeName(type: string): string {
    return Task.getTypeLabelFromString(type);
  }

  onSearchInput(text: string) {
    this.textSubject.next(text);
  }

  filter() {
    // reset page number after filter change
    this.pageModel.pageNumber = this.defaultPageModel.pageNumber;
    this.refetchloadTaskList();
  }

  refetchloadTaskList(force = false): void {
    // store new state, then refetch
    this.storeState({
      pageModel: this.pageModel,
      sortModel: this.sortModel,
      filter: {
        typesModel: this.typesModel
      }
    });
    this.dataSource.refetchQuery(this.buildVariables(), true);
  }

  getName(user: User): string {
    if (user?.firstName && user?.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName || user?.lastName) {
      return user?.firstName ? user?.firstName : user?.lastName;
    } else {
      return '-'
    }
  }

  public getDataSource() {
    return this.dataSource;
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchloadTaskList();
  }

}
