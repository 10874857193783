import { CommandTemplate, HasCommands, Location, User } from "@nexato/nx-core-module";
import { ControlTemplate } from "src/app/rent-module/components/form/entities/controlTemplate";
import { Tour } from "src/app/tour-planning/shared/entities/tour";
import { Comment } from "../../../../order-module/shared/entities/comment/comment";
import { Order } from "../../../../order-module/shared/entities/order/order";
import { Address } from "../../../../shared-module/shared/entities/address/address";
import { AssignmentStrategyCandidate } from "../assignmentStrategyCandidate/assignmentStrategyCandidate";
import { Contact } from "../contact/contact";
import { DateTimePeriod } from "../dateTimePeriod/dateTimePeriod";
import { Resource } from "../resource/resource";
import { TaskAssignment } from "../taskAssignment/taskAssignment";
import { TaskAssignmentSuggestion } from "../taskAssignmentSuggestion/taskAssignmentSuggestion";
export class Task implements HasCommands{
  id: string;
  state: string;
  formTemplate: any;
  dueDateTimePeriod: any;
  type: string;
  order: Order;
  title: string;
  description: string;
  context: any;
  address: Address;
  protocol: any;
  isAssignable: boolean;
  tour: Tour;
  assignmentSuggestion: TaskAssignmentSuggestion;
  assignment: TaskAssignment
  assignmentStrategyCandidates: AssignmentStrategyCandidate[];
  completedDateTime: any;
  completedBy: User;
  color: string;
  formControlTemplate: ControlTemplate;
  commands: CommandTemplate[];
  comments: Comment[];
  files: File[];
  fileCount: any;
  commentCount: any;
  linkedResources: Resource[];
  linkedContact: Contact;
  location: Location;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.state = payload.state;
      this.formTemplate = payload.formTemplate;
      if(payload.dueDateTimePeriod) {
        this.dueDateTimePeriod = new DateTimePeriod(payload.dueDateTimePeriod);
      }
      this.type = payload.type;
      if (payload.order) {
        this.order = new Order(payload.order);
      }
      this.description = payload.description;
      this.context = payload.context;
      this.protocol = payload.protocol;
      if (payload.address) {
        this.address = new Address(payload.address);
      }
      this.isAssignable = payload?.isAssignable;
      if (payload.tour) {
        this.tour = new Tour(payload.tour);
      }
      if (payload.assignmentSuggestion){
        this.assignmentSuggestion = new TaskAssignmentSuggestion(payload.assignmentSuggestion)
      }
      if (payload.assignment){
        this.assignment = new TaskAssignment(payload.assignment)
      }
      if (payload.assignmentStrategyCandidates){
        this.assignmentStrategyCandidates = new Array<AssignmentStrategyCandidate>();
        payload.assignmentStrategyCandidates.map((candidate: any) => {
          this.assignmentStrategyCandidates.push(new AssignmentStrategyCandidate(candidate));
        });
      }
      this.completedDateTime = payload?.completedDateTime;
      if (payload.completedBy) {
        this.completedBy = new User(payload.completedBy);
      }
      this.color = payload.color;
      if(payload.formControlTemplate) {
        this.formControlTemplate = new ControlTemplate(payload.formControlTemplate);
      }
      this.title = payload.title;
      if(payload.commands){
        this.commands = new Array<CommandTemplate>();
        payload.commands.map( (command: any) => {
          this.commands.push(new CommandTemplate(command));
        })
      }
      this.fileCount = payload?.fileCount;
      this.commentCount = payload?.commentCount;
      if (payload?.comments) {
        this.comments = Comment.createComments(payload.comments);
      }
      if (payload?.files) {
        this.files = payload.files;
      }
      if (payload?.linkedResources) {
        this.linkedResources = Resource.createLinkedResources(payload.linkedResources);
      }
      if (payload?.linkedContacts) {
        this.linkedContact = Contact.createLinkedContact(payload.linkedContacts);
      }
      if (payload?.location) {
        this.location = new Location(payload.location);
      }
    }
  }

  getEntityId(): string {
    return this.id || undefined;
  }
  
  getCollectionRoute(): string {
    return undefined;
  }

  /**
   * calculates the badge count for the task in the tasks row.
   * We sum up here:
   * - number of comments - if any
   * - add 1 if the tasks order has a note
   * @returns 
   */
  calculateCommentsAndNoteBadgeCount(): number {
    if(this.type === 'nexcore_default_task'){
      return this.commentCount;
    }
    let count = undefined
    if(this.order?.note){
      count = 1;
    }
    if(this?.order?.commentCount > 0){
      count = count ? count + this?.order?.commentCount : this?.order?.commentCount;
    }
    return count;
  }

  getCommentCount(): number {
    switch (this.type) {
      case 'nexcore_rental_resourceAssignmenmt_out':
      case 'nexcore_rental_resourceAssignmenmt_in':
      case 'nexcore_rental_resourceAssignmenmt_refuel':
      case 'nexcore_rental_resourceAssignmenmt_review':
        return this.order?.commentCount;
      case 'nexcore_default_task':
          return this.commentCount;
      default: 
        return undefined;
    }
  }

  /**
   * creates tasks from a result
   * @param result array of any
   * @returns Array of Task
   */
  static createTasks(result: Array<any>): Task[] {
    const tasks = new Array<Task>();
    if (result) {
      result.map((task) => {
        tasks.push(new Task(task));
      });
    }
    return tasks;
  }

  /**
   * @deprecated use getTypeLabel instead
   * 
   * @returns label of the task type
   */
  public getType() {
    return Task.getTypeLabel(this);
  }

  /**
   * returns the human readable label of the task type
   * 
   * @returns label of the task type
   */
  public static getTypeLabel(task: Task): string | undefined {
    return this.getTypeLabelFromString(task?.type);
  }

  public static getTypeLabelFromString(type: string): string | undefined {
    switch (type) {
      case 'nexcore_rental_resourceAssignmenmt_out' : return 'Übergabe';
      case 'nexcore_rental_resourceAssignmenmt_in' : return 'Rücknahme';
      case 'nexcore_rental_resourceAssignmenmt_refuel' : return 'Nachtanken';
      case 'nexcore_rental_resourceAssignmenmt_review' : return 'Prüfen';
      case 'nexcore_default_task' : return 'Aufgabe';
      default: 
        return type;
    }
  }

  public static getBackgroundColorStatic(type: string): string {
    if(type === undefined) {
      return '#F4F4F4';
    }
    switch (type) {
      case 'nexcore_rental_resourceAssignmenmt_out' : return '#9B00FF';
      case 'nexcore_rental_resourceAssignmenmt_in' : return '#00FDFF';
      case 'nexcore_rental_resourceAssignmenmt_refuel' : return '#DAFF00';
      default: 
        return '';
    }
  }

  public getBackgroundColor() {
    if(this.color !== undefined) {
      return this.color;
    }
    return Task.getBackgroundColorStatic(this.type);
  }


  public getStateLabel() {
    return Task.getStateLabel(this);
  }
  /**
   * returns the human readable label of the task state
   * @returns label of the task state
   */
  public static getStateLabel(task: Task): string | undefined {
    if (task === undefined) {
      return undefined;
    }
    return Task.getStateLabelFromString(task.state);
  }

  /**
   * Returns the human readable label of the task state
   * @param state - string representation of the task state
   * @returns 
   */
  public static getStateLabelFromString(state: string): string | undefined {
    switch (state) {
      case 'COMPLETED' : return 'Erledigt';
      case 'ASSIGNED' : return 'Zugewiesen';
      case 'NEW' : return 'Neu';
      case 'UNASSIGNED' : return 'Nicht zugewiesen';
      case 'DELEGATED' : return 'Delegiert';
      default:
        return state;
    }
  }

  public getStateSeverity() {
    return Task.getStateSeverityStatic(this?.state);
  }

  public static getStateSeverityStatic(state: string): string | undefined {
    switch (state) {
      case 'COMPLETED' : return 'success';
      case 'ASSIGNED' : return 'info';
      case 'NEW' : return 'info';
      case 'UNASSIGNED' : return 'info';
      case 'DELEGATED' : return 'info';
      default:
        return state;
    }
  }
  /**
   * @deprecated: we do not use icons anymore for the task state,
   * we use the "spoken" state with tags/chips instead
   * 
   * @returns 
   */
  public getClassName() {
    switch (this?.state) {
      case 'COMPLETED' : return 'icon icon-task-completed';
      case 'ASSIGNED' : return 'icon icon-task-planned';
      case 'NEW' : return 'icon icon-task-open';
      case 'UNASSIGNED' : return 'icon icon-task-open';
      case 'DELEGATED' : return 'icon icon-task-planned';
      default: return '';
    }
  }

   public getTypeIcon() {
    switch (this?.type) {
      case 'nexcore_rental_resourceAssignmenmt_out' : return 'icon icon-item-out';
      case 'nexcore_rental_resourceAssignmenmt_in' : return 'icon icon-item-in';
      case 'nexcore_rental_resourceAssignmenmt_refuel' : return 'icon icon-refuel';
      case 'nexcore_rental_resourceAssignmenmt_review' : return 'icon icon-examine';
      case 'nexcore_default_task' : return 'icon icon-task-open';
      default: return '';
    }
  }

}


export enum TaskState {
	NEW, ASSIGNED, DELEGATED, COMPLETED, UNASSIGNED
}

