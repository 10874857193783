import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import * as fromGraphQl from './graphql';

export interface TaskView {

  getHeadline(): string;

}
@Component({
  selector: 'app-task-detail-view',
  templateUrl: './task-detail-view.component.html',
  styleUrls: ['./task-detail-view.component.scss']
})
export class TaskDetailViewComponent {

  _task: Task;
  @Input() set task(task: Task) {
    this._task = task;
    if(task && task.id) {
      // we need more information about the task

      this.loadTask(task);
    }
  }
  @Output() refetch = new EventEmitter();

  constructor(
    private apollo: Apollo,
    private cdr: ChangeDetectorRef
  ) {

  }

  loadTask(task: Task) {
    this.apollo.query<fromGraphQl.CompleteTaskQuery>({
      query: fromGraphQl.COMPLETE_TASK_QUERY,
      variables: {
        id: task.id
      },
      fetchPolicy: 'network-only',
    })
    .pipe(take(1))
    .subscribe( result => {
      this._task = new Task(result?.data?.task);
    })
  }

  completeTask($event: any) {
    this.apollo.mutate({
      mutation: fromGraphQl.COMPLETE_TASK_MUTATION,
      variables: {
        taskId: $event?.task?.id,
        formModelInput: $event?.formModelInput
      },
    })
    .pipe(take(1))
    .subscribe( result => {
      this.loadTask(this._task);
      this.refetch.emit();
    });
  }
  
}
