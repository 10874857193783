import { Injectable } from "@angular/core";
import { CommandInput, CommandService, CommandTemplate, EditDialogService, Flow, HasCommands } from "@nexato/nx-core-module";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { AddressDialogComponent } from "src/app/shared-module/components/address-dialog/address-dialog.component";
import { Address } from "src/app/shared-module/shared/entities/address/address";

@Injectable({
    providedIn: 'root'
  })
export class RentalResourceAssignmentTaskUpdateAddressCommandCustomFlow implements Flow {

  dialogRef: DynamicDialogRef | undefined;

  constructor(
    private dialogService: EditDialogService
  ) { }

  executeFlow(commandService: CommandService, hasCommands: HasCommands,
    commandTemplate: CommandTemplate, commandInput: CommandInput,
    completionCallback: () => void) {

      let addressId = commandTemplate?.flow?.settings?.customFlowSettings?.addressId;
      let address = new Address();
      address.id = addressId;

      this.dialogRef = this.dialogService.open(AddressDialogComponent, {
        width: "1100px",
        header: 'Adresse bearbeiten',
        data: {
          address: address,
        }
      });
      this.dialogRef.onClose.subscribe((result) => {
        // result is true or false, indiciating if it should refetch
        this.dialogRef.onClose.subscribe((result: any) => {
          if(result){
            console.log('result', result);
            // this.addressService.updateAddress(result.addressInput, (result: any) => {
            //   this.refetchTasks.emit();
            // });
          }
        });
        if(result){
          console.log('result', result);
          commandInput.addParameter("addressInput", result.addressInput);
          commandService.sendCommand(commandInput, () => {
            completionCallback();
          })
        }
      });

      // // build the dialog
      // const message = commandTemplate.flow?.settings?.message;
      // const cancelLabel = commandTemplate.flow?.settings?.cancelLabel;
      // const confirmLabel = commandTemplate.flow?.settings?.confirmLabel;
      // const title = commandTemplate.flow?.settings?.title;
      // const confirmDialogSeverity = this.getSeverityfromString(commandTemplate.flow?.settings?.severity);

      // this.dialogRef = this.confirmDialogService.open({
      //     message: message,
      //     severity: confirmDialogSeverity,
      //     header: title,
      //     cancelLabel: cancelLabel,
      //     confirmLabel: confirmLabel
      //   });
      // this.dialogRef.onClose.subscribe( (result : ConfirmDialogEventType) => {
      //   if(result === ConfirmDialogEventType.CONFIRM){
          
      //     console.log(commandInput);

      //     // this.loadingService.setLoading(true);

      //     commandService.sendCommand(commandInput, () => {
      //       completionCallback();
      //     })

      //     // this.loadingService.setLoading(true);
      //   };
      // })
  }
  
}