import { NXDataSourcePrime } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';


export class TasksListDataSource extends NXDataSourcePrime<Task> {
  constructor(apollo: Apollo, private changeDetectorRef: any,
    private dataLoadedCallback: (tasks: Task[]) => void) {
    super(apollo, fromGraphQl.TASKS_LIST_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.TasksListQueryResponse): void {
    super.setSubject(
      data.tasks ? Task.createTasks(data.tasks?.content) : [],
      data.tasks ? data.tasks.totalElements : 0
    );
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 100);
    this.dataLoadedCallback(data.tasks ? Task.createTasks(data.tasks?.content) : []);
  }

  loadTasks(variables: any): void {
    this.subscribeToQuery(variables);
  }

  stopPolling(): void {
    this.queryRef?.stopPolling();
  }

  startPolling(): void {
    this.queryRef?.startPolling(environment.pollingIntervall);
  }
}
