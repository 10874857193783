import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogEventType, ConfirmDialogService, ConfirmDialogSeverity, Container, LoadingService, ToastService, User } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { Comment, CommentInput } from "../../../order-module/shared/entities/comment/comment";
import { OrderService } from '../../../order-module/shared/services/order/order.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsListComponent extends Container<fromGraphQl.CommentsQueryResponse>
implements OnInit, OnDestroy {
  
  dialogRef: DynamicDialogRef | undefined;

  public _ownerId: string;
  @Input() set ownerId(ownerId: string) {
    if(ownerId){
      this._ownerId = ownerId;
      this.subscribeToQuery({
        ownerId: this._ownerId,
        sortProperty: 'lastModifiedDate',
        sortDirection: 'desc',
      });
    }
  }
  @Input() showButtons = true;
  @Input() header: string;
  @Input() headerColor :string = '#EBEEEF';
  @Output() updateChild = new EventEmitter();
  @Input() style: 'default' | 'overlay' = 'default';

  public createFormGroup: UntypedFormGroup;
  public editFormGroup: UntypedFormGroup;

  public editComment: Comment;
  public comments: Comment[];

  constructor(
    apollo: Apollo,
    private router: Router,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    public orderService: OrderService,
    private fb: UntypedFormBuilder,
    private confirmDialogService: ConfirmDialogService,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(apollo, loadingService, fromGraphQl.COMMENTS_QUERY, environment);
  }

  getItems(comment: Comment) : any{
    let items = [
      {
        label: 'Bearbeiten',
        command: () => {
          this.edit(comment);
        }
      },
      {
        label: 'Löschen',
        command: () => {
          this.deleteComment(comment);
        }
      }
    ];
    return items;
  }

  private edit(comment: Comment) {
    this.editComment = comment;
    this.createFormGroup.disable();
    this.initializeEditForm();
  }

  ngOnInit(): void {
    this.initializeCreateForm();
  }

  initializeCreateForm(): void {
    this.createFormGroup = this.fb.group({
      text: [null, [Validators.required]],
    });
  }

  initializeEditForm(): void {
    this.editFormGroup = this.fb.group({
      text: [this.editComment?.text, [Validators.required]],
    });
  }

  cancelEditComment(): void {
    this.editComment = null;
    this.createFormGroup.enable();
    this.editFormGroup = undefined;
  }

  handleSubscriptionResult(data: fromGraphQl.CommentsQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data) {
      this.queryRef.stopPolling();
      this.comments = Comment.createComments(data.comments);
      this.changeDetectorRef.detectChanges();
    }
  }


  refetchloadTaskList(): void {
    this.queryRef?.refetch({
      ownerId: this._ownerId,
      sortProperty: 'lastModifiedDate',
      sortDirection: 'desc',
    });
  }

  deleteComment(comment: Comment) {
    const message = 'Möchtest Du den Kommentar wirklich löschen?';
    this.dialogRef = this.confirmDialogService.open({
      message: message,
      severity: ConfirmDialogSeverity.WARN,
      header: 'Kommentar löschen'
    });
    this.dialogRef.onClose.subscribe( (result : ConfirmDialogEventType) => {
      if(result === ConfirmDialogEventType.CONFIRM){
        this.loadingService.setLoading(true);
        this.orderService.deleteComment(
          comment.id,
          this.returnCallback(this.router)
        );
      };
    })
    // do nothing
  };

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.updateChild.emit({
        refetch : true
      });
      this.refetchloadTaskList();
      this.loadingService.setLoading(false);
    }, 500);
  }


  getName(user: User): string {
    if (user?.firstName && user?.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName || user?.lastName) {
      return user?.firstName ? user?.firstName : user?.lastName;
    } else {
      return '-'
    }
  }

  saveComment() {
    this.loadingService.setLoading(true);
    if (this.editComment) {
      this.orderService.updateComment(
        this.prepareCommentModel(),
        this.returnCallbackSave()
      );
    } else {
      this.orderService.createComment(
        this.prepareCommentModel(),
        this.returnCallbackSave()
      );
    }
  }

  cancelSaveComment() {
    this.createFormGroup?.get('text').reset();
  }

  returnCallbackSave(): void {
    setTimeout(() => {
      this.createFormGroup?.get('text').reset();
      this.refetchloadTaskList();
      this.loadingService.setLoading(false);
    }, 500);
  }


  prepareCommentModel(): CommentInput {
    const isEdit = this.editComment ? true : false;
    const formModel = !isEdit ? this.createFormGroup.value : this.editFormGroup.value;
    const commentModel = new CommentInput();
    if (isEdit) {
      commentModel.id = this.editComment.id;
      this.editComment = null;
      this.createFormGroup.enable();
      this.editFormGroup = undefined;
    }
    commentModel.text =formModel?.text;
    commentModel.ownerId = this._ownerId;  
    commentModel.references = [];
    return commentModel;
  }

  ngOnDestroy(): void {
    this.queryRef?.stopPolling();
  }
}
