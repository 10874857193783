import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EditDialogService, GrowthBookService, Reloadable, RolesService, ToastService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { Contact } from '../../shared/entities/contact/contact';
import { DateTimePeriodInput } from '../../shared/entities/dateTimePeriod/dateTimePeriod';
import { Task } from '../../shared/entities/task/task';
import { TaskAssignmentInput } from '../../shared/entities/taskAssignmentInput/taskAssignmentInput';
import { TasksService } from '../../shared/services/tasks/tasks.service';
import { AssignmentDialogComponent } from '../assignment-dialog/assignment-dialog.component';
import { TaskAssignmentDataSource } from '../task-assignment-component/task-assignment.datasource';
import { AbstractAssignmentList, PageModel, SortModel } from './abstract-task-assignment-list';


@Component({
  selector: 'app-task-assignment-list-unassigned',
  templateUrl: './task-assignment-list-unassigned.component.html',
  styleUrls: ['./task-assignment-list-unassigned.component.scss']
})
export class TaskAssignmentListUnassignedComponent extends AbstractAssignmentList implements OnInit, OnChanges, OnDestroy, Reloadable {

  dialogRef: DynamicDialogRef | undefined;

  public reloadable: Reloadable = this;
  
  // inputs
  @Input() date: Date;
  @Input() locations: LocationEntity[];
  
  dataSource: any;
  loading = false;
  textSubscription: Subscription;

  displayedColumns = [
    'dueDateTimePeriod',
    'orderNumber',
    'orderCustomerName'
  ];

  // types
  public typesModel: any[] = [];
  public typeOptions = [
    { id: 'nexcore_rental_resourceAssignmenmt_out', name: 'Übergabe', label: 'Übergabe' },
    { id: 'nexcore_rental_resourceAssignmenmt_in', name: 'Rücknahme', label: 'Rücknahme' },
    { id: 'nexcore_default_task', name: 'Aufgabe', label: 'Aufgabe' }
  ];
  // defaultTypesModel: what the user sees (nothing selected)
  public defaultTypesModel = [];
  // emptyTypesModel: what is actually send to the server: only review tasks
  public emptyTypesModel = [
    { id: 'nexcore_rental_resourceAssignmenmt_out' },
    { id: 'nexcore_rental_resourceAssignmenmt_in' },
    { id: 'nexcore_default_task' }
  ]
  
  // text
  @ViewChild('globalSearch') globalSearchInput!: ElementRef;
  private textSubject = new Subject<string>();
  public textModel: string;
  public defaultTextModel: string = undefined;

  // page model
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };

  // sortModel
  public sortModel: SortModel;
  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Fälligkeit aufsteigend', fieldName: 'dueDateTimePeriod', direction: "asc" },
    { label: 'Fälligkeit absteigend', fieldName: 'dueDateTimePeriod', direction: "desc"},
    { label: 'Auftragsnummer aufsteigend', fieldName: 'order.number', direction: "asc" },
    { label: 'Auftragsnummer absteigend', fieldName: 'order.number', direction: "desc"},
    { label: 'Kunde aufsteigend', fieldName: 'customer', direction: "asc" },
    { label: 'Kunde absteigend', fieldName: 'customer', direction: "desc"},
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  // fixed
  public statesModel = ['NEW', 'UNASSIGNED'];

  
  constructor(
    private apollo: Apollo,
    public taskService: TasksService,
    public dialogService: EditDialogService,
    private toastService: ToastService,
    public growthBookService: GrowthBookService,
    public rolesService: RolesService,
  ) {
    super('TaskAssignmentListUnassignedComponent.unassignedTasksTable', 'local' )
    this.typeOptions = this.typeOptions;
    let state = this.loadState();
    // page model
    let statePageModel = state?.pageModel;
    this.pageModel = statePageModel ? statePageModel : this.defaultPageModel;
    // sort model
    let stateSortModel = state?.sortModel;
    let matchedSortModel = this.sortOptions.find(option => option.fieldName === stateSortModel?.fieldName && option.direction === stateSortModel?.direction);
    this.sortModel = matchedSortModel ? matchedSortModel : this.defaultSortModel;
    // types
    let stateTypesModel = state?.filter?.typesModel;
    const typeModelIds = new Set(stateTypesModel?.map(option => option.id));
    const matchedTypesModel = this.typeOptions?.filter(option => typeModelIds?.has(option?.id));
    this.typesModel = this.defaultTypesModel;
    this.typesModel = matchedTypesModel ? matchedTypesModel : this.defaultTypesModel;
    // text
    this.textSubscription = this.textSubject.pipe(
      debounceTime(500)
    ).subscribe(text => {
      this.textModel = text;
      this.filter();
    });
  }

  reload(): void {
    this.refetchloadTaskList(true);
  }

  ngOnDestroy(): void {
    this.textSubscription?.unsubscribe();
  }

  buildVariables(): any {
    return {
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      fromDateTime: new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString(),
      toDateTime: new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      locationIds: this.locations?.map(location => location.id),
      types: this.typesModel?.length == 0 || this.typesModel === undefined ?  this.emptyTypesModel?.map(type => type.id) : this.typesModel?.map(type => type.id),
      states: this.statesModel,
      text: this.textModel,
    };
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    // we check here for date and location tho initiate the first load
    // both values must be set, even locations gets an empty array after
    // initilaztion of the input
    if (this.date) {
      this.dataSource?.loadTasks(this.buildVariables());
    }
  }

  ngOnInit(): void {
    this.dataSource = new TaskAssignmentDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  getTypeName(type: string): string {
    return Task.getTypeLabelFromString(type);
  }

  onSearchInput(text: string) {
    this.textSubject.next(text);
  }

  filter() {
    // reset page number after filter change
    this.pageModel.pageNumber = this.defaultPageModel.pageNumber;
    this.refetchloadTaskList();
  }

  refetchloadTaskList(force = false): void {
    // store new state, then refetch
    this.storeState({
      pageModel: this.pageModel,
      sortModel: this.sortModel,
      filter: {
        typesModel: this.typesModel
      }
    });
    this.dataSource.refetchQuery(this.buildVariables(), true);
  }

  assignTasks() {
    let taskAssignmentInputs: TaskAssignmentInput[] = [];
    for(const task of this.selectedTasks) {
      if(task.assignmentSuggestion) {
        let taskAssignmentInput = new TaskAssignmentInput();
        taskAssignmentInput.taskId = task.id;
        let dueDateTimePeriodInput = new DateTimePeriodInput();
        taskAssignmentInput.dueDateTimePeriod = dueDateTimePeriodInput;
        taskAssignmentInput.assigneeId = task.assignmentSuggestion.assigneeId;
        taskAssignmentInput.assignmentType = task.assignmentSuggestion.assignmentType;
        dueDateTimePeriodInput.dateTime = task.assignmentSuggestion.dueDateTimePeriod.dateTime;
        dueDateTimePeriodInput.fromDateTime = task.assignmentSuggestion.dueDateTimePeriod.fromDateTime;
        dueDateTimePeriodInput.toDateTime = task.assignmentSuggestion.dueDateTimePeriod.toDateTime;
        dueDateTimePeriodInput.modifier = task.assignmentSuggestion.dueDateTimePeriod.modifier;
        taskAssignmentInputs.push(taskAssignmentInput);
      } else {
        // task asignment in out is missing should not happen - can we somehow send a note
        // here to apm?
      }
    }
    // call for each taskAssignmentInput the assignTask method and
    // wail till all are completed
    if(taskAssignmentInputs.length > 0){
      this.taskService.assignTasks(taskAssignmentInputs, () => {
        this.clearSelection();
        this.toastService.addToastWithMessage(taskAssignmentInputs?.length +' Aufgaben zugewiesen');
        this.refetchloadTaskList(true);
      })
    }
  }

  editAssignment(task: Task): void {
    this.dialogRef = this.dialogService.open(AssignmentDialogComponent, {
      header: 'Zuweisung bearbeiten',
      confirmLabel: 'Zuweisen',
      data: task
    });
    this.dialogRef.onClose.subscribe((result) => {
      if(result){
        this.clearSelection();
        this.toastService.addToastWithMessage('Aufgabe zugewiesen');
        this.refetchloadTaskList(true);
      }
    });
  }

  public getDataSource() {
    return this.dataSource;
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchloadTaskList();
  }

  isSelectable(task: Task): boolean {
    return task.assignmentSuggestion !== undefined;
  }

}
