import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Container, File, FileInput, LoadingService, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { KeycloakService } from 'keycloak-angular';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { OrderService } from '../../../order-module/shared/services/order/order.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent extends Container<fromGraphQl.FilesQueryResponse>{

  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad : PdfJsViewerComponent
  currentHeader: string;

  public uploading = false;
  public ownerId: string;
  public value = '';
  public isFile = false;
  public fileName = '';
  public fileApi = environment.fileApi;
  pdfDialogVisible: boolean = false;
  imageDialogVisible: boolean = false;
  currentImage: string;
  public files: any[];

  @Input() set owner(ownerId: string) {
    this.ownerId = ownerId;
    if(this.ownerId){
      this.subscribeToQuery({
        ownerId: this.ownerId,
        sortProperty: 'lastModifiedDate',
        sortDirection: 'desc',
      });
    }
  }
  @Input() header: string;
  @Input() headerColor :string = '#EBEEEF';
  @Output() updateChild = new EventEmitter();
  @Input() style: 'default' | 'overlay' = 'default';

    /**
   * indicates, if the context menu of the action button is visible
   */
    public menuVisible = false;
  constructor(
    apollo: Apollo,
    private http: HttpClient,
    protected keycloakAngular: KeycloakService,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    private router: Router,
    public orderService: OrderService,
    public rolesService: RolesService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super(apollo, loadingService, fromGraphQl.FILES_QUERY, environment);
  }

  handleSubscriptionResult(data: fromGraphQl.FilesQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data) {
      this.queryRef.stopPolling();
      this.files = data.files;
      this.changeDetectorRef.detectChanges();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.owner) {
      this.ownerId = changes.owner.currentValue;
      this.refetchFilesList();
    }
  }


  refetchFilesList(): void {
    this.queryRef?.refetch({
      ownerId: this.ownerId,
      sortProperty: 'lastModifiedDate',
      sortDirection: 'desc',
    });
  }

  fileChangeEvent(event: any): void {
    if (this.uploading || event.target?.files?.length < 1) {
      return;
    }
    this.loadingService.setLoading(true);
    let extension = event.target?.files[0].type;
    extension = extension.replace(/(.*)\//g, '');
    const uuid = uuidv4();
    this.value = uuid;
    this.uploading = true;
    const params = new HttpParams({
      fromObject: {
        fileName:  event.target?.files[0].name
      }
    });
    const url = environment.fileApi + '/file/upload/?ownerId=' + this.ownerId;
    const formData: FormData = new FormData();
    formData.append('file', event.target?.files[0], this.value + '.' + extension);
    this.http
      .post(url,formData, {params})
      .toPromise()
      .then((data) => {
        this.returnUpload();
        this.uploading = false;
      })
      .catch((error) => {
        this.returnUpload();
      });
  }

  returnUpload(): void {
    setTimeout(() => {
      this.uploading = false;
      this.refetchFilesList();
      this.updateChild.emit({
        refetch : true
      });
      this.loadingService.setLoading(false);
    }, 1000);
  }

  getFileInput(files: File[]) : FileInput[] {
    let fileInputs = [];
    for (let val of files) {
      fileInputs.push(new FileInput({'id': val?.id}));
    }
    return fileInputs;
  }


  refetch(){
      this.returnCallback(this.router)
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.refetchFilesList();
      this.updateChild.emit({
        refetch : true
      });
      this.loadingService.setLoading(false);
    }, 500);
  }

}
