<app-header-task-view
    [label]="getHeadline()">
</app-header-task-view>

<app-completed-by-task-view
    *ngIf="task?.state === 'COMPLETED'"
    [task]="task">
</app-completed-by-task-view>

<app-assignment-task-view
    *ngIf="task?.state !== 'COMPLETED'"
    [task]="task">
</app-assignment-task-view>

<app-address-task-view
    [address]="task?.address">
</app-address-task-view>

<app-contact-task-view
    [label]="'Kontakt (Nr.)'"
    [contact]="task?.linkedContact">
</app-contact-task-view>

<app-resources-task-view
    [resources]="task?.linkedResources">
</app-resources-task-view>

<app-description-task-view
    [task]="task">
</app-description-task-view>

<app-complete-button-task-view
    [canComplete]="true"
    (completeTask)="completeTask($event)"
    [task]="task">
</app-complete-button-task-view>