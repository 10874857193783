

<nx-headline-box 
    icon="icon-item" 
    [label]="_task?.description" 
    class="mt-[1.25rem] border-b border-[#E0E0E0]">
</nx-headline-box>

<div
    [formGroup]="refuelForm"
    class="flex flex-col form">
    <div
        class="bg-white px-5 pt-[0.313rem] pb-[14px] border-b border-[#E0E0E0]">
        <nx-edit-number-input
            [suffix]="suffix"
            [formControlName]="controlKey"
            [placeholder]="'28,3'"
            [label]="label">
        </nx-edit-number-input>
    </div>
    <div
        class="justify-end flex flex-row">
        <nx-default-button
            *ngIf="_task?.state !== 'COMPLETED'"
            [disabled]="!refuelForm?.valid"
            (click)="completeTask()"
            class="confirm"
            [tooltip]="'Als erledigt markieren'"
            [style]="'dark'"
            [label]="'Als erledigt markieren'">
        </nx-default-button>

        <nx-default-button
            *ngIf="_task?.state === 'COMPLETED'"
            [disabled]="true"
            class="done"
            [tooltip]="'Erledigt'"
            [style]="'dark'"
            [label]="'Erledigt'">
    </nx-default-button>    
    </div>
</div> 
