import { Injectable } from '@angular/core';
import { TaskInput } from '../../entities/taskInput/taskInput';
import { TaskLinkInput } from '../../entities/taskLinkInput/taskLinkInput';
import { TaskLinkCandidate } from '../../interfaces/taskLinkCandidate';

/**
 * Tasks service.
 */
@Injectable()
export class TaskLinkService {
  constructor() {}

  buildTaskLinksFromTaskLinkCandidates(taskInput: TaskInput, taskLinkCandidates: TaskLinkCandidate[]){
    if(!taskInput || !taskLinkCandidates || taskLinkCandidates.length === 0){
      return;
    }
    if(!taskInput.taskLinks) {
      taskInput.taskLinks = [];
    }
    taskLinkCandidates.forEach(taskLinkCandidate => {
      taskInput.taskLinks.push(this.buildTaskLinkInput(taskInput.id, taskLinkCandidate));
    });
  }

  buildTaskLinksFromTaskLinkCandidate(taskInput: TaskInput, taskLinkCandidate: TaskLinkCandidate){
    if(!taskInput || !taskLinkCandidate ){
      return;
    }
    if(!taskInput.taskLinks) {
      taskInput.taskLinks = [];
    }
    taskInput.taskLinks.push(this.buildTaskLinkInput(taskInput.id, taskLinkCandidate));
  }

  buildTaskLinkInput(taskId: string, taskLinkCandidate: TaskLinkCandidate){
    let taskLinkInput = new TaskLinkInput();
    taskLinkInput.taskId = taskId;
    taskLinkInput.linkedEntityId = taskLinkCandidate.getId();
    taskLinkInput.linkedEntityType = taskLinkCandidate.getLinkedEntityType();
    return taskLinkInput;
  }

}
