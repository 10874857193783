import { CommandTemplate, HasCommands } from "@nexato/nx-core-module";
import { LineItem } from "../lineItem/lineItem";
import { Protocol } from "../protocol/protocol";
import { Resource } from "../resource/resource";
import { SchedulingInformation } from "../schedulingInformation/schedulingInformation";

export class ResourceAssignment implements HasCommands{
  id: string | undefined;
  externalId: string | undefined;
  name: string;
	isDeletable: boolean;
	lineItem: LineItem
	startDateTime: string;
	endDateTime: string;
	resource: Resource
	free: Boolean
	state: ResourceAssignmentState
	schedulingInformation: SchedulingInformation;
  effectiveSchedulingInformation: SchedulingInformation;
  protocols: Protocol[];
	customAttributes: any[]
  text: string;
  commands: CommandTemplate[];


  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.extensions;
      this.name = payload.name;
      this.startDateTime = payload.startDateTime;
      this.isDeletable = payload.isDeletable;
      this.endDateTime = payload.endDateTime;
      this.free = payload.free;
      this.text = payload.text;
      if (payload.lineItem) {
        this.lineItem = new LineItem(payload.lineItem);
      }
      if (payload.resource) {
        this.resource = new Resource(payload.resource);
      }
      this.state = payload.state;
      if (payload.schedulingInformation) {
        this.schedulingInformation = new SchedulingInformation(payload.schedulingInformation);
      }
      if (payload.effectiveSchedulingInformation) {
        this.effectiveSchedulingInformation = new SchedulingInformation(payload.effectiveSchedulingInformation);
      }
      if(payload.protocols && payload.protocols.length > 0) {
        this.protocols = new Array<Protocol>();
        payload.protocols.map( (protocol : any) => {
          this.protocols.push(new Protocol(protocol));
        })
      }
      this.customAttributes = payload.customAttributes;
      if(payload.commands){
        this.commands = new Array<CommandTemplate>();
        payload.commands.map( (command: any) => {
          this.commands.push(new CommandTemplate(command));
        })
      }
    }
  }

  getCollectionRoute(): string {
    return undefined;
  }

  getEntityId(): string {
    return this.id || undefined;
  }

  getResourceAssignmentStateSeverity(): string {
    return ResourceAssignment.getResourceAssignmentStateSeverity(this.state + "");
  }

  getResourceAssignmentState(): string {
    return ResourceAssignment.getResourceAssignmentStateStatic(this.state + "");
  }

  /**
   * creates models from a result
   * @param result Array of any
   * @returns Bookigs
   */
  static createResourceAssignments(result: Array<any>): ResourceAssignment[] {
    const resources = new Array<ResourceAssignment>();
    if (result) {
      result.map((resource) => {
        resources.push(new ResourceAssignment(resource));
      });
    }
    return resources;
  }

  static getResourceAssignmentStateStatic(state: string): string {
    switch (state) {
      case 'NEW':
        return 'neu';
      case 'OUT':
        return 'übergeben';
      case 'IN':
        return 'zurückgenommen';
      case 'COMPLETED':
        return 'abgeschlossen';
      case 'ARCHIVED':
        return 'archiviert';
      default:
        return '-';
    }
  }

  static getResourceAssignmentStateSeverity(state: string): string {
    switch (state) {
      case 'NEW':
        return 'info';
      case 'OUT':
        return 'warning';
      case 'IN':
        return 'success';
      case 'COMPLETED':
        return 'success';
      case 'ARCHIVED':
        return 'info';
      default:
        return '';
    }
  }
}

export class ResourceAssignmentInput {
  id: string;
  externalId: string;
  name: string;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.name = payload.name;
    }
  }
}

export enum  ResourceAssignmentState {
	OPEN, OUT_SCHEDULED, OUT, IN_SCHEDULED, IN, COMPLETED
}

