
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModuleModule, DefaultBoxComponent, HeadlineBoxComponent } from '@nexato/nx-core-module';
import { TranslateModule } from "@ngx-translate/core";
import { LightgalleryModule } from 'lightgallery/angular';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer"; // <-- Import PdfJsViewerModule module
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TimesIcon } from 'primeng/icons/times';
import { ImageModule } from 'primeng/image';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { AppRoutingModule } from '../app-routing.module';
import { FrontendMaterialModule } from '../material-module/material.module';
import { AddressIndicatorComponent } from './components/address-indicator/address-indicator.component';
import { ChipComponent } from './components/chip/chip.component';
import { ComposeAtomicTaskDialogComponent } from './components/compose-atomic-task-dialog/compose-atomic-task-dialog.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { FileComponent } from './components/file/file.component';
import { FilesComponent } from './components/files/files.component';
import { FilterInputComponent } from './components/filter-input/filter-input.component';
import { EditLinkedAddressInputComponent } from './components/formControls/edit-linked-address-form-control.component';
import { EditLinkedContactInputComponent } from './components/formControls/edit-linked-contact-form-control.component';
import { EditLinkedResourcesInputComponent } from './components/formControls/edit-linked-ressources-form-control.component';
import { EditLinkedLocationInputComponent } from './components/formControls/edit-location-form-control.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LocationSelectionComponent } from './components/location-selection/location-selection.component';
import { LocationSelectorComponent } from './components/location-selector/location-selector.component';
import { NonLicensePageComponent } from './components/non-license-page/non-license-page.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NxDurationPickerComponent } from './components/nx-duration-picker/nx-duration-picker.component';
import { NxTaskColorPickerComponent } from './components/nx-task-color-picker/nx-task-color-picker.component';
import { OrdersSearchComponent } from './components/orders-search/orders-search.component';
import { RefuelViewComponent } from './components/refuel-view/refuel-view.component';
import { SettingsFormComponent } from './components/settings-form/settings-form.component';
import { SplitTaskDialogComponent } from './components/split-task-dialog/split-task-dialog.component';
import { LocationTaskAssignmentStrategyViewComponent } from './components/task-assignment-strategy-view/location-task-assignment-strategy-view/location-task-assignment-strategy.component';
import { TaskAssignmentStrategyViewComponent } from './components/task-assignment-strategy-view/task-assignment-strategy-view.component';
import { TourTaskAssignmentStrategyViewComponent } from './components/task-assignment-strategy-view/tour-task-assignment-strategy-view/tour-task-assignment-strategy-view.component';
import { UserTaskAssignmentStrategyViewComponent } from './components/task-assignment-strategy-view/user-task-assignment-strategy-view copy/user-task-assignment-strategy.component';
import { TaskFilesDialogComponent } from './components/task-files-dialog/task-files-dialog.component';
import { TaskRowComponent } from './components/task-row-component/task-row.component';
import { TaskRowWideComponent } from './components/task-row-wide-component/task-row-wide.component';
import { CommentsListComponent } from './containers/comments-list/comments-list.component';
import { CustomAttributesViewComponent } from './containers/custom-attributes-view/custom-attributes-view.component';
import { NavigationComponent } from './containers/navigation/navigation.component';
import { HoverDirective } from './directives/hover.directive';
import { ScrollShadowDirective } from './directives/scroll-shadow.directive';
import { ChargeInputControlLabelViewComponent } from './protocols/controls/chargeInput-control-label-view/chargeInput-control-label-view.component';
import { ChargeInputControlValueViewComponent } from './protocols/controls/chargeInput-control-value-view/chargeInput-control-value-view.component';
import { CheckboxInputControlLabelViewComponent } from './protocols/controls/checkboxInput-control-label-view/checkboxInput-control-label-view.component';
import { CheckboxInputControlValueViewComponent } from './protocols/controls/checkboxInput-control-value-view/checkboxInput-control-value-view.component';
import { CommentInputControlLabelViewComponent } from './protocols/controls/commentInput-control-label-view/commentInput-control-label-view.component';
import { CommentInputControlValueViewComponent } from './protocols/controls/commentInput-control-value-view/commentInput-control-value-view.component';
import { FuelInputControlLabelViewComponent } from './protocols/controls/fuelInput-control-label-view/fuelInput-control-label-view.component';
import { FuelInputControlValueViewComponent } from './protocols/controls/fuelInput-control-value-view/fuelInput-control-value-view.component';
import { NumberInputControlLabelViewComponent } from './protocols/controls/numberInput-control-label-view/numberInput-control-label-view.component';
import { NumberInputControlValueViewComponent } from './protocols/controls/numberInput-control-value-view/numberInput-control-value-view.component';
import { PictureInputControlLabelViewComponent } from './protocols/controls/pictureInput-control-label-view/pictureInput-control-label-view.component';
import { PictureInputControlValueViewComponent } from './protocols/controls/pictureInput-control-value-view/pictureInput-control-value-view.component';
import { PicturesInputControlLabelViewComponent } from './protocols/controls/picturesInput-control-label-view/picturesInput-control-label-view.component';
import { PicturesInputControlValueViewComponent } from './protocols/controls/picturesInput-control-value-view/picturesInput-control-value-view.component';
import { RangeInputControlLabelViewComponent } from './protocols/controls/rangeInput-control-label-view/rangeInput-control-label-view.component';
import { RangeInputControlValueViewComponent } from './protocols/controls/rangeInput-control-value-view/rangeInput-control-value-view.component';
import { RefuelInputControlLabelViewComponent } from './protocols/controls/refuelInput-control-label-view/refuelInput-control-label-view.component';
import { RefuelInputControlValueViewComponent } from './protocols/controls/refuelInput-control-value-view/refuelInput-control-value-view.component';
import { SelectInputControlLabelViewComponent } from './protocols/controls/selectInput-control-label-view/selectInput-control-label-view.component';
import { SelectInputControlValueViewComponent } from './protocols/controls/selectInput-control-value-view/selectInput-control-value-view.component';
import { SignatureInputControlLabelViewComponent } from './protocols/controls/signatureInput-control-label-view/signatureInput-control-label-view.component';
import { SignatureInputControlValueViewComponent } from './protocols/controls/signatureInput-control-value-view/signatureInput-control-value-view.component';
import { TextInputControlLabelViewComponent } from './protocols/controls/textInput-control-label-view/textInput-control-label-view.component';
import { TextInputControlValueViewComponent } from './protocols/controls/textInput-control-value-view/textInput-control-value-view.component';
import { ProtocolControlLabelViewComponent } from './protocols/protocol-control-label-view/protocol-control-label-view.component';
import { ProtocolControlValueViewComponent } from './protocols/protocol-control-value-view/protocol-control-value-view.component';
import { ProtocolSideBySideViewComponent } from './protocols/protocol-side-by-side-view/protocol-side-by-side-view.component';
import { ProtocolViewComponent } from './protocols/protocol-view/protocol-view.component';
import { NotificationService } from './shared/services/notification.service';
@NgModule({
  declarations: [
    LayoutComponent,
    NavigationComponent,
    FilterInputComponent,
    DateSelectorComponent,
    OrdersSearchComponent,
    CustomAttributesViewComponent,
    LocationSelectionComponent,
    RefuelViewComponent,
    CommentsListComponent,
    ProtocolSideBySideViewComponent,
    ProtocolViewComponent,
    ProtocolControlValueViewComponent,
    ProtocolControlLabelViewComponent,
    CheckboxInputControlValueViewComponent,
    CheckboxInputControlLabelViewComponent,
    NumberInputControlLabelViewComponent,
    NumberInputControlValueViewComponent,
    RangeInputControlLabelViewComponent,
    RangeInputControlValueViewComponent,
    RefuelInputControlLabelViewComponent,
    RefuelInputControlValueViewComponent,
    ChargeInputControlLabelViewComponent,
    ChargeInputControlValueViewComponent,
    PictureInputControlLabelViewComponent,
    PictureInputControlValueViewComponent,
    PicturesInputControlLabelViewComponent,
    PicturesInputControlValueViewComponent,
    CommentInputControlLabelViewComponent,
    CommentInputControlValueViewComponent,
    SignatureInputControlLabelViewComponent,
    SignatureInputControlValueViewComponent,
    SelectInputControlLabelViewComponent,
    SelectInputControlValueViewComponent,
    TextInputControlLabelViewComponent,
    TextInputControlValueViewComponent,
    FuelInputControlLabelViewComponent,
    FuelInputControlValueViewComponent,
    RangeInputControlLabelViewComponent,
    RangeInputControlValueViewComponent,
    TaskAssignmentStrategyViewComponent,
    NonLicensePageComponent,
    FilesComponent,
    FileComponent,
    TaskFilesDialogComponent,
    SettingsFormComponent,
    // DefaultButtonComponent,
    AddressIndicatorComponent,
    ChipComponent,
    LocationSelectorComponent,
    ScrollShadowDirective,
    NxTaskColorPickerComponent,
    NotificationCenterComponent,
    NotificationComponent,
    HoverDirective,
    TaskRowComponent,
    TaskRowWideComponent,
    LocationTaskAssignmentStrategyViewComponent,
    UserTaskAssignmentStrategyViewComponent,
    TourTaskAssignmentStrategyViewComponent,
    NxDurationPickerComponent,
    EditLinkedAddressInputComponent,
    EditLinkedResourcesInputComponent,
    EditLinkedContactInputComponent,
    EditLinkedLocationInputComponent,
    SplitTaskDialogComponent,
    ComposeAtomicTaskDialogComponent
  ],
  providers: [
    NotificationService
  ],
  imports: [
    CommonModule,
    FrontendMaterialModule,
    AppRoutingModule,
    CoreModuleModule,
    ReactiveFormsModule,
    FormsModule,
    LightgalleryModule,
    ButtonModule,
    MenuModule,
    DialogModule,
    InputTextareaModule,
    TooltipModule,
    PdfJsViewerModule,
    ImageModule,
    MultiSelectModule,
    InputSwitchModule,
    DropdownModule,
    CalendarModule,
    ColorPickerModule,
    OverlayPanelModule,
    TabViewModule,
    TagModule,
    TranslateModule,
    TimesIcon,
    AutoCompleteModule,
    CheckboxModule
  ],
  exports: [
    LayoutComponent,
    FilterInputComponent,
    DateSelectorComponent,
    OrdersSearchComponent,
    CustomAttributesViewComponent,
    RefuelViewComponent,
    LocationSelectionComponent,
    CommentsListComponent,
    ProtocolSideBySideViewComponent,
    ProtocolViewComponent,
    ProtocolControlValueViewComponent,
    ProtocolControlLabelViewComponent,
    CheckboxInputControlValueViewComponent,
    CheckboxInputControlLabelViewComponent,
    NumberInputControlLabelViewComponent,
    NumberInputControlValueViewComponent,
    RangeInputControlLabelViewComponent,
    RangeInputControlValueViewComponent,
    RefuelInputControlLabelViewComponent,
    RefuelInputControlValueViewComponent,
    PictureInputControlLabelViewComponent,
    PictureInputControlValueViewComponent,
    PicturesInputControlLabelViewComponent,
    PicturesInputControlValueViewComponent,
    CommentInputControlLabelViewComponent,
    CommentInputControlValueViewComponent,
    SignatureInputControlLabelViewComponent,
    SignatureInputControlValueViewComponent,
    SelectInputControlLabelViewComponent,
    SelectInputControlValueViewComponent,
    TextInputControlLabelViewComponent,
    TextInputControlValueViewComponent,
    FuelInputControlLabelViewComponent,
    FuelInputControlValueViewComponent,
    ChargeInputControlLabelViewComponent,
    ChargeInputControlValueViewComponent,
    TaskAssignmentStrategyViewComponent,
    FilesComponent,
    FileComponent,
    TaskFilesDialogComponent,
    SettingsFormComponent,
    DefaultBoxComponent,
    HeadlineBoxComponent,
    // DefaultButtonComponent,
    AddressIndicatorComponent,
    ChipComponent,
    LocationSelectorComponent,
    DefaultBoxComponent,
    ScrollShadowDirective,
    HoverDirective,
    TaskRowComponent,
    TaskRowWideComponent,
    EditLinkedAddressInputComponent,
    EditLinkedResourcesInputComponent,
    EditLinkedContactInputComponent,
    EditLinkedLocationInputComponent,
    SplitTaskDialogComponent,
    ComposeAtomicTaskDialogComponent
  ],
})
export class SharedModule { }
