<nx-pageTitle-box
  class="mb-3"
  [label]="'Meine Aufgaben (beta)'"
  [icon]="'icon-task'"
  [iconToolTip]="'Meine Aufgaben (beta)'">

  <ng-template nxTemplate="end">
  </ng-template>
</nx-pageTitle-box>

<div class="bodyWrapper flex flex-col">
  <!-- LEFT -->
  <div class="basis-full mt-[10px] widthFixForTable">
    <!-- header start -->
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/2 flex flex-row items-center ml-3 font-medium noWrap h-12">
        Unerledigte Aufgaben
      </div>
      <div class="basis-1/2 flex flex-row justify-end items-center">
        <input
        #globalSearch
        pInputText
        type="text"
        class="p-inputtext p-component p-element mr-3"
        (input)="onSearchInput($event.target?.['value'])"
        placeholder="Aufgabe suchen" />
      </div>
    </div>
    <!-- header end -->
    <div class="flex flex-row pt-[0.4rem] pb-1 pl-3 pr-3 bg-white border-b">
      <div class="basis-2/3 flex flex-row gap-2">
        <!--  -->
        <nx-filter-multiSelect
          [className]="'table-filter'"
          [filter]="false"
          [showToggleAll]="false"
          [showHeader]="false"
          [appendTo]="'body'"
          [showClear]="true"
          placeholder="Typ"
          [(ngModel)]="typesModel"
          [options]="typeOptions"
          (onClear)="filter()"
          (onChange)="filter()"
          [optionValue]="'id'"
          optionLabel="label">
            <ng-template let-option pTemplate="item">
              <div class="inline-block vertical-align-middle">
                <span class="ml-1 mt-1">{{ option.name }}</span>
              </div>
            </ng-template>
        </nx-filter-multiSelect>
        <!--  -->
        <nx-filter-multiSelect
          [className]="'table-filter'"
          [filter]="false"
          [showToggleAll]="false"
          [showHeader]="false"
          [appendTo]="'body'"
          [(ngModel)] = "locationsModel"
          [options]="locationsOptions"
          [showClear]="true"
          placeholder="Niederlassungen"
          (onClear)="filter()"
          (onChange)="filter()"
          [optionValue]="'id'"
          optionLabel="label">
          <ng-template let-option pTemplate="item">
            <div class="inline-block vertical-align-middle">
              <span class="ml-1 mt-1">{{ option.name }}</span>
            </div>
          </ng-template>
        </nx-filter-multiSelect>
        <nx-filter-date-select
          [className]="'table-filter'"
          placeholder="Fällig"
          [showIncludeOverDue]="true"
          [includeOverDueLabel]="'inkl. überfällige'"
          [(ngModel)] = "dueDateModel"
          (onClear)="filter()"
          (onChange)="filter()"
          optionLabel="label">
        </nx-filter-date-select>
      </div>
      <div class="basis-1/3 flex flex-row justify-end">
        <p-dropdown
          placeholder="Sortierung"
          [styleClass]="'sort'"
          appendTo="body"
          optionLabel="label"
          [(ngModel)] = "sortModel"
          (onChange)="sort()"
          [options]="sortOptions"
          [showClear]="false">
        </p-dropdown>
      </div>
    </div>
  </div>

  <div class="basis-full flex flex-row">
    <div class="basis-5/12">
      <nx-empty-box
      *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
      [label]="'Keine Aufgaben gefunden.'">
    </nx-empty-box>
    <div
      (click)="selectTask(task)"
      *ngFor="let task of dataSource?.data; let isLast = last"
      class="cursor-pointer"
      [ngClass]="{
        'border-b' : !isLast, 
        'bg-[#f3f4f6]' : isSelected(task), 'bg-white' : !isSelected(task)}">
      <div
        task-row
        (refetchTasks)="reload()"
        [showStateLabel]="true"
        [showCommandMenu]="task?.type === 'nexcore_default_task' && task?.commands?.length > 0"
        [showOrderNote]="true"
        [showAddress]="false"
        [task]=task
        class="flex flex-row basis-full items-center">
      </div>
    </div>



    <p-paginator 
      [dropdownAppendTo]="'body'"
      [showCurrentPageReport]="true" 
      (onPageChange)="onPageChange($event)" 
      [first]="pageModel?.pageNumber * pageModel?.pageSize"
      [rows]="pageModel?.pageSize"
      [totalRecords]="dataSource?.totalElements"
      currentPageReportTemplate="{first} - {last} von {totalRecords}"
      [rowsPerPageOptions]="[15, 20, 50]">
    </p-paginator>
    </div>
    <div class="basis-7/12">
      <div class="basis-7/12 mt-2 ml-2">
        <app-task-detail-view
          (refetch)="refetchTasks(true)"
          [task]="selectedTask">
        </app-task-detail-view>
      </div>
    </div>
  </div>
</div>

