import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FilterDateSelectValue } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { debounceTime, Subject, take } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { AbstractAssignmentList, PageModel, SortModel } from '../../components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { LocationService } from '../../shared/services/location/location.service';
import { TaskDetailViewComponent } from '../task-detail-view/task-detail-view.component';
import { TasksListDataSource } from './TasksListDataSource';

@Component({
  selector: 'tasks-list',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent extends AbstractAssignmentList {

  // text
  @ViewChild('taskDetailView') taskDetailView: TaskDetailViewComponent;

  dataSource: any;
  public selectedTask: Task;
  public tasks: Task[];

  // text
  @ViewChild('globalSearch') globalSearchInput!: ElementRef;
  private textSubject = new Subject<string>();
  public textModel: string;
  public defaultTextModel: string = undefined;

  // types
  public typesModel: any[] = [];
  public typeOptions = [
   { id: 'nexcore_rental_resourceAssignmenmt_review', name: 'Prüfen', label: 'Prüfen' },
   { id: 'nexcore_rental_resourceAssignmenmt_refuel', name: 'Nachtanken', label: 'Nachtanken' },
   { id: 'nexcore_rental_resourceAssignmenmt_out', name: 'Übergabe', label: 'Übergabe' },
   { id: 'nexcore_rental_resourceAssignmenmt_in', name: 'Rücknahme', label: 'Rücknahme' },
   { id: 'nexcore_default_task', name: 'Aufgabe', label: 'Aufgabe' }
  ];
  // load all types by default
  // defaultTypesModel: what the user sees (nothing selected)
  public defaultTypesModel = [];
  // emptyTypesModel: what is actually send to the server: only review tasks
  public emptyTypesModel = []

  // locations - options will be generated as a result of the locationService.getLocations() call
  public locationsModel: any[] = [];
  public locationsOptions = [];
  public defaultLocationsModel = undefined; // load all locations by default

  // dueDate
  public dueDateModel: FilterDateSelectValue = undefined;
  public defaultDueDateModel = undefined; // load all locations by default

  // includeOverDue
  public includeOverdueModel: undefined | boolean;
  public defaultIncludeOverdueModel = undefined;
  public includeOverdueOptions = [
    { id: true, name: 'Ja', label: 'Ja' },
    { id: false, name: 'Nein', label: 'Nein' }
  ];
  public emptyIncludeOverdue = false;

  // completedDate
  public completedDateModel: FilterDateSelectValue = undefined;
  public completedDueDateModel = undefined; // load all locations by default

  // states
  public statesModel: any[] = [];
  public statesOptions = [
    { id: 'COMPLETED', name: 'Erledigt', label: 'Erledigt' },
    { id: 'ASSIGNED', name: 'Zugewiesen', label: 'Zugewiesen' },
    { id: 'NEW', name: 'Neu', label: 'Neu' },
    { id: 'UNASSIGNED', name: 'Nicht zugewiesen', label: 'Nicht zugewiesen' }
  ];
  public defaultStatesModel = undefined; // load all types by default

  // sorting
  public sortModel: SortModel;
  public sortOptions : SortModel[] = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Auftragsnummer aufsteigend', fieldName: 'order.number', direction: "asc" },
    { label: 'Auftragsnummer absteigend', fieldName: 'order.number', direction: "desc"},
    { label: 'Fällig aufsteigend', fieldName: 'dueDateTimePeriod', direction: "asc" },
    { label: 'Fällig absteigend', fieldName: 'dueDateTimePeriod', direction: "desc"},
    { label: 'Erledigt aufsteigend', fieldName: 'completedDateTime', direction: "asc" },
    { label: 'Erledigt absteigend', fieldName: 'completedDateTime', direction: "desc"},
    { label: 'Kunde aufsteigend', fieldName: 'customer', direction: "asc" },
    { label: 'Kunde absteigend', fieldName: 'customer', direction: "desc"}
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };

  constructor(
    private apollo: Apollo,
    private changeDetectorRef: ChangeDetectorRef,
    private locationService: LocationService,
  ) {
    super('TasksComponent.tasksList', 'local' )
    this.locationService.getLocations().pipe(
      take(1)
    ).subscribe(locations => {
      if(locations){
        this.locationsOptions = LocationEntity.createLocations(locations);
      }
    });
    let state = this.loadState();
    this.pageModel = this.defaultPageModel;
    // sortModel
    let stateSortModel = state?.sortModel;
    let matchedSortModel = this.sortOptions.find(option => option.fieldName === stateSortModel?.fieldName && option.direction === stateSortModel?.direction);
    this.sortModel = matchedSortModel ? matchedSortModel : this.defaultSortModel;
    // types model
    let stateTypesModel = state?.filter?.typesModel;
    const typeModelIds = new Set(stateTypesModel?.map(option => option.id));
    const matchedTypesModel = this.typeOptions?.filter(option => typeModelIds?.has(option?.id));
    this.typesModel = this.defaultTypesModel;
    this.typesModel = matchedTypesModel ? matchedTypesModel : this.defaultTypesModel;
    // states model
    let stateStatesModel = state?.filter?.statesModel;
    this.statesModel = stateStatesModel ? stateStatesModel : this.defaultStatesModel;
    // dueDate model
    let stateDueDateModel = state?.filter?.dueDateModel;
    this.dueDateModel = stateDueDateModel ? stateDueDateModel : this.defaultDueDateModel;
    // completedDate model
    let stateCompletedDateModel = state?.filter?.completedDateModel;
    this.completedDateModel = stateCompletedDateModel ? stateCompletedDateModel : this.completedDueDateModel;
    // locations model
    let stateLocationsModel = state?.filter?.locationsModel;
    this.locationsModel = stateLocationsModel ? stateLocationsModel : this.defaultLocationsModel;
    this.includeOverdueModel = this.defaultIncludeOverdueModel;
    this.textSubject.pipe(
      debounceTime(500)
    ).subscribe(text => {
      this.textModel = text;
      this.filter();
    });
    this.dataSource = new TasksListDataSource(this.apollo, this.changeDetectorRef, this.dataLoadedCallback.bind(this));
    this.loadTasks();
  }

  loadTasks(){
    this.dataSource?.loadTasks(this.buildVariables());
  }

  reload(): void {
    this.refetchTasks(true);
  }

  refetchTasks(force = false): void {
    this.storeState({
      // pageModel: this.pageModel,
      sortModel: this.sortModel,
      filter: {
        typesModel: this.typesModel,
        locationsModel: this.locationsModel,
        statesModel: this.statesModel,
        dueDateModel: this.dueDateModel,
        completedDateModel: this.completedDateModel
      }
    });
    this.dataSource.refetchQuery(this.buildVariables(), force);
  }

  filter(){
    console.log('filter');
    // if no dateModel is set, we reset the overdueModel to undefined
    if(!this.dueDateModel){
      this.includeOverdueModel = this.emptyIncludeOverdue ;
    }
    this.refetchTasks();
  }

  sort() {
    this.refetchTasks();
  }

  onSearchInput(text: string) {
    this.textSubject.next(text);
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchTasks();
  }

  buildVariables(): any {
    return {
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      locationIds: this.locationsModel,
      sortDirection: this.sortModel?.direction,
      types: this.typesModel?.length === 0 || this.typesModel === undefined ?  this.emptyTypesModel?.map(type => type.id) : this.typesModel?.map(type => type.id),
      states: this.statesModel,
      fromDueDateTime: this.dueDateModel?.fromDate ? this.dueDateModel?.fromDate : undefined,
      toDueDateTime: this.dueDateModel?.toDate ? this.dueDateModel?.toDate : undefined,
      includeOverDue: this.dueDateModel?.includeOverDue == undefined || this.dueDateModel?.includeOverDue === null ? this.emptyIncludeOverdue : this.dueDateModel?.includeOverDue,
      fromCompletedDateTime: this.completedDateModel?.fromDate ? this.completedDateModel?.fromDate : undefined,
      toCompletedDateTime: this.completedDateModel?.toDate ? this.completedDateModel?.toDate : undefined,
      text: this.textModel,
    }
  }



  isSelected(task: Task): boolean {
    if(this.selectedTask?.id === task?.id){
      return true;
    }
    return false;
  }

  selectTask(task: Task) {
    if(this.selectedTask === task){
      this.selectedTask = undefined;
      return;
    } else {
      this.selectedTask = task;
    }
  }

  dataLoadedCallback(tasks: Task[]) {
    // no task is selected, set the first
    this.tasks = tasks;
    if(!this.selectedTask && tasks?.length > 0){
      this.selectedTask = tasks[0];
    }
    // if the selected task is not in the list anymore, select the first task
    if(this.selectedTask && !tasks.find(task => task.id === this.selectedTask.id)){
      this.selectedTask = tasks[0];
    }
  }

  getDataSource() {
    this.dataSource;
  }
  
}
