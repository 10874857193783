import { Component, Input } from '@angular/core';
import { Contact } from '../../shared/entities/contact/contact';

@Component({
    selector: 'app-contact-task-view',
    template: `
    <nx-default-box 
        class="bg-white border-b border-gray"
        [label]="label">
        <div class="flex items-center">
            <ng-container
                *ngIf="contact; else noContact">
                <a [routerLink]="['/customers/customer/', contact?.id ]" class="jump">
                    {{contact?.getFullName()? contact?.getFullName() :'-'}} {{contact?.number? '(' +
                    contact?.number+ ')': ''}}
                    <i [className]="'icon icon-jump-to'"></i>
                </a>
            </ng-container>
            <ng-template #noContact>
                -
            </ng-template>
        </div>
    </nx-default-box>
    `,
    styles: [`

  `]
})
export class ContactTaskViewComponent{

    @Input() contact: Contact;
    @Input() label: string;

    constructor() {
    }

}