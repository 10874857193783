import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../../shared/entities/task/task';

@Component({
    selector: 'app-complete-button-task-view',
    template: `
<div
    class="justify-end flex flex-row mt-4">
    <nx-default-button
        *ngIf="task?.state !== 'COMPLETED'"
        (onClick)="completeTask.emit()"
        [disabled]="!canComplete"
        class="confirm"
        [tooltip]="notCompletedLabel ? notCompletedLabel : 'Als erledigt markieren'"
        [style]="'dark'"
        [label]="notCompletedLabel ? notCompletedLabel : 'Als erledigt markieren'">
    </nx-default-button>
    
    <nx-default-button
        *ngIf="task?.state === 'COMPLETED'"
        [disabled]="true"
        class="done"
        [tooltip]="'Erledigt'"
        [style]="'dark'"
        [label]="'Erledigt'">
    </nx-default-button>

</div>
    `,
    styles: [`

  `]
})
export class CompleteButtonTaskViewComponent{

    @Input() task: Task;
    @Input() canComplete = false;
    @Input() notCompletedLabel: string = undefined;

    @Output() completeTask = new EventEmitter();

    constructor() {
    }

}