import { gql } from 'apollo-angular';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

export const GET_TASK = gql`
query getTask($id: UUID){
  getTask(id: $id){
    id
    title
    description
    location{
      id
    }
    dueDateTimePeriod {
      id
      modifier
      dateTime
      fromDateTime
      toDateTime
      precision
    }
    address {
      id
      country
      city
      postalCode
      street
      houseNumber
      name
      lat
      lng
      text
      type
      note
      geocodingResultGrade
    }
    linkedResources: linkedEntities(entityType: "Resource") {
        linkType
        entity {
          ... on Resource {
              id
              name
              number
          }
        }
    }
    linkedContacts: linkedEntities(entityType: "Contact") {
      linkType
      entity {
        ... on Contact {
            id
            type
            number
            companyName
            companyNameExtension
            firstName
            lastName
        }
      }
    }
  }
}
`;

export interface GetTaskResponse {
  getTask: Task;
  loading: boolean;
}
