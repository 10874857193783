<div [formGroup]="splitTaskForm" class="form flex flex-col">
  {{commandTemplate?.flow?.settings?.customFlowSettings?.message}}

  <div class="flex flex-col mt-2">
    <div
      (click)="isSelectable(task) ? selectTask(task) : null"
      [columns]="defaultColumns"
      *ngFor="let task of tasksToDisplay; let isLast = last, let isFirst = first"
      task-row-wide
      [showFilesButton]="false"
      [showCommentsButton]="false"
      [showCommandMenu]="false"
      class="bg-white border-gray border-t last:border-b"
      [ngClass]="{'bg-[#f3f4f6]' : isSelected(task), 'bg-white' : !isSelected(task), 'cursor-pointer': isSelectable(task)}"
      [task]=task>
    </div>
  </div>

  <!-- <div *ngIf="splitTaskForm.value" class="selected-values">
    <h4>Ausgewählte Tasks:</h4>
    <pre>{{ splitTaskForm.value | json }}</pre>
  </div> -->
</div>
