import { gql } from 'apollo-angular';
import { AssignmentStrategyCandidate } from '../../entities/assignmentStrategyCandidate/assignmentStrategyCandidate';

export const COMPLETE_TASK_MUTATION = gql`
mutation completeTask($taskId: UUID, $formModelInput: FormModelInput) {
  completeTask(taskId: $taskId, formModelInput: $formModelInput){
    id
  }
}
`;

export const ASSIGN_TASK_MUTATION = gql`
mutation assignTask($taskAssignmentInput: TaskAssignmentInput) {
  assignTask(taskAssignmentInput: $taskAssignmentInput){
    id
  }
}
`;

export const ASSIGN_TASKS_MUTATION = gql`
mutation assignTasks($taskAssignmentInputs: [TaskAssignmentInput]) {
  assignTasks(taskAssignmentInputs: $taskAssignmentInputs){
    id
  }
}
`;

export const UNASSIGN_TASKS_MUTATION = gql`
mutation unassignTasks($taskIds: [UUID]) {
  unassignTasks(taskIds: $taskIds){
    id
  }
}
`;

export const UNASSIGN_TASK_MUTATION = gql`
mutation unassignTask($taskId: UUID) {
  unassignTask(taskId: $taskId){
    id
  }
}
`;

export const GET_ASSIGNMENT_STRATEGY_CANDIDATES_QUERY = gql`
query assignmentStrategyCandidates($taskId: UUID, $strategyTypes: [String]) {
  getAssignmentStrategyCandidates(taskId: $taskId, strategyTypes: $strategyTypes){
    label
    type
    settings
  }
}
`;

export interface GetAssignmentStrategyCandidatesResponse {
  getAssignmentStrategyCandidates: AssignmentStrategyCandidate[];
  loading: boolean;
}
