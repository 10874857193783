import { gql } from 'apollo-angular';
import { DriverTourDay, ResourceTourDay } from '../../shared/entities/tour';

export const TOUR_PLANNER_TOURS_BY_RESOURCE_QUERY = gql`
query getToursForPlanningByResource(
  $locationId: UUID,
  $fromDateTime: String,
  $toDateTime: String,
  $states:[TourState]
)
{
  dailyTours: getToursForPlanningByResource(
    filter: {
      locationId: $locationId,
      fromDateTime: $fromDateTime,
      toDateTime: $toDateTime,
      states: $states,
    }
  ){
    date
      resources{
        id
        name
        tours{
          ...tourExcerpt
        }
      }
    }
  }
  fragment tourExcerpt on Tour{
    id
    name
    scheduledDepartureDateTime
    scheduledArrivalDateTime
    actualDepartureDateTime
    actualArrivalDateTime
    dateTimeReference
    state
    note
    driver{
      id
      firstName
      lastName
    }
    resource {
      id
      name
    }
    tasks{
      ... dailyTaskExcerpt
    }
    effectiveArrivalDateTime
    effectiveDepartureDateTime
  }
  fragment dailyTaskExcerpt on Task{
    id
    type
    color
    dueDateTimePeriod {
      modifier
      dateTime
      fromDateTime
      toDateTime
      precision
    }
    state
    description
    order{
      id
      number
      note
      commentCount
      fileCount
      customer {
        type
        companyName
        companyNameExtension
        firstName
        lastName
      }
    }
    address {
      id
      country
      city
      postalCode
      street
      houseNumber
      name
      lat
      lng
      text
      type
      note
      geocodingResultGrade
      externalAddress
    }
    linkedContacts: linkedEntities(entityType: "Contact") {
      linkType
      entity {
        ... on Contact {
            id
            type
            number
            companyName
            companyNameExtension
            firstName
            lastName
        }
      }
    }
  }
`;

export interface TourPlannerToursByResourceQueryResponse {
  dailyTours: ResourceTourDay[];
}

export const TOUR_PLANNER_TOURS_BY_DRIVER_QUERY = gql`
query getToursForPlanningByDriver(
  $locationId: UUID,
  $fromDateTime: String,
  $toDateTime: String,
  $states:[TourState]
)
{
  dailyTours: getToursForPlanningByDriver(
    filter: {
      locationId: $locationId,
      fromDateTime: $fromDateTime,
      toDateTime: $toDateTime,
      states: $states,
    }
  ){
    date
      drivers{
        id
        name
        tours{
          ...tourExcerpt
        }
      }
    }
  }
  fragment tourExcerpt on Tour{
    id
    name
    scheduledDepartureDateTime
    scheduledArrivalDateTime
    actualDepartureDateTime
    actualArrivalDateTime
    dateTimeReference
    state
    note
    driver{
      id
      firstName
      lastName
    }
    resource {
      id
      name
    }
    tasks{
      ... dailyTaskExcerpt
    }
    effectiveArrivalDateTime
    effectiveDepartureDateTime
  }
  fragment dailyTaskExcerpt on Task{
    id
    type
    color
    dueDateTimePeriod {
      modifier
      dateTime
      fromDateTime
      toDateTime
      precision
    }
    state
    title
    description
    commands
    order{
      id
      number
      note
      commentCount
      fileCount
      customer {
        type
        companyName
        companyNameExtension
        firstName
        lastName
      }
    }
    address {
      street
      houseNumber
      postalCode
      city
      country
      geocodingResultGrade
      name
      note
    }
    linkedContacts: linkedEntities(entityType: "Contact") {
      linkType
      entity {
        ... on Contact {
            id
            type
            number
            companyName
            companyNameExtension
            firstName
            lastName
        }
      }
    }
  }
`;

export interface TourPlannerToursByDriverQueryResponse {
  dailyTours: DriverTourDay[];
}
