import { Task } from '../../shared/entities/task/task';

export interface ComponentState {
    pageNumber?: number;
    pageSize?: number;
    sortProperty?: string;
    sortDirection?: string;
    sortModel?: SortModel;
    pageModel?: PageModel;
    filter?: any;
    expandedModel?: boolean;
  }

export interface SortModel {
  label: string;
  fieldName: string;
  direction: string;
}

export interface PageModel {
  pageNumber: number;
  pageSize: number;
}
export abstract class AbstractAssignmentList {

    public selectedTasks: Task[];

    constructor(
        private stateKey: string,
        private stateStorage: 'session' | 'local' = 'session') {
    }
    /**
     * stores the component state in the selected storage
     * @param componentState 
     */
    public storeState(componentState: ComponentState) {
        if(!this.stateKey || !this.stateStorage){
            // both must be set
            return;
        }
        try {
            if (this.stateStorage === 'session') {
                sessionStorage.setItem(this.stateKey, JSON.stringify(componentState));
            } else {
                localStorage.setItem(this.stateKey, JSON.stringify(componentState));
            }
        } catch (error) {
            // in case of any error, we clear the storage
            this.clearState();
        }
    }

    public loadState(): ComponentState {
        if(!this.stateKey || !this.stateStorage){
            // both must be set
            return;
        }
        try {
            let stateString = null;
            if (this.stateStorage === 'session') {
                stateString = sessionStorage.getItem(this.stateKey);
            } else {
                stateString = localStorage.getItem(this.stateKey);
            }
            if (stateString) {
                return JSON.parse(stateString);
            }
        } catch (error) {
            // in case of any error, we clear the storage
            this.clearState();
        }
    }

    public clearState() {
        localStorage.removeItem(this.stateKey);
        sessionStorage.removeItem(this.stateKey);
    }

      // select task logic
  selectTask(task: Task) {
    if(!this.selectedTasks){
      this.selectedTasks = [];
    }
    if(this.selectedTasks.includes(task)){
      this.selectedTasks = this.selectedTasks.filter(selectedTask => selectedTask.id !== task.id);
    } else {
      this.selectedTasks.push(task);
    }
  }

  isSelected(task: Task): boolean {
    if(this.selectedTasks?.includes(task)){
      return true;
    }
    return false;
  }

  selectAllTasks() {
    if(!this.selectedTasks){
      this.selectedTasks = [];
    }
    if(this.getDataSource()?.data){
      this.getDataSource()?.data.forEach(task => {
        if(!this.selectedTasks.includes(task) && this.isSelectable(task)){
          this.selectedTasks.push(task);
        }
      });
    }
  }

  clearSelection() {
    this.selectedTasks = [];
  }

  setStateKey(key: string) {
    this.stateKey = key;
  }

  abstract getDataSource(): any;

  isSelectable(task: Task): boolean {
    return true;
  }

}