import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApolloService, EditDialogComponent, EditDialogInstance } from '@nexato/nx-core-module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { TaskInput } from 'src/app/rent-module/shared/entities/taskInput/taskInput';
import { TaskLinkService } from 'src/app/rent-module/shared/services/taskLink/tasksLink.service';
import { LocationInput } from 'src/app/shared-module/shared/entities/location/location';
import { TaskService } from '../../shared/services/task/task.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss']
})
export class TaskDialogComponent implements EditDialogInstance {

    taskForm: FormGroup;
    task: Task;
    taskId: string;

  constructor(  
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public taskService: TaskService,
    private apolloService: ApolloService,
    private taskLinkService: TaskLinkService
  ) {

    this.taskForm = new FormGroup({});
    this.taskForm.addControl('id', new FormControl());
    this.taskForm.addControl('title', new FormControl(undefined, Validators.required));
    this.taskForm.addControl('description', new FormControl(undefined, []));
    this.taskForm.addControl('dueDateTimePeriod', new FormControl(undefined, [Validators.required]));
    this.taskForm.addControl('address', new FormControl(undefined, []));
    this.taskForm.addControl('ressources', new FormControl(undefined, []));
    this.taskForm.addControl('contact', new FormControl(undefined, []));
    this.taskForm.addControl('locationId', new FormControl(undefined, []));

    // check, if we have to load a task (edit mode)
    if (this.config.data) {
      this.taskId = this.config.data;
      this.apolloService.query<fromGraphQl.GetTaskResponse>(fromGraphQl.GET_TASK, {
        id: this.taskId
      })
      .pipe(
        take(1)
      ).subscribe((result) => {
        this.task = new Task(result?.data?.getTask);
        this.taskForm.patchValue(this.task);
        this.taskForm.controls['ressources'].setValue(this.task.linkedResources);
        this.taskForm.controls['contact'].setValue(this.task.linkedContact);
        this.taskForm.controls['locationId'].setValue(this.task.location?.id);  
      });
    }

    // this.taskForm.valueChanges.subscribe((value) => {
    //   console.log(value);
    // });
  }


  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    console.log(this.taskForm.value);
    let taskInput = new TaskInput();
    taskInput.id = this.taskForm.value.id;
    taskInput.title = this.taskForm.value.title;
    taskInput.description = this.taskForm.value.description;
    taskInput.dueDateTimePeriod = this.taskForm.value.dueDateTimePeriod;
    taskInput.address = this.taskForm.value.address;
    if (this.taskForm.value.locationId) {
      let locationInput = new LocationInput();
      locationInput.id = this.taskForm.value.locationId;
      taskInput.location = locationInput;
    }
    this.taskLinkService.buildTaskLinksFromTaskLinkCandidates(taskInput, this.taskForm.value.ressources);
    this.taskLinkService.buildTaskLinksFromTaskLinkCandidate(taskInput, this.taskForm.value.contact);
    console.log(taskInput);
    this.ref.close(taskInput);
  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

  getEditForm(): FormGroup {
    return this.taskForm;
  }

}
