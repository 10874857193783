<div [formGroup]="taskForm" class="form flex flex-col">
  <nx-edit-text-input
    formControlName="title"
    [placeholder]="'Aufgabenname hinzufügen'"
    label="Aufgabenname">
  </nx-edit-text-input>
  <nx-edit-dateTime-input-v2
    formControlName="dueDateTimePeriod"
    [placeholder]="'Fälligkeit hinzufügen'"
    label="Fälligkeit">
  </nx-edit-dateTime-input-v2>
  <nx-edit-linked-location-input
    formControlName="locationId"
    [placeholder]="'Niederlassung hinzufügen'"
    label="Niederlassung">
  </nx-edit-linked-location-input>
  <nx-edit-linked-address-input
    formControlName="address"
    [placeholder]="'Adresse hinzufügen'"
    label="Adresse">
  </nx-edit-linked-address-input>
  <nx-edit-linked-contact-input
    formControlName="contact"
    [emptyMessage]="'Keine Kontakte gefunden.'"
    [placeholder]="'Kontakt hinzufügen'"
    label="Kontakt">
  </nx-edit-linked-contact-input>
  <nx-edit-linked-resources-input
    formControlName="ressources"
    [emptyMessage]="'Keine Ressourcen gefunden.'"
    [placeholder]="'Ressourcen hinzufügen'"
    label="Ressourcen">
  </nx-edit-linked-resources-input>
  <nx-textfield-input
    formControlName="description"
    [showLabel]="false"
    [placeholder]="'Beschreibung hinzufügen'"
    label="Beschreibung">
  </nx-textfield-input>
<div>