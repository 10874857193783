import { Component, Input } from '@angular/core';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

@Component({
    selector: 'app-location-task-view',
    template: `
    <nx-default-box 
        class="bg-white border-b border-gray "
        [label]="'Niederlassung'">
        <div class="flex items-center whitespace-pre-wrap">{{location?.label ? location?.label :  location?.name}}</div>
    </nx-default-box>
    `,
    styles: [`

  `]
})
export class LocationTaskViewComponent{

    @Input() location: LocationEntity;

    constructor() {
    }

}