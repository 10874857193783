import { Component, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { ResourceAssignment } from '../../shared/entities/resourceAssignment/resourceAssignment';
import { TaskView } from '../task-detail-view/task-detail-view.component';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-nexcore-rental-resourceAssignment-out-task-view',
  templateUrl: './nexcore-rental-resourceAssignment-out-task-view.component.html',
  styleUrls: ['./nexcore-rental-resourceAssignment-out-task-view.component.scss']
})
export class NexcoreRentalResourceAssignmenmtOutTaskViewComponent implements TaskView  {

  resourceAssignents: ResourceAssignment[];

  _task: Task;
  @Input() set task(task: Task) {
    if(task) {
      this._task = task;
      this.loadResourceAssignments(task);
    }
  }

  constructor(
    private apollo: Apollo
  ) {

  }

  loadResourceAssignments(task: Task) {
    if(task?.context) {
      this.apollo.query<fromGraphQl.RentalResourceAssignmentReviewQuery>({
        query: fromGraphQl.RENTAL_RESOURCE_ASSIGNMENT_REVIEW_QUERY,
        variables: {
          ids: task?.context?.map(element => element.id)
        },
        fetchPolicy: 'network-only',
      })
      .pipe(take(1))
      .subscribe( result => {
        this.resourceAssignents = ResourceAssignment.createResourceAssignments(result?.data?.resourceAssignments);
      })
    }
  }
  
  getHeadline(): string {
    return this._task?.formControlTemplate?.settings?.label;
  }
  
}
