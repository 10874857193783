<nx-headline-box
  label="Dateiliste">
  <ng-template nxTemplate="end">
    <nx-default-button
      *ngIf="rolesService.hasRole('framework_files_manage')"
      [style]="'light'"
      (click)="fileInput.click()"
      [icon]="'icon-plus'"
      [label]="'Datei hochladen'">
    </nx-default-button>
    <input #fileInput type="file"  style="display:none;"  (change)="fileChangeEvent($event)" accept="application/pdf, image/jpg, image/jpeg, image/png"/>
  </ng-template>
</nx-headline-box>

<nx-empty-box *ngIf="!files|| files?.length<1"
  [ngClass]="{
      'min-w-[485px]': style === 'overlay' }"
  [label]="'Keine Dateien vorhanden.'">
</nx-empty-box>

<div
*ngFor="let file of files; let isFirst = first">
  <app-file (reloadFileList)="refetch()" [file]="file"></app-file>
</div>



