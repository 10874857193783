import { gql } from 'apollo-angular';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

export const TASKS_QUERY = gql`
query TaskListQuery(
  $ids: [UUID]
)
{
  tasks: getTasksByIds(
    ids: $ids){
        id
        description
        type
        state
        handlingType
        color
        order{
          id
          number
          customer{
            type
            companyName
            firstName
            lastName
          }
        }
        dueDateTimePeriod {
          modifier
          dateTime
          fromDateTime
          toDateTime
          precision
        }
    }
}
`;

export interface TasksQueryResponse {
  tasks: Task[];
  loading: boolean;
}


