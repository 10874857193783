import { Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { EditDialogService, Location, LocationService } from '@nexato/nx-core-module';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs';
import { Address, AddressInput } from '../../shared/entities/address/address';

@Component({
    selector: 'nx-edit-linked-location-input',
    template: `
    <nx-edit-select-input
        [placeholder]="placeholder"
        [(ngModel)]=selectedLocationId
        (ngModelChange)="onSelect()"
        [options]="locations"
        [showClear]="true"
        [label]="label"></nx-edit-select-input>
    `,
    styles: [`
  `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditLinkedLocationInputComponent),
            multi: true
        }
    ]
})
export class EditLinkedLocationInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() label: string;
    @Input() placeholder: string;

    locations: Location[];
    selectedLocationId: string;

    ngControl: NgControl;
    isDisabled: boolean;
    dialogRef: DynamicDialogRef | undefined;

    // If the address was cleared by the user and the address had already an id, we store the id here, 
    // so that we cann add it to the addressInput when the user selects a new address. So we make sure
    // that the addressInput has the same id as the address that was cleared.
    clearedAddressId: string;
    address: Address;
    addressInput: AddressInput;

    private onChange: (value: any) => void;
    private onTouched: () => void;


    constructor(
        private locationService: LocationService,
        public dialogService: EditDialogService,
        private _injector: Injector) {
        this.locationService.getLocations().pipe(
                take(1)
            ).subscribe((locations) => {
            this.locations = locations;
        });
    }

    ngOnInit() {
        this.ngControl = this._injector.get(NgControl);
        this.ngControl.valueAccessor = this;
    }

    ngOnDestroy(): void {
    }

    writeValue(locationId: string): void {
        if(locationId){
            this.selectedLocationId = locationId;
        }
    }

    onSelect() {
        if (this.onChange) {
            this.onChange(this.selectedLocationId);
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    isRequired(): boolean {
        return this.ngControl?.control?.hasValidator(Validators.required);
    }

}