import { gql } from 'apollo-angular';
import { Tour } from '../../shared/entities/tour';

export const TOUR_PLANNER_TOURS_QUERY = gql`
query GetDailyToursQuery(
  $locationId: UUID,
  $states:[TourState],
  $fromDateTime: String,
  $toDateTime: String
)
{
  dailyTours: getTours(
    filter: {
      locationId: $locationId,
      states: $states,
      fromDateTime: $fromDateTime,
      toDateTime: $toDateTime
  }
  ){
    ...tourExcerpt
  }
}
fragment tourExcerpt on Tour{
  id
  name
  scheduledDepartureDateTime
  scheduledArrivalDateTime
  actualDepartureDateTime
  actualArrivalDateTime
  dateTimeReference
  state
  note
  driver{
    id
    firstName
    lastName
  }
  resource {
    id
    name
  }
  tasks{
    ... dailyTaskExcerpt
  }
  effectiveArrivalDateTime
  effectiveDepartureDateTime
}
fragment dailyTaskExcerpt on Task{
  id
  type
  color
  fileCount
  commentCount
  dueDateTimePeriod {
    modifier
    dateTime
    fromDateTime
    toDateTime
    precision
  }
  state
  title
  description
  commands
  order{
    id
    number
    note
    commentCount
    fileCount
    customer {
      type
      companyName
      companyNameExtension
      firstName
      lastName
    }
  }
  address {
    id
    street
    houseNumber
    postalCode
    city
    country
    geocodingResultGrade
    name
    note
    lat
    lng
    text
    type
    note
    externalAddress
  }
  linkedContacts: linkedEntities(entityType: "Contact") {
    linkType
    entity {
      ... on Contact {
          id
          type
          number
          companyName
          companyNameExtension
          firstName
          lastName
      }
    }
  }
}
`;

export interface TourPlannerToursQueryResponse {
  dailyTours: Tour[];
}