import { Component, Input } from '@angular/core';
import { Task } from '../../shared/entities/task/task';

@Component({
    selector: 'app-description-task-view',
    template: `
    <nx-default-box 
        class="bg-white border-b border-gray "
        [label]="'Beschreibung'">
        <div class="flex items-center whitespace-pre-wrap">{{task?.description ? task?.description : '-'}}</div>
    </nx-default-box>
    `,
    styles: [`

  `]
})
export class DescriptionTaskViewComponent{

    @Input() task: Task;

    constructor() {
    }

}