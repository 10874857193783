<div [formGroup]="splitTaskForm" class="form flex flex-col">
  {{commandTemplate?.flow?.settings?.customFlowSettings?.message}}

  <div class="flex flex-col mt-2">
    <div
      [columns]="defaultColumns"
      *ngFor="let task of tasksToDisplay; let isLast = last, let isFirst = first"
      task-row-wide
      [showFilesButton]="false"
      [showCommentsButton]="false"
      [showCommandMenu]="false"
      class="bg-white border-gray border-t last:border-b"
      [task]=task>
    </div>
  </div>

</div>
