import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

export const MY_TASKS_LIST_QUERY = gql`
query GetMyTasksListQuery(
  $sortProperty: String,
  $sortDirection: String,
  $text: String,
  $locationIds: [UUID]
  $fromDueDateTime: String,
  $toDueDateTime: String,
  $includeOverDue: Boolean,
  $pageNumber: Int, $pageSize: Int,
  $assignedToCurrentUser: Boolean,
  $types: [String]
)
{
  myTasks: getTasksPaged(
    taskFilter: {
      states : [ASSIGNED],
      locationIds: $locationIds 
      text: $text,
      types: $types,
      fromDueDateTime: $fromDueDateTime,
      toDueDateTime: $toDueDateTime,
      includeOverDue: $includeOverDue,
      assignedToCurrentUser: $assignedToCurrentUser,
      sortRequest: { 
        sortProperties: [$sortProperty],
        sortDirection: $sortDirection
      },
      pagination: {
        number: $pageNumber,
        size: $pageSize,
        sortProperty: $sortProperty,
        sortDirection: $sortDirection
      },
    }
  ){
    totalPages
    totalElements
    number
    size
    content{
      ... taskExcerpt
    }
  }
}

fragment taskExcerpt on Task{
  id
  type
  dueDateTimePeriod {
    modifier
    dateTime
    fromDateTime
    toDateTime
    precision
  }
  state
  title
  color
  description
  commands
  order{
    id
    number
    type
    note
    customer {
      type
      companyName
      companyNameExtension
      firstName
      lastName
    }
    location {
      id
      name
    }
    comments {
      text
    }
    fileCount
    commentCount
  }
  linkedContacts: linkedEntities(entityType: "Contact") {
    linkType
    entity {
      ... on Contact {
          id
          type
          number
          companyName
          companyNameExtension
          firstName
          lastName
      }
    }
  }
}
`;


export interface MyTasksListQueryResponse {
  myTasks:Page<Task>;
}


