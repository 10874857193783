import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommandTemplate, EditDialogComponent, EditDialogInstance } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import * as fromGraphQl from './graphql';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-compose-atomic-task-dialog",
  templateUrl: "compose-atomic-task-dialog.html",
  styleUrls: ["./compose-atomic-task-dialog.scss"]
})
export class ComposeAtomicTaskDialogComponent implements EditDialogInstance {

  // address form
  splitTaskForm: FormGroup;
  commandTemplate: CommandTemplate

  tasksToDisplay: Task[] = [];
  
  selectedTasks: string[] = [];

  taskIds: string[] = [];

  defaultColumns = [
    'dueDateTimePeriod',
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private apollo: Apollo
  ) {
    this.commandTemplate = this.config?.data.commandTemplate;

    // collect the ids of the tasks that we must load
    this.taskIds = this.commandTemplate?.flow?.settings?.customFlowSettings?.tasksIdsToCompose;

    // load the tasks
    if(this.taskIds && this.taskIds.length > 0) {
      this.apollo.query<fromGraphQl.TasksQueryResponse>({
        query: fromGraphQl.TASKS_QUERY,
        variables: {
          ids: this.taskIds
        },
        fetchPolicy: 'network-only',
      })
      .pipe(take(1))
      .subscribe( result => {
        this.tasksToDisplay = Task.createTasks(result?.data?.tasks);
      })
    }

    this.splitTaskForm = this.fb.group({
      selectedAtomicTasks: [this.taskIds, [
        Validators.required,
        Validators.minLength(1)
      ]]
    });
  }

  getEditForm(): FormGroup {
    return this.splitTaskForm;
  }

  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    console.log(this.selectedTasks);
    this.ref.close({
      "taskIds" :  this.taskIds
    });
  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

}
