import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Notification } from '../../shared/entities/notification/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private allNotificationsSubject = new BehaviorSubject<Notification[]>([]);
  public allNotifications$ = this.allNotificationsSubject.asObservable();
  private unreadNotificationsSubject = new BehaviorSubject<Notification[]>([]);
  public unreadNotifications$ = this.unreadNotificationsSubject.asObservable();
  
  constructor() {
    const initialNotifications = [
      // this.getNotification0(),
      this.getNotification1()
    ];
    this.allNotificationsSubject.next(initialNotifications);
    this.unreadNotificationsSubject.next(initialNotifications.filter(notification => !notification.readDateTime));
  }

  // Beispielbenachrichtigungen
  // private getNotification0(): Notification {
  //   let notification = new Notification();
  //   notification.id = 'abc45774-c6ad-4bca-af84-a42a9bf0e581';
  //   notification.message = 'Nexato GmbH (Nov 13th) Gestriger Umsatz: €1,558.90 Kunden erstellt: 0';
  //   notification.type = 'link';
  //   notification.createdDateTime = '2024-11-14T12:00:00';
  //   notification.settings = {
  //     url: 'https://www.nexato.com',
  //   };
  //   notification.readDateTime = this.getReadDateTime(notification.id);
  //   return notification;
  // }
  
  private getNotification1(): Notification {
    let notification = new Notification();
    notification.id = '72a2c686-0b65-4cde-8a78-073c6f375fc2';
    notification.message = 'Update: Neue Funktionen rund um Aufgaben';
    notification.type = 'link';
    notification.createdDateTime = '2025-04-02T12:00:00';
    notification.settings = {
      url: 'https://app.loopedin.io/nexcore/updates?update=67eabc8a7be0dc002dbfb295',
    };
    notification.readDateTime = this.getReadDateTime(notification.id);
    return notification;
  }

  // updateNotifications(notifications: Notification[]) {
  //   this.notificationsSubject.next(notifications);
  // }

  // checks via local storage if the notification has been read
  getReadDateTime(id: string) {
    return localStorage.getItem(id);
  }

  // mark notification as read
  // currently dummy implementaion where the id of the notification and the read date time is stored in local storage
  markNotificationAsRead(id: string) {
    let notifications = this.allNotificationsSubject.value;
    let notification = notifications.find(notification => notification.id === id);
    notification.readDateTime = new Date().toISOString();
    // store id and read date time in local storage
    localStorage.setItem(id, notification.readDateTime);
    this.allNotificationsSubject.next(notifications);
    this.unreadNotificationsSubject.next(notifications.filter(notification => !notification.readDateTime));
  }

  markNotificationsAsRead(ids: string[]) {
    let notifications = this.allNotificationsSubject.value;
    ids.forEach(id => {
      let notification = notifications.find(notification => notification.id === id);
      notification.readDateTime = new Date().toISOString();
      // store id and read date time in local storage
      localStorage.setItem(id, notification.readDateTime);
    });
    this.allNotificationsSubject.next(notifications);
    this.unreadNotificationsSubject.next(notifications.filter(notification => !notification.readDateTime));
  }
  
}
