import { Component, Input } from '@angular/core';
import { Address } from 'src/app/shared-module/shared/entities/address/address';

@Component({
    selector: 'app-address-task-view',
    template: `
    <nx-default-box 
        class="bg-white border-b border-gray"
        [label]="'Adresse'">
        <ng-template nxTemplate="tag">
              <app-address-indicator
                label
                class="mt-[0.438rem] ml-2"
                size="small"
                [grade]="address?.geocodingResultGrade?.toString()">
              </app-address-indicator>
            </ng-template>
        <div class="flex items-center">
            {{address?.getAddressString() ? address?.getAddressString() : '-'}}
        </div>
    </nx-default-box>
    `,
    styles: [`

  `]
})
export class AddressTaskViewComponent{

    @Input() address: Address;

    constructor() {
    }

}