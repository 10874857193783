import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel, File, FileInput, LoadingService, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { saveAs } from 'file-saver';
import { KeycloakService } from 'keycloak-angular';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { OrderService } from '../../../order-module/shared/services/order/order.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileComponent implements AfterViewInit {

  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad : PdfJsViewerComponent
  private pdfSrcData: Blob | null = null;
  currentHeader: string;

  public uploading = false;
  public ownerId: string;
  public value = '';
  public isFile = false;
  public fileName = '';
  public fileApi = environment.fileApi;
  pdfDialogVisible: boolean = false;
  imageDialogVisible: boolean = false;
  currentImage: string;
  public files: any[];
  menuItems: MenuItem[];

  @Output() reloadFileList = new EventEmitter();

  _file: File;
  @Input() set file(file: File) {
    if(file !== undefined){
      this._file = file;
      this.menuItems = this.getMenuItems(this._file);
    }
  }

    /**
   * indicates, if the context menu of the action button is visible
   */
    public menuVisible = false;
  constructor(
    apollo: Apollo,
    private http: HttpClient,
    protected keycloakAngular: KeycloakService,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    public orderService: OrderService,
    public rolesService: RolesService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
  }

  getMenuItems(file: File) : MenuItem[]{
    let items = [
      {
        label: 'Herunterladen',
        icon: 'pi pi-fw pi-trash', command: () => {
          this.downloadFile(file);
        }
      },
      {
          label: 'Löschen',
          icon: 'pi pi-fw pi-trash', command: () => {
            this.confirmDialog(file);
          }
      }
    ];
    return items;
  }


  public downloadFile(file: any): void {
    const downloadName = file?.fileName;
    const url = environment.fileApi + '/file/'+ file?.fullPath;
    this.http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
      //const blob = new Blob([response]);
      saveAs(response, downloadName);
    });
  }

  getFileInput(files: File[]) : FileInput[] {
    let fileInputs = [];
    for (let val of files) {
      fileInputs.push(new FileInput({'id': val?.id}));
    }
    return fileInputs;
  }

  confirmDialog(file: File): void {
    const message = 'Möchtest Du die Datei wirklich löschen?';
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.loadingService.setLoading(true);
        this.deleteFile(file.id);
      }
    });
  }

  deleteFile(id: string){
    this.orderService.deleteFile(
      id,
      this.reloadFileList.emit()
    );
  }


  getSizeinMb(size: number) {
   return (size /(1024*1024) ).toFixed(2);
  }

  openFile(file: any) {
    // open application/pdf, image/jpg, image/jpeg, image/png
    if ( file?.extenstion === 'jpg' || file?.extension === 'jpeg' || file.extension === 'png') {
      this.currentImage = this.fileApi + '/view/'+ file?.fullPath;
      this.currentHeader = file?.fileName;
      this.imageDialogVisible = true;
      // t5x is a quick fix, because the backend does not store it correctly
    } else if (file?.extension === 'pdf' || file?.extension === 't5x') {
      const url = environment.fileApi + '/file/'+ file?.fullPath;
      this.http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
        this.currentHeader = file?.fileName;
        this.pdfSrcData = response;
        this.pdfDialogVisible = true;
        this.changeDetectorRef.detectChanges();
        
        // Use Promise to ensure proper timing
        Promise.resolve().then(() => {
          if (this.pdfViewerAutoLoad) {
            this.initializePdfViewer(file?.fileName);
          }
        });
      });
    }

  }

  private initializePdfViewer(fileName: string) {
    if (!this.pdfViewerAutoLoad || !this.pdfSrcData) return;
    
    this.pdfViewerAutoLoad.diagnosticLogs = true;
    this.pdfViewerAutoLoad.pdfSrc = this.pdfSrcData;
    this.pdfViewerAutoLoad.downloadFileName = fileName;
    this.pdfViewerAutoLoad.openFile = false;
    
    // Ensure change detection runs after initialization
    this.changeDetectorRef.detectChanges();
    this.pdfViewerAutoLoad.refresh();
  }

  shouldShowButton(file: any): boolean {
    let _menuItems = this.menuItems;
    if(_menuItems !== undefined && _menuItems.length > 0){
      return true;
    }
    return false;
  }

  ngAfterViewInit() {
    // If we have pending PDF data, initialize the viewer
    if (this.pdfSrcData && this.pdfDialogVisible) {
      this.initializePdfViewer(this.currentHeader);
    }
  }

  /**
   * triggers, when the context menu will be showm and sets the menuVisible flag to true
   */
  public onMenuShow() {
    this.menuVisible = true;
  }

  /**
   * triggers, when the context menu will be hidden and sets the menuVisible flag to false
   */
  public onMenuHide() {
    this.menuVisible = false;
  }

}
