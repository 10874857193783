import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';


export const CONTACTS_QUERY = gql`
query contactsQuery(
  $pageNumber: Int,
  $pageSize: Int,
  $sortProperty: String,
  $sortDirection: String,
  $name: String
)
{
  contacts: getContactPaged(
    filter: {
      sortRequest: {
        sortProperties: [$sortProperty],
        sortDirection: $sortDirection
      },
      pagination: {
        number: $pageNumber,
        size: $pageSize,
        sortProperty: $sortProperty,
      sortDirection: $sortDirection},
      name: $name
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
          companyName
          companyNameExtension
          firstName
          lastName
          number
          type
      }
  }
}
`;

export interface ContactsQueryResponse {
  contacts: Page<Contact>;
}
