import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditDialogComponent, EditDialogInstance, EditSelectable, LoadingService, ToastService } from '@nexato/nx-core-module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription, take } from 'rxjs';
import { AssignmentStrategyCandidate } from '../../shared/entities/assignmentStrategyCandidate/assignmentStrategyCandidate';
import { Task } from '../../shared/entities/task/task';
import { TaskAssignmentSuggestion } from '../../shared/entities/taskAssignmentSuggestion/taskAssignmentSuggestion';
import { LocationService } from '../../shared/services/location/location.service';
import { TasksService } from '../../shared/services/tasks/tasks.service';

@Component({
  selector: 'app-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.scss']
})
export class AssignmentDialogComponent
  implements EditDialogInstance, OnDestroy {

  // could be set as an input later, currently only those two are allowed from this component
  private allowedStrategyTypes: string[] = ['LocationTaskAssignmentStrategy', 'UserTaskAssignmentStrategy'];

  // form group for the assignment dialog
  assignmentFormGroup: FormGroup;
  assignmentTypeFormControlSubscription: Subscription

  // current task
  task: Task;

  // available options for the assignment dialog
  public assignemtTypeOptions: EditSelectable[] = [];
  public assignmentSuggestion: TaskAssignmentSuggestion;
  public taskAssignmenStrategyCandidates: AssignmentStrategyCandidate[];
  public locationTaskAssignmentStrategyCandidateOptions: EditSelectable[] = [];
  public userTaskAssignmentStrategyCandidateOptions: EditSelectable[] = [];
  public assigneeIdPlaceholder: string = undefined;


  public assigneeOptions: EditSelectable[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public locationService: LocationService,
    public loadingService: LoadingService,
    public taskService: TasksService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    if (this.config.data) {
      this.task = this.config.data;
      this.taskService.getAssignmentStrategyCandidates(this.config.data?.id, this.allowedStrategyTypes).pipe(
        take(1)
      ).subscribe( taskAssignmenStrategyCandidates => {
        this.taskAssignmenStrategyCandidates = taskAssignmenStrategyCandidates;
        this.assignemtTypeOptions = taskAssignmenStrategyCandidates?.map((candidate) => {
          return {
            id: candidate.type,
            name: candidate.label
          }
        });
        this.assignmentSuggestion = this.task.assignmentSuggestion;
        this.locationTaskAssignmentStrategyCandidateOptions = this.buildTaskAssignmentStrategyCandidateOptions(this.taskAssignmenStrategyCandidates, 'LocationTaskAssignmentStrategy');
        this.userTaskAssignmentStrategyCandidateOptions = this.buildTaskAssignmentStrategyCandidateOptions(this.taskAssignmenStrategyCandidates, 'UserTaskAssignmentStrategy');
        // check, if the fields should be preselected by the suggestion
        if(this.assignmentSuggestion && this.assignmentSuggestion.assignmentType){
          if(this.assignmentSuggestion.assignmentType === 'LocationTaskAssignmentStrategy'){
            this.assigneeOptions = this.locationTaskAssignmentStrategyCandidateOptions;
          }
          if(this.assignmentSuggestion.assignmentType === 'UserTaskAssignmentStrategy'){
            this.assigneeOptions = this.userTaskAssignmentStrategyCandidateOptions;
          }
        }
        // this.cdr.detectChanges();
      });
    }
    this.assignmentFormGroup = new FormGroup({});
    this.assignmentFormGroup.valueChanges.subscribe((value) => {
      console.log(value);
    }
    );

    // // add form controls
    
    // taskId
    this.assignmentFormGroup.addControl('taskId', new FormControl());
    // assignment type
    let assignmentTypeFormControl = new FormControl(undefined , [Validators.required]);
    this.assignmentTypeFormControlSubscription = assignmentTypeFormControl.valueChanges.subscribe((value) => {
      if(value === 'LocationTaskAssignmentStrategy'){
        this.assigneeOptions = this.locationTaskAssignmentStrategyCandidateOptions;
        this.assigneeIdPlaceholder = "Niederlassung auswählen";
        this.assignmentFormGroup.get('assigneeId').enable();
      }
      if(value === 'UserTaskAssignmentStrategy'){
        this.assigneeOptions = this.userTaskAssignmentStrategyCandidateOptions;
        this.assigneeIdPlaceholder = "Nutzer auswählen";
        this.assignmentFormGroup.get('assigneeId').enable();
      }
      // this.cdr.detectChanges();
    });
    this.assignmentFormGroup.addControl('assignmentType', assignmentTypeFormControl);
    // assignee id
    let assigneeIdFormControl = new FormControl(undefined, [Validators.required]);
    assigneeIdFormControl.disable();
    this.assignmentFormGroup.addControl('assigneeId', assigneeIdFormControl);
    // due date time period
    let dueDateTimePeriodFormControl = new FormControl(undefined, [Validators.required]);
    this.assignmentFormGroup.addControl('dueDateTimePeriod', dueDateTimePeriodFormControl);

    // task id must always be set
    this.assignmentFormGroup.get('taskId').setValue(this.task.id);
    
    // set values if we have a suggestion
    if(this.task?.assignmentSuggestion){
      this.assignmentFormGroup.get('assignmentType').setValue(this.task?.assignmentSuggestion.assignmentType);
      this.assignmentFormGroup.get('assigneeId').setValue(this.task?.assignmentSuggestion.assigneeId);
      this.assignmentFormGroup.get('dueDateTimePeriod').setValue(this.task?.assignmentSuggestion.dueDateTimePeriod);
    } else if(this.task?.dueDateTimePeriod){
      // or prefill at least the due date time period from the task directly
      this.assignmentFormGroup.get('dueDateTimePeriod').setValue(this.task?.dueDateTimePeriod);
    }
    
    // this.assignmentFormGroup.addControl('dueDateTimePeriod', new FormControl(this.task?.assignmentSuggestion?.dueDateTimePeriod, [Validators.required]));

    // if the value were set during initialization we must mark the form as dirty
    if(this.assignmentFormGroup.valid){
      this.assignmentFormGroup.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.assignmentTypeFormControlSubscription?.unsubscribe();
  }

  isAssigneeIdDisabled(): boolean {
    return this.assignmentFormGroup.get('assignmentType').value === undefined;
  }

  buildTaskAssignmentStrategyCandidateOptions(assignmentStrategyCandidates: AssignmentStrategyCandidate[], type: string): EditSelectable[]{
    // get the suitable assignment candidate for the selected assignment type
    let assignmentCandidate = assignmentStrategyCandidates?.find((candidate) => candidate.type === type);
    if(!assignmentCandidate){
      return [];
    }
    let assigneIdOptions = assignmentCandidate?.settings[0];
    if(!assigneIdOptions){
      return [];
    }
    return assigneIdOptions?.settings?.options?.map((option: any) => {
      return {
        id: option.id,
        name: option.label
      }
    });
  }



  // getAssignmentTypeOptions(taskAssignmenStrategyCandidates: AssignmentStrategyCandidate[]): any[] {
  //   return taskAssignmenStrategyCandidates.map((candidate) => {
  //     return {
  //       id: candidate.type,
  //       name: candidate.label
  //     }
  //   });
  // }

  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    this.loadingService.setLoading(true);
    this.taskService.assignTask(this.assignmentFormGroup.value, () => {
      this.toastService.addToastWithMessage('Aufgabe zugewiesen.');
      this.ref.close(true);
      // we´re faking here the loading time... todo: remove this
      // and take care, that the refetch discards the spinner
      setTimeout(() => {
        this.loadingService.setLoading(false);
      }, 100);
    })  
  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

  getEditForm(): FormGroup {
    return this.assignmentFormGroup;
  }

}
