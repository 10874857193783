import { Component, Input } from '@angular/core';
import { Resource } from '../../shared/entities/resource/resource';

@Component({
    selector: 'app-resources-task-view',
    template: `
    <nx-default-box 
        class="bg-white border-b border-gray"
        [label]="'Resourcen'">
        <div class="flex items-center">
          <ng-container *ngIf="resources && resources.length > 0; else noResources">
            <ng-container *ngFor="let resource of resources; let last = last">
              <a [routerLink]="['/resources/resource/', resource?.id ]" class="jump ">
              {{resource?.text}}
              <i [className]="'icon icon-jump-to'"></i>
              </a>
              <ng-container *ngIf="!last">,&nbsp;</ng-container>
            </ng-container>
          </ng-container>
          <ng-template #noResources>
            -
          </ng-template>
        </div>
    </nx-default-box>
    `,
    styles: [`

  `]
})
export class ResourcesTaskViewComponent{

    @Input() resources: Resource[];

    constructor() {
    }

}