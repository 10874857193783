import { Injectable } from "@angular/core";
import { CommandInput, CommandService, CommandTemplate, EditDialogService, Flow, HasCommands } from "@nexato/nx-core-module";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { SplitTaskDialogComponent } from "src/app/shared-module/components/split-task-dialog/split-task-dialog.component";

@Injectable({
    providedIn: 'root'
  })
export class SplitCompositTaskCommandCustomFlow implements Flow {

  dialogRef: DynamicDialogRef | undefined;

  constructor(
    private dialogService: EditDialogService
  ) { }

  executeFlow(commandService: CommandService, hasCommands: HasCommands,
    commandTemplate: CommandTemplate, commandInput: CommandInput,
    completionCallback: () => void) {

      this.dialogRef = this.dialogService.open(SplitTaskDialogComponent, {
        width: "500px",
        header: commandTemplate?.settings?.label,
        data: {
          commandTemplate: commandTemplate,
        }
      });
      this.dialogRef.onClose.subscribe((result) => {
        // result is true or false, indiciating if it should refetch
        console.log('result', result);
        if(result){
          commandInput.addParameter("taskIds", result.taskIds);
          commandService.sendCommand(commandInput, () => {
            completionCallback();
          })
        }
      });

    
  }
  
}