import { Component, Input } from '@angular/core';
import { Order } from 'src/app/order-module/shared/entities/order/order';

@Component({
    selector: 'app-order-task-view',
    template: `
    <nx-default-box
        *ngIf="order?.number" 
        class="bg-white border-b border-gray"
        [label]="'Auftragsnummer'">
        <div class="flex items-center">
            <a [routerLink]="['/orders/order/', order?.id ]" class="jump">
                {{order?.number}}
                <i [className]="'icon icon-jump-to'"></i>
            </a>
        </div>
    </nx-default-box>
    `,
    styles: [`
  `]
})
export class OrderTaskViewComponent{

    @Input() order: Order;

    constructor() {
    }

}