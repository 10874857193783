import { Component, Input } from '@angular/core';
import { Task } from '../../shared/entities/task/task';

@Component({
    selector: 'app-completed-by-task-view',
    template: `
    <nx-default-box
        *ngIf="task?.completedBy" 
        class="bg-white border-b border-gray"
        [label]="'Erledigt'">
        <div class="flex items-center">
            {{task?.completedBy?.firstName}} {{task?.completedBy?.lastName}} / {{task?.completedDateTime | date: 'dd.MM.yyyy HH:mm'}}
        </div>
    </nx-default-box>
    `,
    styles: [`

  `]
})
export class CompletedByTaskViewComponent{

    @Input() task: Task;

    constructor() {
    }

}