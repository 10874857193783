<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
  <div class="basis-1/3 flex flex-row items-center ml-3 font-medium">
    Abgeschlossene Aufgaben
  </div>
  <div class="basis-1/3 flex flex-row justify-center items-center">
  </div>
  <div class="basis-1/3 flex flex-row justify-end items-center">
    <input
      pInputText
      type="text"
      class="p-inputtext p-component p-element mr-3"
      (input)="onSearchInput($event.target?.['value'])"
      placeholder="Aufgabe suchen" />
  </div>
</div>
<div class="flex flex-row px-[0.75rem] py-[0.4rem] leading-[1rem] border-b bg-white">
  <div class="basis-2/3 flex flex-row gap-2">
        <nx-filter-multiSelect
          [className]="'table-filter'"
          [filter]="false"
          [showToggleAll]="false"
          [showHeader]="false"
          [appendTo]="'body'"
          [(ngModel)] = "typesModel"
          [options]="typeOptions"
          [showClear]="true"
          placeholder="Typ"
          (onClear)="filter()"
          (onChange)="filter()"
          optionLabel="label">
            <ng-template let-option pTemplate="item">
              <div class="inline-block vertical-align-middle">
                <span class="ml-1 mt-1">{{ option.name }}</span>
              </div>
            </ng-template>
        </nx-filter-multiSelect>
  </div>
  <div class="basis-1/3 flex flex-row justify-end">
    <p-dropdown
      [styleClass]="'sort'"
      appendTo="body"
      [(ngModel)] = "sortModel"
      [options]="sortOptions"
      optionLabel="label"
      [showClear]="false"
      (onChange)="refetchloadTaskList()">
    </p-dropdown>
  </div>
</div>
<div class="grid grid-cols-[1fr,auto,auto]">
  <div
    *ngFor="let task of dataSource?.data; let isLast = last"
    class="contents">
      <div 
      task-row-wide
      [showOrderNote]="true"
      [columns]="displayedColumns"
      class="child flex flex-row task items-center bg-white basis-full relative border-b border-r border-gray"
      [ngClass]="{'border-b' : !isLast}"
      [task]=task>
    </div>
    <div
      class="child border-b border-gray bg-white px-2 py-1 text-base flex justify-end items-center max-w-[15.625rem] truncate"
      [ngClass]="{'border-b' : !isLast}">
     <span>{{task?.completedDateTime | date:'dd.MM.yyyy HH:mm'}}</span>
  </div>
    <div
      class="child border-b border-gray bg-white px-2 py-1 text-base flex justify-end items-center max-w-[15.625rem] truncate"
      [ngClass]="{'border-b' : !isLast}">
      <span>{{task?.completedBy?.firstName}} {{task?.completedBy?.lastName}}</span>
    </div>
  </div>
</div>
<nx-empty-box
  *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
  [label]="'Keine Aufgaben gefunden.'">
</nx-empty-box>
<p-paginator 
  (onPageChange)="onPageChange($event)" 
  [first]="pageModel?.pageNumber * pageModel?.pageSize"
  [rows]="pageModel?.pageSize"
  [totalRecords]="dataSource?.totalElements"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[15, 20, 50]">
</p-paginator>



