import { gql } from 'apollo-angular';
import { Tour } from '../../shared/entities/tour';


export const TOURS_DETAIL_STANDARD_QUERY = gql`
query ToursDetailPageStandardQuery($tourId: UUID)
{
  tour: getTour(id: $tourId) {
    id
    name
    scheduledDepartureDateTime
    scheduledArrivalDateTime
    actualDepartureDateTime
    actualArrivalDateTime
    effectiveDepartureDateTime
    effectiveArrivalDateTime
    dateTimeReference
    state
    note
    departureAddress{
      id
      country
      city
      houseNumber
      postalCode
      street
      lat
      lng
      geocodingResultGrade
    }
    arrivalAddress{
      id
      country
      city
      houseNumber
      postalCode
      street
      lat
      lng
      geocodingResultGrade
    }
    tasks {
      id
      type
      color
      description
      title
      commands
      dueDateTimePeriod {
        modifier
        dateTime
        fromDateTime
        toDateTime
        precision
      }
      state
      description
      order{
        id
        number
        type
        note
        customer {
          type
          companyName
          companyNameExtension
          firstName
          lastName
        }
        comments {
          id
        }
        commentCount
      }
      address{
        id
        country
        city
        postalCode
        street
        lat
        lng
        geocodingResultGrade
      }
      linkedContacts: linkedEntities(entityType: "Contact") {
        linkType
        entity {
          ... on Contact {
              id
              type
              number
              companyName
              companyNameExtension
              firstName
              lastName
          }
        }
      }
    }
    resource {
      id
      name
    }
    driver {
      id
      firstName
      lastName
    }
    driverCandidates{
      id
      firstName
      lastName
    }
    isDeletable
    possibleStartAddresses {
      id
      street
      houseNumber
      postalCode
      city
      geocodingResultGrade
    }
    possibleEndAddresses {
      id
      street
      houseNumber
      postalCode
      city
      geocodingResultGrade
    }
    departureAddress {
      id
    }
    arrivalAddress {
      id
    }
    transportCandidates {
      id
      name
    }
    location {
      name
    }
    dateTimeReference
  }
}
`;

export interface TourDetailQueryResponse {
  tour: Tour;
  loading: boolean;
}


