import { Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { EditDialogService } from '@nexato/nx-core-module';
import { Apollo, QueryRef } from 'apollo-angular';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { Address, AddressInput } from '../../shared/entities/address/address';
import * as fromGraphQl from './edit-linked-ressources.graphql';

type OptionLabelType = string | ((option: any) => string);

@Component({
    selector: 'nx-edit-linked-resources-input',
    template: `
    <div class="nx-edit-linked-resources-input">
        <label for="text">{{label}}<ng-container *ngIf=isRequired()>*</ng-container></label>
        <p-autoComplete 
            [(ngModel)]="selectedItems" 
            [emptyMessage]="emptyMessage"
            [showEmptyMessage]="emptyMessage != undefined"
            [placeholder]="placeholder"
            [suggestions]="suggestions"
            (onSelect)=onSelect();
            (onUnselect)=onUnselect();
            (completeMethod)="search($event)" 
            [optionLabel]="optionLabel"
            [multiple]="true" />
    </div>
    `,
    styles: [`
  `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditLinkedResourcesInputComponent),
            multi: true
        }
    ]
})
export class EditLinkedResourcesInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

    ngControl: NgControl;
    isDisabled: boolean;
    dialogRef: DynamicDialogRef | undefined;

    optionLabel = this.getOptionLabel.bind(this) as unknown as string;

    suggestions: Resource[] = [];
    selectedItems: any[] = [];

    items: any[] = [];

    // If the address was cleared by the user and the address had already an id, we store the id here, 
    // so that we cann add it to the addressInput when the user selects a new address. So we make sure
    // that the addressInput has the same id as the address that was cleared.
    clearedAddressId: string;
    address: Address;
    addressInput: AddressInput;

    @Input() label: string;
    @Input() placeholder: string;
    @Input() emptyMessage: string;

    private onChange: (value: any) => void;
    private onTouched: () => void;

    private pageNumber: number = 0;
    private pageSize: number = 10;
    private sortProperty: string = 'number';
    private sortDirection: string = 'asc';
    private text: string;

    autocompleteQuery: QueryRef<fromGraphQl.ResourcesQueryResponse>;
    public autocompleteQuerySubscription: Subscription;

    constructor(
        public dialogService: EditDialogService,
        private apollo: Apollo,
        private _injector: Injector) {
    }

    ngOnInit() {
        this.ngControl = this._injector.get(NgControl);
        this.ngControl.valueAccessor = this;
    }

    ngOnDestroy(): void {
    }

    writeValue(resources: Resource[]): void {
        this.selectedItems = resources;
        // TODO -> hier bekommen wir halt nur die "links" und müssten die eigentlich auf ressourcen mappen
        // diese müssten wir über die ids holen..
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    isRequired(): boolean {
        return this.ngControl?.control?.hasValidator(Validators.required);
    }

    search(event: any) {
        this.text = event.query;
        if(!this.autocompleteQuery){
            this.autocompleteQuery = this.apollo.watchQuery<fromGraphQl.ResourcesQueryResponse>({
                fetchPolicy: 'network-only',
                query: fromGraphQl.RESOURCES_QUERY,
                variables: this.buildVariables()
                });
            this.autocompleteQuerySubscription = this.autocompleteQuery.valueChanges.subscribe(
                {
                    next: (data) => {
                    if (data.networkStatus === 7) {
                        let resources = Resource.createResources(data.data.resources.content);
                        // filter out already selected items
                        this.suggestions = resources.filter((resource) => !this.selectedItems?.find((selectedItem) => selectedItem.id === resource.id));
                    }
                    },
                    error: (error) => {
                        this.autocompleteQuery = undefined;
                        this.autocompleteQuerySubscription?.unsubscribe();
                    }
                }
            );
        } else {
            this.autocompleteQuery.refetch(this.buildVariables());
            this.autocompleteQuery.refetch();
        }
    }

    onSelect() {
        if (this.onChange) {
            this.onChange(this.selectedItems);
        }
    }

    onUnselect() {
        if (this.onChange) {
            this.onChange(this.selectedItems);
        }
    }

    buildVariables(): any {
        return {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortProperty: this.sortProperty,
            sortDirection: this.sortDirection,
            // fix for the case when the user tries to search for the same term after he selected an element
            // reason: search gehts called with the same variable and apollo does not resend the query in this case
            timeStamp: new Date().toISOString(),
            text: this.text
          }
    }

    getOptionLabel(option: any): string {
        return `${option.number} / ${option.name}`;
      }

}