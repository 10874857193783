import { Injectable } from "@angular/core";
import { CommandInput, CommandService, CommandTemplate, EditDialogService, Flow, HasCommands } from "@nexato/nx-core-module";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { TaskDialogComponent } from "../../components/task-dialog/task-dialog.component";

@Injectable({
    providedIn: 'root'
  })
export class UpdateDefaultTaskCommandCustomFlow implements Flow {

  dialogRef: DynamicDialogRef | undefined;

  constructor(
    private dialogService: EditDialogService
  ) { }

  executeFlow(commandService: CommandService, hasCommands: HasCommands,
    commandTemplate: CommandTemplate, commandInput: CommandInput,
    completionCallback: () => void) {
      let taskId = commandInput?.parameters?.commandProviderId;
      this.dialogRef = this.dialogService.open(TaskDialogComponent, {
        header: commandTemplate?.settings?.label,
        width: '760px',
        data: taskId
      });
      this.dialogRef.onClose.subscribe((result) => {
        // result is true or false, indiciating if it should refetch
        if(result){
          console.log('result', result);
          commandInput.parameters['taskInput'] = result;
          commandService.sendCommand(commandInput, () => {
            completionCallback();
          })
        }
      });
  }
  
}