import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-header-task-view',
    template: `
<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
    <div class="basis-1/2 flex flex-row items-center ml-3 font-medium noWrap h-12">
        {{label}}
    </div>
</div>
    `,
    styles: [`

  `]
})
export class HeaderTaskViewComponent{

    @Input() label: string;

    constructor() {
    }

}