import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FilterDateSelectValue } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { debounceTime, Subject, take } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { AbstractAssignmentList, PageModel, SortModel } from '../../components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { LocationService } from '../../shared/services/location/location.service';
import { MyTasksListDataSource } from './myTasksListDataSource';

@Component({
  selector: 'my-tasks-list',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.scss']
})
export class MyTasksComponent extends AbstractAssignmentList {

  dataSource: any;
  public selectedTask: Task;

  // text
  @ViewChild('globalSearch') globalSearchInput!: ElementRef;
  private textSubject = new Subject<string>();
  public textModel: string;
  public defaultTextModel: string = undefined;

  // types
  public typesModel: any[] = [];
  public typeOptions = [
   { id: 'nexcore_rental_resourceAssignmenmt_review', name: 'Prüfen', label: 'Prüfen' },
   { id: 'nexcore_rental_resourceAssignmenmt_refuel', name: 'Nachtanken', label: 'Nachtanken' },
   { id: 'nexcore_default_task', name: 'Aufgabe', label: 'Aufgabe' }
  ];
  // load all types by default
  // defaultTypesModel: what the user sees (nothing selected)
  public defaultTypesModel = [];
  // emptyTypesModel: what is actually send to the server: only review tasks
  public emptyTypesModel = [
    { id: 'nexcore_rental_resourceAssignmenmt_review' },
    { id: 'nexcore_rental_resourceAssignmenmt_refuel' },
    { id: 'nexcore_default_task' }
  ]

  // locations - options will be generated as a result of the locationService.getLocations() call
  public locationsModel: any[] = [];
  public locationsOptions = [];
  public defaultLocationsModel = undefined; // load all locations by default

  // dueDate
  public dueDateModel: FilterDateSelectValue = undefined;
  public defaultDueDateModel = undefined; // load all locations by default

  // includeOverDue
  public includeOverdueModel: undefined | boolean;
  public defaultIncludeOverdueModel = undefined;
  public includeOverdueOptions = [
    { id: true, name: 'Ja', label: 'Ja' },
    { id: false, name: 'Nein', label: 'Nein' }
  ];
  public emptyIncludeOverdue = false;

  // sorting
  public sortModel: SortModel;
  public sortOptions : SortModel[] = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Auftragsnummer aufsteigend', fieldName: 'order.number', direction: "asc" },
    { label: 'Auftragsnummer absteigend', fieldName: 'order.number', direction: "desc"},
    { label: 'Fälligkeit aufsteigend', fieldName: 'dueDateTimePeriod', direction: "asc" },
    { label: 'Fälligkeit absteigend', fieldName: 'dueDateTimePeriod', direction: "desc"},
    { label: 'Kunde aufsteigend', fieldName: 'customer', direction: "asc" },
    { label: 'Kunde absteigend', fieldName: 'customer', direction: "desc"}
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };

  //fix 
  public assignedToCurrentUser = true;

  constructor(
    private apollo: Apollo,
    private changeDetectorRef: ChangeDetectorRef,
    private locationService: LocationService,
  ) {
    super('MyTasksComponent.myTasksList', 'local' )
    this.locationService.getLocations().pipe(
      take(1)
    ).subscribe(locations => {
      this.locationsOptions = locations;
    });
    // load the state
    let state = this.loadState();
    // sortModel
    let stateSortModel = state?.sortModel;
    let matchedSortModel = this.sortOptions.find(option => option.fieldName === stateSortModel?.fieldName && option.direction === stateSortModel?.direction);
    this.sortModel = matchedSortModel ? matchedSortModel : this.defaultSortModel;
    // types model
    let stateTypesModel = state?.filter?.typesModel;
    this.typesModel = stateTypesModel ? stateTypesModel : this.defaultTypesModel;
    // locations model
    let stateLocationsModel = state?.filter?.locationsModel;
    this.locationsModel = stateLocationsModel ? stateLocationsModel : this.defaultLocationsModel;
    // dueDate model
    let stateDueDateModel = state?.filter?.dueDateModel;
    this.dueDateModel = stateDueDateModel ? stateDueDateModel : this.defaultDueDateModel;
    this.pageModel = this.defaultPageModel;
    this.textSubject.pipe(
      debounceTime(500)
    ).subscribe(text => {
      this.textModel = text;
      this.filter();
    });
    this.dataSource = new MyTasksListDataSource(this.apollo, this.changeDetectorRef, this.dataLoadedCallback.bind(this));
    this.loadTasks();
  }

  loadTasks(){
    this.dataSource?.loadTasks(this.buildVariables());
  }

  reload(): void {
    this.refetchTasks(true);
  }

  refetchTasks(force = false): void {
    this.storeState({
      // pageModel: this.pageModel,
      sortModel: this.sortModel,
      filter: {
        typesModel: this.typesModel,
        locationsModel: this.locationsModel,
        dueDateModel: this.dueDateModel,
      }
    });
    this.dataSource.refetchQuery(this.buildVariables(), force);
  }

  buildVariables(){
    console.log("types model", this.typesModel);
    return {
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      locationIds: this.locationsModel,
      fromDueDateTime: this.dueDateModel?.fromDate ? this.dueDateModel?.fromDate : undefined,
      toDueDateTime: this.dueDateModel?.toDate ? this.dueDateModel?.toDate : undefined,
      includeOverDue: this.dueDateModel?.includeOverDue == undefined || this.dueDateModel?.includeOverDue === null ? this.emptyIncludeOverdue : this.dueDateModel?.includeOverDue,
      types: this.typesModel == undefined || this.typesModel.length == 0 ?  this.emptyTypesModel?.map(type => type.id) : this.typesModel,
      assignedToCurrentUser: this.assignedToCurrentUser,
      text: this.textModel
    }
  }

  filter(){
    this.refetchTasks();
  }

  sort() {
    this.refetchTasks();
  }

  onSearchInput(text: string) {
    this.textSubject.next(text);
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchTasks();
  }

  isSelected(task: Task): boolean {
    if(this.selectedTask?.id === task?.id){
      return true;
    }
    return false;
  }

  selectTask(task: Task) {
    if(this.selectedTask === task){
      this.selectedTask = undefined;
      return;
    } else {
      this.selectedTask = task;
    }
  }

  dataLoadedCallback(tasks: Task[]) {
    // no task is selected, set the first
    if(!this.selectedTask && tasks?.length > 0){
      this.selectedTask = tasks[0];
    }
    // if the selected task is not in the list anymore, select the first task
    if(this.selectedTask && !tasks.find(task => task.id === this.selectedTask.id)){
      this.selectedTask = tasks[0];
    }
  }

  getDataSource() {
    return this.dataSource;
  }
  
}
