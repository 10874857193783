

  <div class="top flex flex-col px-5 py-[0.313rem] bg-white border-b border-gray min-w-[485px]">
    <div class="flex flex-row">
      <div
        class="flex flex-col justify-center">
        <i class="flex text-[2rem] pr-2 cursor-pointer"  (click)="openFile(_file)"
          [ngClass]="'icon-file-attachment'"></i>
      </div>
      <div class="grow">
        <div class="flex flex-row">
          <!-- label -->
          <div (click)="openFile(_file)" class="text-base font-medium leading-3r cursor-pointer">
            {{_file?.fileName}}
          </div>
        </div>
          <div class="text-base text-[#848488] leading-3 ng-star-inserted">
            {{_file?.uploadedDateTime | date:'dd.MM.yyyy HH:mm'}} Uhr - {{getSizeinMb(_file?.size)}} MB
          </div>
      </div>

      <div
      *ngIf="shouldShowButton(_file)"
      class="flex flex-col justify-center ml-2">
      <p-menu
        #menu
        (onShow)="onMenuShow()"
        (onHide)="onMenuHide()"
        [model]="menuItems"
        [popup]="true"
        [appendTo]="'body'"></p-menu>
      <nx-default-button
        [ngClass]="{
          'nx-focus': menuVisible
        }"
        (click)="menu.toggle($event)"
        [icon]="'icon-options'">
      </nx-default-button>
    </div>
  </div>

  <p-dialog
  [header]="currentHeader"
  [(visible)]="pdfDialogVisible"
  [style]="{ width: '800px', height: '80%' }"
  [dismissableMask]="true"
  [modal]="true"
  appendTo="body"
  styleClass="rounded-md p-[10px] bg-white"
  contentStyleClass="noScrollbar pt-[10px]">
  <div style="width: 100%; height: 100%">
    <ng2-pdfjs-viewer 
      #pdfViewerAutoLoad 
      locale="de-DE"
      [viewerId]="'unique-id'"
      [externalWindow]="false">
    </ng2-pdfjs-viewer>
  </div>
</p-dialog>

<p-dialog
  [header]="currentHeader"
  [(visible)]="imageDialogVisible"
  [style]="{ width: '800px', height: '80%' }"
  [dismissableMask]="true"
  [modal]="true"
  appendTo="body"
  styleClass="rounded-md p-[10px] bg-white"
  contentStyleClass="noScrollbar pt-[10px]">
  <div style="width: 100%; height: 100%" class="flex justify-center items-center">
    <img [src]="currentImage">
  </div>
</p-dialog>



