<app-header-task-view
    [label]="getHeadline()">
</app-header-task-view>

<app-completed-by-task-view
    *ngIf="task?.state === 'COMPLETED'"
    [task]="task">
</app-completed-by-task-view>

<app-assignment-task-view
    *ngIf="task?.state !== 'COMPLETED'"
    [task]="task">
</app-assignment-task-view>

<app-contact-task-view
    [label]="'Kontakt (Nr.)'"
    [contact]="task?.order?.customer">
</app-contact-task-view>

<app-order-task-view
    [order]="task?.order">
</app-order-task-view>

<app-location-task-view
    [location]="task?.order?.location">
</app-location-task-view>

<app-rental-resource-assignment-refuel-form 
    (complete)="completeTask($event)"
    [task]="task">
</app-rental-resource-assignment-refuel-form>

<!-- <app-complete-button-task-view
    [canComplete]="false"
    [task]="task">
</app-complete-button-task-view> -->