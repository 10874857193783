<ng-container [ngSwitch]="assignment?.assignmentType">
  
  <!-- TourTaskAssignmentStrategy -->
  <app-tour-task-assignment-strategy-view
    *ngSwitchCase="'TourTaskAssignmentStrategy'"
    [assignment]="assignment">
  </app-tour-task-assignment-strategy-view>

  <!-- LocationTaskAssignmentStrategyViewComponent -->
  <app-location-task-assignment-strategy-view
    *ngSwitchCase="'LocationTaskAssignmentStrategy'"
    [assignment]="assignment">
  </app-location-task-assignment-strategy-view>

  <!-- UserTaskAssignmentStrategyViewComponent -->
  <app-user-task-assignment-strategy-view
    *ngSwitchCase="'UserTaskAssignmentStrategy'"
    [assignment]="assignment">
  </app-user-task-assignment-strategy-view>

</ng-container>
