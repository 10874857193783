<app-header-task-view
    [label]="getHeadline()">
</app-header-task-view>

<app-completed-by-task-view
    *ngIf="_task?.state === 'COMPLETED'"
    [task]="_task">
</app-completed-by-task-view>

<app-assignment-task-view
    *ngIf="_task?.state !== 'COMPLETED'"
    [task]="_task">
</app-assignment-task-view>

<app-contact-task-view
    [label]="'Kontakt (Nr.)'"
    [contact]="_task?.order?.customer">
</app-contact-task-view>

<app-order-task-view
    [order]="_task?.order">
</app-order-task-view>

<app-location-task-view
    [location]="_task?.order?.location">
</app-location-task-view>

<ng-container *ngFor="let resourceAssignment of resourceAssignents">
    <app-order-rent-resourceAssignment-protocols-view
        [showRefuelProtocol]="false"
        [showReviewProtocol]="false"
        [resourceAssignment]="resourceAssignment">
    </app-order-rent-resourceAssignment-protocols-view>
</ng-container>

<app-complete-button-task-view
    [task]="task"
    [notCompletedLabel]="'Aufgabe kann nicht im Web erledigt werden'"
    [canComplete]="false"
></app-complete-button-task-view>