import { CdkDragRelease, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

import { GrowthBookService, Reloadable, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { debounceTime, Subject, take } from 'rxjs';
import { AbstractAssignmentList, PageModel, SortModel } from 'src/app/rent-module/components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import { LocationService } from 'src/app/rent-module/shared/services/location/location.service';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { NxDragDrop } from '../../shared/dragDrop/evetns';
import { Tour } from '../../shared/entities/tour';
import { DragDropabble, TourPlannerService } from '../../shared/services/tour/tour-planner.service';
import { TourService } from '../../shared/services/tour/tour.service';
import { PrimeToursPlannerTaskListDataSource } from './primeToursPlannerTaskListDataSource';

@Component({
  selector: "app-tours-planner-tasks-list",
  templateUrl: "./tours-planner-tasks-list.component.html",
  styleUrls: ["./tours-planner-tasks-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToursPlannerTasksListComponent extends AbstractAssignmentList implements AfterViewInit, OnDestroy, OnChanges, DragDropabble, Reloadable  {

  // generate a random id for the component
  // this is used to store the state of the component
  // in the local storage
  private initialLoad = false;

  public reloadable: Reloadable = this;

  // inputs
  @Input() date: Date;

  dataSource: any;
  loading = false;

  // default and current values for sorting and pagination

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };

  // sorting
  public sortModel: SortModel;
  public sortOptions : SortModel[] = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Auftragsnummer aufsteigend', fieldName: 'order.number', direction: "asc" },
    { label: 'Auftragsnummer absteigend', fieldName: 'order.number', direction: "desc"},
    { label: 'Fälligkeit aufsteigend', fieldName: 'dueDateTimePeriod', direction: "asc" },
    { label: 'Fälligkeit absteigend', fieldName: 'dueDateTimePeriod', direction: "desc"},
    { label: 'Kunde aufsteigend', fieldName: 'customer', direction: "asc" },
    { label: 'Kunde absteigend', fieldName: 'customer', direction: "desc"},
    { label: 'PLZ aufsteigend', fieldName: 'address.postalCode', direction: "asc" },
    { label: 'PLZ absteigend', fieldName: 'address.postalCode', direction: "desc"},
    { label: 'Stadt aufsteigend', fieldName: 'address.city', direction: "asc" },
    { label: 'Stadt absteigend', fieldName: 'address.city', direction: "desc"},
    { label: 'Niederlassung aufsteigend', fieldName: 'order.location.name', direction: "asc" },
    { label: 'Niederlassung absteigend', fieldName: 'order.location.name', direction: "desc"},
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  // models for multiselect
  public previewModel: number;

  // types
  public typesModel: any[] = [];
  public typeOptions = [
   { id: 'nexcore_rental_resourceAssignmenmt_out', name: 'Übergabe', label: 'Übergabe' },
   { id: 'nexcore_rental_resourceAssignmenmt_in', name: 'Rücknahme', label: 'Rücknahme' },
   { id: 'nexcore_default_task', name: 'Aufgabe', label: 'Aufgabe' }
  ];
  public defaultTypesModel = undefined; // load all types by default
  
  // locations - options will be generated as a result of the locationService.getLocations() call
  public locationsModel: any[] = [];
  public locationsOptions = [];
  public defaultLocationsModel = undefined; // load all locations by default

  // text
  @ViewChild('globalSearch') globalSearchInput!: ElementRef;
  private textSubject = new Subject<string>();
  public textModel: string;
  public defaultTextModel: string = undefined;

  // fixed, not selectable
  public statesModel = ['NEW', 'UNASSIGNED'];

  @HostListener('window:resize')
  onResize() {
    this.currentContainerHeight = this.elementRef.nativeElement.offsetHeight;
    this.changeDetectorRef.detectChanges();
  } 
  private currentContainerHeight: number;
  private currentScrollHeight: number;

  constructor(
    public tasksService: TasksService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    public rolesService: RolesService,
    private apollo: Apollo,
    private locationService: LocationService,
    public growthBookService: GrowthBookService,
    public tourPlannerService: TourPlannerService,
    public tourService: TourService,
    
    private elementRef: ElementRef
  ) {
    // new
    super('ToursPlannerTasksListComponent.unassignedTasksTable', 'local' )
    this.locationService.getLocations().pipe(
      take(1)
    ).subscribe(locations => {
      this.locationsOptions = locations;
      let state = this.loadState();
    });
    let state = this.loadState();
    this.pageModel = state?.pageModel ? state.pageModel : this.defaultPageModel;
    // we´re filtering here for the options, so that we do not get any problems, if we change them in future
    // we need to find a suitable option for the state
    let stateSortModel = state?.sortModel;
    let matchedSortModel = this.sortOptions.find(option => option.fieldName === stateSortModel?.fieldName && option.direction === stateSortModel?.direction);
    this.sortModel = matchedSortModel ? matchedSortModel : this.defaultSortModel;
    // we need to find a suitable option for the state
    let stateTypesModel = state?.filter?.typesModel;
    const typeModelIds = new Set(stateTypesModel?.map(option => option.id));
    const matchedTypesModel = this.typeOptions?.filter(option => typeModelIds?.has(option?.id));
    this.typesModel = matchedTypesModel ? matchedTypesModel : this.defaultTypesModel;
    // we need to find a suitable option for the state
    let stateLocationsModel = state?.filter?.locationsModel;
    this.locationsModel = stateLocationsModel ? stateLocationsModel : this.defaultLocationsModel;
    this.previewModel = state?.filter?.previewModel ? state.filter.previewModel : undefined;
    this.textModel = state?.filter?.textModel ? state.filter.textModel : this.defaultTextModel;
    // missing location
    this.textSubject.pipe(
      debounceTime(500)
    ).subscribe(text => {
      this.textModel = text;
      this.filter();
    });
    this.dataSource = new PrimeToursPlannerTaskListDataSource(this.apollo, this.changeDetectorRef);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.setDisplayedColumns();
    // new
    this.tourPlannerService.setToursPlannerTasksListComponent(this);
   }

  ngOnChanges(changes: SimpleChanges): void {
    // we check here for date and location tho initiate the first load
    // both values must be set, even locations gets an empty array after
    // initilaztion of the input
    
    if (this.date) {
      if(!this.initialLoad){
      this.loadTasks();
      this.initialLoad = true;
      } else {
        this.refetchTasks();
      }
    }
  }

  onSearchInput(text: string) {
    this.textSubject.next(text);
  }

  filter(){
    this.refetchTasks();
  }

  loadTasks(){
    this.dataSource?.loadTasks({
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      fromDateTime: new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString(),
      toDateTime: new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      locationIds: this.locationsModel?.map(location => location.id),
      types: this.typesModel?.map(type => type.id),
      text: this.textModel,
      taskAssignmentInput:  {
        "assignmentType": "TourTaskAssignmentStrategy",
        "fromDateTime": new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString(),
        "toDateTime": new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString()
      },
      preview: this.previewModel
    });
  }

  reload() {
    this.refetchTasks(true);
  }

  refetchTasks(force = false): void {
    // store new state, then refetch
    this.storeState({
      pageModel: this.pageModel,
      sortModel: this.sortModel,
      filter: {
        typesModel: this.typesModel,
        locationsModel: this.locationsModel,
        previewModel: this.previewModel,
        textModel: this.textModel
      }
    });
    this.dataSource.refetchQuery({
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      fromDateTime: new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString(),
      toDateTime: new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      locationIds: this.locationsModel?.map(location => location.id),
      types: this.typesModel?.map(type => type.id),
      text: this.textModel,
      taskAssignmentInput:  {
        "assignmentType": "TourTaskAssignmentStrategy",
        "fromDateTime": new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString(),
        "toDateTime": new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString()
      },
      preview: this.previewModel
    }, force);
  }

  sort() {
    this.refetchTasks();
  }
  
  getDataSource() {
    return this.dataSource;
  }

  refetch() {
    // we add a short delay for the refetch to make sure we get the latest data
    setTimeout(() => {
      this.refetchTasks(true);
    }, 10);
  }

  startPolling() {
    this.dataSource?.startPolling();
  }

  stopPolling() {
    this.dataSource?.stopPolling();
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchTasks();
  }


  /**
   *  99999999999 is an unreal high value to sort null or
   * existing values at the end of the list
   */
  ngAfterViewInit(): void {
    try {
      if(this.globalSearchInput?.nativeElement && this.textModel){
        this.globalSearchInput.nativeElement.value = this.textModel;
      }
    } catch (error) {
      // do nothing
    }

    this.currentContainerHeight = this.elementRef.nativeElement.offsetHeight;
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.onResize();
    }, 1);
  }

  getCurrentScrollHeight() {
    let currentScrollheight = (this.currentContainerHeight - 130) + 'px';
    return currentScrollheight;
  }

  dragDropped(event: NxDragDrop<Task, Tour> | any) {
    this.tourPlannerService.dragDropped(event);
  }

  dragStarted(event: CdkDragStart<Task>) {
    this.tourPlannerService.dragStarted(event);
  }

  dragReleased(event: CdkDragRelease<Task>) {
    this.tourPlannerService.dragReleased(event);
  }

  isDragDisabled(task: Task) {
    return this.tourPlannerService.isDragDisabled(task);
  }

  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  ngOnDestroy(): void {
  }


  // task labels
  availableColumns = [
    { field: 'dueDateTimePeriod', label: 'Fälligkeit', showSpacer: true },
    { field: 'orderNumber', label: 'Auftragsnummer', showSpacer: true },
    { field: 'orderCustomerName', label: 'Kunde', showSpacer: true },
    { field: 'addressStreet', label: 'Straße', showSpacer: true },
    { field: 'addressPostalCode', label: 'PLZ', showSpacer: true },
    { field: 'addressCity', label: 'Stadt', showSpacer: true },
    { field: 'addessGeocodingGrade', label: 'Addressqualität', showSpacer: true },
    { field: 'addressSummary', label: 'Adresse', showSpacer: true },
    { field: 'addressNote', label: 'Adresszusatz', showSpacer: true },
    { field: 'orderLocation', label: 'Niederlassung', showSpacer: true }
  ];

  defaultColumns = [
    'dueDateTimePeriod',
    'orderNumber',
    'orderCustomerName',
    'addressSummary'
  ];

  displayedColumns = [...this.defaultColumns];
  notDisplayedColumns;
  displayedColumnsStateKey = 'ToursPlannerTasksListComponent.displayedColumns';
  
  getHeaderForColumn(column: string): string {
    const col = this.availableColumns.find((c) => c.field === column);
    return col ? col.label : '';
  }
  dropColumn($event){
       // If the item is dropped in the same list, reorder the items
       if ($event.previousContainer === $event.container) {
        moveItemInArray($event.container.data, $event.previousIndex, $event.currentIndex);
      }
    this.saveDisplayedColumns();
  }
  onRemoveFromDisplayedColumns($event, column: string){
    $event.stopPropagation()
    this.displayedColumns = this.displayedColumns.filter(col => col !== column);
    this.notDisplayedColumns.push(column);
    this.saveDisplayedColumns();
  }
  onAddToDisplayedColumns(column: string){
    this.notDisplayedColumns = this.notDisplayedColumns.filter(col => col !== column);
    this.displayedColumns.push(column);
    this.saveDisplayedColumns();
  }
  saveDisplayedColumns(){
    let displayColumnsString = JSON.stringify(this.displayedColumns);
    localStorage.setItem(this.displayedColumnsStateKey, displayColumnsString);
  }
  setNotDisplayedColumns(){
    this.notDisplayedColumns = this.availableColumns.map(col => col.field).filter(col => !this.displayedColumns.includes(col));
  }
  setDisplayedColumns(){
    try {
      // try to load the displayed columns from local storage
      let displayColumnsString = localStorage.getItem(this.displayedColumnsStateKey);
      let displayColumnsFromLocalStorage = JSON.parse(displayColumnsString);
      // check if all columns from the stored state are still available
      // if not, use the default columns
      if(displayColumnsFromLocalStorage){
        let allColumnsAvailable = displayColumnsFromLocalStorage.every(col => this.availableColumns.find(ac => ac.field === col));
        if(allColumnsAvailable){
          this.displayedColumns = displayColumnsFromLocalStorage;
        } else {
          this.displayedColumns = [...this.defaultColumns];
          localStorage.removeItem(this.displayedColumnsStateKey);
        }
      }
    } catch (error) {
      localStorage.removeItem(this.displayedColumnsStateKey);
      this.resetLabels();
    }
    this.setNotDisplayedColumns();
  }

  resetLabels(){
    this.displayedColumns = [...this.defaultColumns];
    this.saveDisplayedColumns();
    this.setNotDisplayedColumns();
  }

  getActiveColumnes() {
    return this.availableColumns;
  }

  getAvailableColumns() {
    return this.availableColumns;
  }

  createTask(){
    alert('create task');
  }

}
