import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

export const LOCATIONS_QUERY = gql`
query LocationsQuery
{
  locations: getLocations(filter:{}){
    id
    name
    label
  }
}
`;

export interface LocationsQueryResponse {
  loading: boolean;
  locations: LocationEntity[];

}

export const TASK_ASSIGNMENT_QUERY = gql`
query TasksQuery(
  $pageNumber: Int,
  $pageSize: Int,
  $sortProperty: String,
  $sortDirection: String,
  $states: [TaskState],
  $text: String,
  $fromDateTime: String,
  $toDateTime: String,
  $toCompletedDateTime: String,
  $fromCompletedDateTime: String,
  $locationIds: [UUID],
  $types: [String],
  $assignmentTypes: [String]
)
{
  tasks: getTasksPaged(
    taskFilter: { 
      states: $states,
      handlingType: PICKUP,
      text: $text,
      toDateTime: $toDateTime,
      fromDateTime: $fromDateTime,
      toCompletedDateTime: $toCompletedDateTime,
      fromCompletedDateTime: $fromCompletedDateTime,
      locationIds: $locationIds,
      assignmentTypes: $assignmentTypes,
      types: $types,
      sortRequest: { 
        sortProperties: [$sortProperty],
        sortDirection: $sortDirection 
      },
      pagination: { 
        number: $pageNumber,
        size: $pageSize,
        sortProperty: $sortProperty,
        sortDirection: $sortDirection
      },
  }){
    totalPages
    totalElements
    number
    size
    content{
      id
      type
      title
      description
      color
      state
      commands
      dueDateTimePeriod{
        dateTime
        fromDateTime
        modifier
        toDateTime
        precision
      }
      assignmentSuggestion{
        assigneeId
        assignmentType
        dueDateTimePeriod{
          dateTime
          fromDateTime
          modifier
          toDateTime
          precision
        }
        name
        typeLabel
      }
      assignment{
        assigneeId
        assignmentType
        typeLabel
        name
      }
      completedBy{
        firstName
        lastName
      }
      completedDateTime
      order {
        id
        number
        note
        customer{
          type
          companyName
          companyNameExtension
          firstName
          lastName
        }
        fileCount
        commentCount
      }
    }
  }
}
`;

export interface TasksQueryResponse {
  tasks: Page<Task>;
  loading: boolean;
}
