import { AddressInput } from "src/app/shared-module/shared/entities/address/address";
import { LocationInput } from "src/app/shared-module/shared/entities/location/location";
import { DateTimePeriod } from "../dateTimePeriod/dateTimePeriod";
import { TaskLinkInput } from "../taskLinkInput/taskLinkInput";

export class TaskInput {
    id: string;
    title: string;
    description: string;
    dueDateTimePeriod: DateTimePeriod
    address: AddressInput;
    taskLinks: TaskLinkInput[];
    location: LocationInput;

    constructor(payload?: any) {
      if (payload) {
        this.id = payload.id;
        this.title = payload.title;
        this.description = payload.description;
        this.dueDateTimePeriod = new DateTimePeriod(payload.dueDateTimePeriod);
        this.address = new AddressInput(payload.address);
        if(payload.location ) {
          this.location = new LocationInput(payload.location);
        }
      }
    }
  }
