import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { AbstractAssignmentList, PageModel, SortModel } from '../../components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { Widget, WidgetComponent } from '../dashboard/dashboard.component';
import { TaskDataSource } from './taskDataSource';

export enum DateType {
  assignmentDueDateTime, dueDateTime, completedDateTime
}

export interface DashboardTaskListSettings {
  types?: string[] | undefined;
  handlingType?: string | undefined;
  states?: string[] | undefined;
  sortOptions?: string[] | undefined;
  dateType?: DateType | undefined;
  includeOverDue?: boolean | undefined;
}
@Component({
  selector: 'app-dashboard-task-list',
  templateUrl: './dashboard-task-list.component.html',
  styleUrls: ['./dashboard-task-list.component.scss']
})
export class DashboardTaskListComponent extends AbstractAssignmentList implements  OnDestroy, WidgetComponent{

  public expandedModel: boolean;
  public defaultExpandedModel = false;
  
  _locationId: string;
  @Input() set locationId(locationId: string){
    if(locationId){
      this._locationId = locationId;
      this.onChanges(); 
    }
  }

  _widget: Widget;
  _settings: DashboardTaskListSettings;
  @Input() set widget(widget: Widget){
    this._widget = widget;
    if(this._widget) {
      this._settings = this._widget.settings as DashboardTaskListSettings;
      this.onChanges();
    }
  };

  public _date = new Date();
  @Input() set setDate(date: any) {
    if(date){
      this._date = date;
      this.onChanges();
    }
  }

  @HostBinding('class.col-span-2') 
  get expandedClass() {
    return this.expandedModel;
  }
  
  public dataSource: TaskDataSource;
  public loading = false;

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 5 };

  // sorting
  public sortModel: SortModel;
  public sortOptions : SortModel[] = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Fälligkeit aufsteigend', fieldName: 'dueDateTimePeriod', direction: "asc" },
    { label: 'Fälligkeit absteigend', fieldName: 'dueDateTimePeriod', direction: "desc"},
    { label: 'Status aufsteigend', fieldName: 'state', direction: "asc" },
    { label: 'Status absteigend', fieldName: 'state', direction: "desc"}
  ];
  public availableSortOptions = this.sortOptions;
  public defaultSortModel: SortModel = this.sortOptions[0];

  public defaultColumns = [
    'dueDateTimePeriod',
    'orderNumber',
    'orderCustomerName',
    'extendedState'
  ];
  
  constructor(
    private apollo: Apollo
  ) {
    super(undefined, 'local' )
    this.pageModel = this.defaultPageModel;
    this.sortModel = this.defaultSortModel;
    this.expandedModel = this.defaultExpandedModel;
  }

  onChanges(): void {
    if (this._widget && this._date) {
      if(!this.dataSource) {
        this.availableSortOptions = this._widget?.settings?.sortOptions ? this.sortOptions.filter(option => this._widget?.settings?.sortOptions.includes(option.fieldName)) : this.sortOptions;
        this.setStateKey("DashboardTaskListComponent." + this._widget.field);
        let state = this.loadState();
        this.pageModel = state?.pageModel ? state.pageModel : this.defaultPageModel;
        this.expandedModel = state?.expandedModel ? state.expandedModel : this.defaultExpandedModel;
        // we´re filtering here for the options, so that we do not get any problems, if we change them in future
        // we need to find a suitable option for the state
        let stateSortModel = state?.sortModel;
        let matchedSortModel = this.sortOptions.find(option => option.fieldName === stateSortModel?.fieldName && option.direction === stateSortModel?.direction);
        this.sortModel = matchedSortModel ? matchedSortModel : this.defaultSortModel;
        this.dataSource = new TaskDataSource(this.apollo);
        this.dataSource.loading.subscribe((loading) => {
          this.loading = loading;
        });
        this.waitForLocationId();
      } else {
        this.refetchTasks();
      }
    }
  }

  waitForLocationId() {
    const checkInterval = 50; // Zeit in Millisekunden zwischen den Prüfungen
    const maxWaitTime = 300; // Maximale Wartezeit in Millisekunden
    let waitedTime = 0;

    const intervalId = setInterval(() => {
      if (this._locationId) {
        clearInterval(intervalId);
        this.loadTasks();
      } else if (waitedTime >= maxWaitTime) {
        clearInterval(intervalId);
        this.loadTasks();
      } else {
        waitedTime += checkInterval;
      }
    }, checkInterval);
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect();
  }

  buildVariables(): any {
    let variables = {};
    variables['pageNumber'] = this.pageModel?.pageNumber;
    variables['pageSize'] = this.pageModel?.pageSize;
    variables['sortProperty'] = this.sortModel?.fieldName;
    variables['sortDirection'] = this.sortModel?.direction;
    variables['states'] = this._widget?.settings?.states ? this._widget?.settings?.states : undefined;
    variables['types'] = this._settings?.types ? this._settings?.types : undefined;
    variables['locationIds'] = this._locationId ? [ this._locationId ] : undefined;
    variables['includeOverDue'] = this._settings?.includeOverDue != undefined ? this._settings?.includeOverDue : undefined;
    variables['handlingType'] = this._settings?.handlingType ? this._settings?.handlingType : undefined;
    if(this._settings?.dateType === DateType.assignmentDueDateTime) {
      variables['fromAssignmentDueDateTime'] = new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString();
      variables['toAssignmentDueDateTime'] = new Date(moment(this._date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    } else if(this._settings?.dateType === DateType.completedDateTime) {
      variables['fromCompletedDateTime'] = new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString();
      variables['toCompletedDateTime'] = new Date(moment(this._date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    } else {
      variables['fromDate'] = new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString();
      variables['toDate'] = new Date(moment(this._date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    }
    return variables;
  }

  loadTasks(): void {
    this.dataSource.loadTasks(this.buildVariables());
  }

  refetchTasks(): void {
    this.storeState({
      pageModel: this.pageModel,
      sortModel: this.sortModel,
      expandedModel: this.expandedModel
    });
    this.dataSource?.refetchQuery(this.buildVariables());
  }


  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchTasks();
  }

  toggleExpanded() {
    this.expandedModel = !this.expandedModel;
    this.storeState({
      pageModel: this.pageModel,
      sortModel: this.sortModel,
      expandedModel: this.expandedModel
    });
  }

  getDataSource() {
    return this.dataSource;
  }

  reset(): void {
    this.pageModel = this.defaultPageModel;
    this.sortModel = this.defaultSortModel;
    this.expandedModel = this.defaultExpandedModel;
    this.clearState();
  }

}
