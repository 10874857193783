<div class="flex flex-row basis-full tasks" [class.completed]="_task?.state === 'COMPLETED'" *ngIf="_task?.id">
  
  <!-- start -->
  <div class="flex flex-col basis-12">
    <div class="flex flex-row h-full">
      <div [ngStyle]="{ 'background-color': _task?.getBackgroundColor() }" class="w-[5px] h-full"></div>
      <div class="pl-1 pt-1"><i [className]="_task?.getTypeIcon()" [matTooltip]="_task?.getType()"></i></div>
    </div>
  </div>

  <!-- middle/end -->
  <div class="flex justify-items-start flex-col basis-full px-2 py-1">
    <div class="flex justify-items-start flex-row">
      <div class="flex justify-items-start flex-col mb-[0.125rem] w-full">
        <div class="flex flex-row row flex-wrap items-center leading-[2rem]">
          <span class="bg-[#EBEEEF] text-black whitespace-nowrap leading-[1.25rem] px-2 py-1 rounded-md" [ngClass]="_task?.state">
            {{tasksService.getDueDateTime(_task?.dueDateTimePeriod)}}
          </span>
          &nbsp;
          <ng-container *ngIf="showAddress">
            <span class="noWrap" #number
              matTooltipShowDelay="250" matTooltip="{{getAddressToolTip(_task?.address)}}">
            &nbsp;{{getAddressString(_task)}}
            </span>
            <app-address-indicator
              class="ml-1"
              size="small"
              [grade]="_task?.address?.geocodingResultGrade?.toString()">
            </app-address-indicator>
          </ng-container>
        </div>
        <div class="flex flex-row row">
          <span class="text-[#848488]">
            <ng-container *ngIf="_task?.type !== 'nexcore_default_task'">
              {{_task?.description? _task?.description: '-'}}
            </ng-container>
            <ng-container *ngIf="_task?.type === 'nexcore_default_task'">
              {{_task?.title ? _task?.title: '-'}}
            </ng-container>
          </span>
        </div>
      </div>
      <div class="orderInfo flex justify-end flex-row pt-1">
        <!-- order note -->
        <nx-default-button
          *ngIf="shouldShowOrderNoteButton()"
          class="mr-1"
          [clickable]="false"
          [style]="'light'"
          [icon]="'icon-info'"
          [tooltip]="_task?.order?.note ? _task?.order?.note : 'Kein Auftrags-Hinweis hinterlegt.'"
          (click)="$event.stopPropagation();"
          [ngClass]="_task?.order?.note ? 'note' :'no-note'">
        </nx-default-button>
        <!-- order note -->
        <!-- files START -->
        <ng-template #filesOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <app-files [owner]="_task?.type === 'nexcore_default_task' ? _task?.id : _task.order?.id" [style]="'overlay'"/>
          </div>
        </ng-template>
        <!--  -->
        <nx-default-button
          (click)="openOverlay(filesOverlayContent, filesTriggerButton, $event)"
          #filesTriggerButton
          [tooltip]="'Anhänge anzeigen'"
          [style]="'light'"
          [badgeValue]="_task?.fileCount ? _task?.fileCount : _task.order?.fileCount"
          class="mr-1"
          [icon]="'icon-file-attachment'">
        </nx-default-button>
        <!-- files END -->
        <!-- comments START -->
        <ng-template #commentsOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <div>
              <app-comments-list
                *ngIf="_task?.type !== 'nexcore_default_task'"
                [ownerId]="_task?.order?.id"
                [style]="'overlay'" />
              <app-comments-list
                *ngIf="_task?.type === 'nexcore_default_task'"
                [ownerId]="_task?.id"
                [style]="'overlay'" />
            </div>
          </div>
        </ng-template>
        <!--  -->
        <nx-default-button
          #commentsTriggerButton
          (click)="openOverlay(commentsOverlayContent, commentsTriggerButton, $event)"
          [tooltip]="'Kommentare anzeigen'"
          [style]="'light'"
          class="mr-1"
          [badgeValue]="_task?.getCommentCount()"
          [icon]="'icon-annotation'">
        </nx-default-button>
        <!-- comments END -->
        <nx-default-button
          *ngIf="showCommandMenu"
          [style]="'light'"
          [data]="_task"
          [menuItems]="menuItems"
          [icon]="'icon-options'">
        </nx-default-button>
      </div>
    </div>
    <div class="flex flex-row row">
      <div class="flex flex-row">
          <div>
            <ng-container *ngIf="_task.type === 'nexcore_default_task'">
              {{getContactName(_task?.linkedContact)? getContactName(_task?.linkedContact) : '-'}}
            </ng-container>
            <ng-container *ngIf="_task.type !== 'nexcore_default_task'">
              {{getContactName(_task?.order?.customer)? getContactName(_task?.order?.customer) : '-'}}
            </ng-container>
            
          </div>
        <span class="order flex items-center">&nbsp;/&nbsp;{{_task?.order?.number ? _task?.order?.number : '-'}}</span>
        <i *ngIf="_task?.order?.number" [className]="'icon icon-jump-to'" [newTabNavigation]="['./orders/order', _task?.order?.id]"></i>
      </div>
      <div class="flex justify-end flex-grow">
        <div class="pr-2">
          <ng-container *ngIf="showCompletedStateLabel">
            <p-tag *ngIf="_task?.state === 'COMPLETED'" [value]="_task?.getStateLabel()" [severity]="_task?.getStateSeverity()"></p-tag>
          </ng-container>
          <ng-container *ngIf="showStateLabel">
            <p-tag [value]="_task?.getStateLabel()" [severity]="_task?.getStateSeverity()"></p-tag>
          </ng-container>
        </div>
      </div>
    </div>
    
    <!-- end -->
  </div>




</div>
