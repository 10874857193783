<div class="flex flex-row basis-full tasks" [class.completed]="_task?.state === 'COMPLETED'" *ngIf="_task?.id">
  
  <!-- start -->
  <div class="flex flex-col basis-12">
    <div class="flex flex-row h-full">
      <div [ngStyle]="{ 'background-color': _task?.getBackgroundColor() }" class="w-[5px] h-full"></div>
      <div class="pl-1 pt-1"><i [className]="_task?.getTypeIcon()" [matTooltip]="_task?.getType()"></i></div>
    </div>
  </div>

  <!-- middle/end -->
  <div class="flex justify-items-start flex-col basis-full px-2 py-1">
    <div class="flex justify-items-start flex-row">
      <div class="flex justify-items-start w-full flex-col">
        <div class="leading-[25px]">
          <ng-container *ngFor="let column of columns; let i = index; let isFirst = first; let isLast = last">
            <ng-container [ngTemplateOutlet]="getTemplateForColumn(column)" [ngTemplateOutletContext]="{ $implicit: _task }"></ng-container>
            <ng-container *ngIf="shoulShowSpacer(column, columns, i, isLast)"> / </ng-container>
          </ng-container>
        </div>
        <div class="flex flex-row row">
          <span class="text-[#848488]">
          <ng-container *ngIf="_task?.type !== 'nexcore_default_task'">
            {{_task?.description? _task?.description: '-'}}
          </ng-container>
          <ng-container *ngIf="_task?.type === 'nexcore_default_task'">
            {{_task?.title ? _task?.title: '-'}}
          </ng-container>
          </span>
        </div>
      </div>
      <div class="orderInfo flex justify-end flex-row pt-1" *ngIf="showIconMenu">
        <!-- order note -->
        <nx-default-button
          *ngIf="shouldShowOrderNoteButton()"
          class="mr-1"
          [clickable]="false"
          [style]="'light'"
          [icon]="'icon-info'"
          [tooltip]="_task?.order?.note ? _task?.order?.note : 'Kein Auftrags-Hinweis hinterlegt.'"
          (click)="$event.stopPropagation();"
          [ngClass]="_task?.order?.note ? 'note' :'no-note'">
        </nx-default-button>
        <!-- order note -->
        <!-- files START -->
        <ng-template #filesOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <app-files [owner]="_task?.type === 'nexcore_default_task' ? _task?.id : _task.order?.id" [style]="'overlay'"/>
          </div>
        </ng-template>
        <!--  -->
        <nx-default-button
          *ngIf="_showFilesButton()"
          (click)="openOverlay(filesOverlayContent, filesTriggerButton)"
          #filesTriggerButton
          [tooltip]="'Anhänge anzeigen'"
          [style]="'light'"
          [badgeValue]="_task?.fileCount ? _task?.fileCount : _task.order?.fileCount"
          class="mr-1"
          [icon]="'icon-file-attachment'">
        </nx-default-button>
        <!-- files END -->
        <!-- comments START -->
        <ng-template #commentsOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <div>
              <app-comments-list
                *ngIf="_task?.type !== 'nexcore_default_task'"
                [ownerId]="_task?.order?.id"
                [style]="'overlay'" />
              <app-comments-list
                *ngIf="_task?.type === 'nexcore_default_task'"
                [ownerId]="_task?.id"
                [style]="'overlay'" />
            </div>
          </div>
        </ng-template>
        <!--  -->
        <nx-default-button
          *ngIf="_showCommentsButton()"
          #commentsTriggerButton
          (click)="openOverlay(commentsOverlayContent, commentsTriggerButton)"
          [tooltip]="'Kommentare anzeigen'"
          [style]="'light'"
          class="mr-1"
          [badgeValue]="_task?.getCommentCount()"
          [icon]="'icon-annotation'">
        </nx-default-button>
        <!-- comments END -->
        <nx-default-button
          *ngIf="showCommandMenu"
          [style]="'light'"
          [data]="_task"
          [menuItems]="menuItems"
          [icon]="'icon-options'">
        </nx-default-button>
      </div>
    </div>    
    <!-- end -->
  </div>

</div>

<ng-template #dueDateTimePeriod let-task>
  <ng-container *ngIf="_task?.dueDateTimePeriod; else noContent">
    <span class="bg-[#EBEEEF] text-black whitespace-nowrap leading-[1.25rem] px-2 py-1 rounded-md" [ngClass]="_task?.state">
      {{tasksService.getDueDateTime(_task?.dueDateTimePeriod)}}
    </span>
    &nbsp;
  </ng-container>
</ng-template>

<ng-template #completedDateTime let-task>
  <ng-container *ngIf="_task?.completedDateTime; else noContent">
    <span class="bg-[#EBEEEF] text-black whitespace-nowrap leading-[1.25rem] px-2 py-1 rounded-md" [ngClass]="_task?.state">
      {{_task?.completedDateTime | date: 'HH:mm'}}
    </span>
    <span class="dueDate pl-1">{{_task?.completedDateTime | date: 'dd.MM.yyyy'}}</span>
  </ng-container>
</ng-template>

<ng-template #orderCustomerName let-task>
  <ng-container *ngIf="_task.type === 'nexcore_default_task'">
    <ng-container *ngIf="_task?.linkedContact; else noContent">
      {{getContactName(_task?.linkedContact)}}
    </ng-container>
  </ng-container>
  <ng-container *ngIf="_task.type !== 'nexcore_default_task'">
    <ng-container *ngIf="_task?.order?.customer; else noContent">
      {{getContactName(_task?.order?.customer)}}
    </ng-container>
  </ng-container>

</ng-template>

<ng-template #orderNumber let-task>
  <ng-container *ngIf="_task?.order?.number; else noContent">
    {{_task?.order?.number}}
    <i [className]="'icon icon-jump-to'" [newTabNavigation]="['./orders/order', _task?.order?.id]"></i>
  </ng-container>
</ng-template>

<ng-template #state let-task>
  <ng-container *ngIf="task?.state; else noContent">
    <p-tag [value]="task?.getStateLabel()" [severity]="task?.getStateSeverity()" class="stateLabel" [matTooltip]="task?.state === 'COMPLETED' ? getCompletedTooltip(task) : undefined"></p-tag>
  </ng-container>
</ng-template>

<ng-template #extendedState let-task>
  <ng-container *ngIf="task?.state; else noContent">
    
    <p-tag [value]="" [severity]="task?.getStateSeverity()" class="stateLabel" [matTooltip]="task?.state === 'COMPLETED' ? getCompletedDateTimeTooltip(task) : undefined">
      <ng-container *ngIf="task?.state === 'ASSIGNED'">
        <ng-container *ngIf="task?.assignment?.assignmentType === 'TourTaskAssignmentStrategy'">
          {{task?.getStateLabel()}}: {{task?.assignment?.name}}
          <i [className]="'icon icon-jump-to'" [newTabNavigation]="['./tour-planner/detail/', task?.assignment?.assigneeId]"></i>
        </ng-container>
        <ng-container *ngIf="task?.assignment?.assignmentType === 'LocationTaskAssignmentStrategy'">
          {{task?.getStateLabel() + (task?.assignment?.name ? ': ' + task?.assignment?.name + '' : '')}}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="task?.state === 'COMPLETED'">
        {{task?.getStateLabel()}}: {{task?.completedBy ? task?.completedBy?.firstName + ' ' + task?.completedBy?.lastName : '-' }}
      </ng-container>
      <ng-container *ngIf="task?.state === 'UNASSIGNED' || task?.state === 'NEW'">
        {{task?.getStateLabel()}}
      </ng-container>
    </p-tag>
  </ng-container>
</ng-template>

<ng-template #addressStreet let-task>
  <ng-container *ngIf="(task?.address?.street || task?.address?.houseNumber); else noContent">
    {{task?.address?.street}}&nbsp;{{task?.address?.houseNumber}}
  </ng-container>
</ng-template>

<ng-template #addressPostalCode let-task>
  <ng-container *ngIf="task?.address?.postalCode; else noContent">
    {{task?.address?.postalCode}}
  </ng-container>
</ng-template>

<ng-template #addressCity let-task>
  <ng-container *ngIf="task?.address?.city; else noContent">
    {{task?.address?.city}}
  </ng-container>
</ng-template>

<ng-template #addessGeocodingGrade let-task>
  <app-address-indicator
    label
    class="inline-flex mt-[0.25rem] ml-2"
    size="small"
    [grade]="task?.address?.geocodingResultGrade?.toString()">
  </app-address-indicator>
</ng-template>

<ng-template #addressSummary let-task>
  <div class="inline-flex flex-row">
    <div>{{task?.address?.getAddressString() ? task?.address?.getAddressString() : '-'}}</div>
    <div>
      <app-address-indicator
        label
        class="mt-[0.25rem] ml-2"
        size="small"
        [grade]="task?.address?.geocodingResultGrade?.toString()">
      </app-address-indicator>
    </div>
  </div>
</ng-template>

<ng-template #addressNote let-task>
  <ng-container *ngIf="task?.address?.note; else noContent">
    {{task?.address?.note}}
  </ng-container>
</ng-template>

<ng-template #orderLocation let-task>
  <ng-container *ngIf="task?.order?.location; else noContent">
    {{task?.order?.location?.label ? task?.order?.location?.label : task?.order?.location?.name}}
  </ng-container>
</ng-template>

<ng-template #noContent>
  -
</ng-template>