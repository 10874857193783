import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { TaskView } from '../task-detail-view/task-detail-view.component';

@Component({
  selector: 'app-nexcore-rental-resourceAssignment-review-task-view',
  templateUrl: './nexcore-rental-resourceAssignment-review-task-view.component.html',
  styleUrls: ['./nexcore-rental-resourceAssignment-review-task-view.component.scss']
})
export class NexcoreRentalResourceAssignmenmtReviewTaskViewComponent implements TaskView  {

  @Input() task: Task;
  @Output() complete = new EventEmitter<{ task: Task, formModelInput?: any }>();

  constructor(
  ) {

  }
  
  getHeadline(): string {
    return this.task?.formControlTemplate?.settings?.label;
  }

  completeTask($event: any) {
    this.complete.emit($event);
  }
  
  
}
