import { Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { EditDialogService } from '@nexato/nx-core-module';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Address, AddressInput } from '../../shared/entities/address/address';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';

@Component({
    selector: 'nx-edit-linked-address-input',
    template: `
    <div class="nx-edit-linked-address-input">
        <label for="text">{{label}}<ng-container *ngIf=isRequired()>*</ng-container></label>
        <div class="flex flex-row" (click)="openAddressDialog(); $event.stopPropagation()">
            <div class="input previewValue flex flex-row justify-between items-center">
                <div
                    *ngIf="address"
                    class="flex-grow">
                    {{getPreviewValue()}}
                </div>
                <div
                    *ngIf="!address"
                    class="flex-grow placeholder">
                    {{placeholder}}
                </div>
                <TimesIcon
                    *ngIf="address"
                    (click)="clearAddress(); $event.stopPropagation()"
                    [styleClass]="'p-dropdown-clear-icon'" [attr.data-pc-section]="'clearicon'" />
            </div>
        </div>
    </div>
    `,
    styles: [`
        :host ::ng-deep .nx-edit-linked-address-input .p-icon-wrapper {
            margin-right: 12px;
            margin-top: -7px;
            transform: rotate(45deg);
        }
        .placeholder {
            color: #888484;
        }
  `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditLinkedAddressInputComponent),
            multi: true
        }
    ]
})
export class EditLinkedAddressInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

    ngControl: NgControl;
    isDisabled: boolean;
    dialogRef: DynamicDialogRef | undefined;

    // If the address was cleared by the user and the address had already an id, we store the id here, 
    // so that we cann add it to the addressInput when the user selects a new address. So we make sure
    // that the addressInput has the same id as the address that was cleared.
    clearedAddressId: string;
    address: Address;
    addressInput: AddressInput;

    @Input() label: string;
    @Input() placeholder: string;

    private onChange: (value: any) => void;
    private onTouched: () => void;

    constructor(
        public dialogService: EditDialogService,
        private _injector: Injector) {
    }

    ngOnInit() {
        this.ngControl = this._injector.get(NgControl);
        this.ngControl.valueAccessor = this;
    }

    ngOnDestroy(): void {
    }

    writeValue(address: Address): void {
        if(address){
            this.address = address;
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    isRequired(): boolean {
        return this.ngControl?.control?.hasValidator(Validators.required);
    }

    openAddressDialog() {
        console.log("openAddressDialog:");
        console.log(this.address);
        this.dialogRef = this.dialogService.open(AddressDialogComponent, {
            width: "1100px",
            header: this.address ? 'Adresse bearbeiten' : 'Adresse hinzufügen',
            data: {
                address: this.address,
                showImportedAddress: false,
                loadAddressOnOpen: false
            }
            });
            this.dialogRef.onClose.subscribe((result: any) => {
            if(result){
                // set the "current" address to the resulting input so that we can show a preview
                this.address = new Address(result.addressInput);
                // if the address was cleared before, set the id to the addressInput
                // this will trigger an update and does not leave orphaned addresses in the database
                if(this.clearedAddressId){
                    this.address.id = this.clearedAddressId;
                    result.addressInput.id = this.clearedAddressId;
                    this.clearedAddressId = undefined;
                }
                console.log("closeAddressDialog:");
                console.log(this.address);
                // return the address input to the parent form
                this.onChange(result.addressInput);
            }
        });
    }

    getPreviewValue(): string {
        return this.address ? this.address?.getAddressString() : "Adresse hinzufügen";
    }

    clearAddress() {
        this.clearedAddressId = this.address.id;
        this.address = undefined;
        this.onChange(undefined);
    }

}