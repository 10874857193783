
export class TaskLinkInput {
    id: string;
    taskId: string;
    linkedEntityType: string;
    linkedEntityId: string

    constructor(payload?: any) {
      if (payload) {
        this.id = payload.id;
        this.taskId = payload.taskId;
        this.linkedEntityType = payload.linkedEntityType;
        this.linkedEntityId = payload.linkedEntityId;
      }
    }
  }
