import { Component, Input } from '@angular/core';
import { TaskAssignment } from 'src/app/rent-module/shared/entities/taskAssignment/taskAssignment';

@Component({
  selector: 'app-user-task-assignment-strategy-view',
  template: `
  {{assignment?.name}}
  `
})
export class UserTaskAssignmentStrategyViewComponent {

  @Input() public assignment: TaskAssignment;

  constructor() {
  }

}
