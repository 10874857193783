import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';


export const RESOURCES_QUERY = gql`
query resourcesQuery(
  $pageNumber: Int,
  $pageSize: Int,
  $sortProperty: String,
  $sortDirection: String,
  $text: String
)
{
  resources: getResourcesPaged(
    filter: {
      sortRequest: {
        sortProperties: [$sortProperty],
        sortDirection: $sortDirection
      },
      pagination: {
        number: $pageNumber,
        size: $pageSize,
        sortProperty: $sortProperty,
      sortDirection: $sortDirection},
      text: $text
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
	        name
          number
      }
  }
}
`;

export interface ResourcesQueryResponse {
  resources: Page<Resource>;
}
